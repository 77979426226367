<ng-container *ngIf="model != null">
    <div class="wrapper px-1">
        <!-- Task info: Cancelada -->
        <ng-container *ngIf="isTaskCancelled; else componentTemplate">
            <div class="message-container" *ngIf="isTaskAutomaticallyCancelled">
                <b>
                    Tarefa cancelada automaticamente pelo sistema em
                    <span class="d-inline-block text-highlight relevant-info-font">{{dateString}}</span>
                </b>
            </div>
            <div class="message-container" *ngIf="isTaskManuallyCancelled">
                <b>
                    Tarefa cancelada manualmente pelos administradores do Fluxo em
                    <span class="d-inline-block text-highlight relevant-info-font">{{dateString}}</span>
                </b>
            </div>

            <!-- Visão do executor -->
            <ng-container *ngIf="isTaskPublicAgentActor">
                <hr />
                <div class="mb-3">
                    Documento comprobatório do cancelamento desta Tarefa:
                </div>
                <div class="documents-container">
                    <div class="position-relative my-1" *ngFor="let doc of currentDocuments">
                        <span class="document-highlight" (click)="openDocumentModal(doc.id)">
                            <span class="e-docs relevant-info-font text-highlight" matTooltip="Protocolo E-Docs do documento">{{doc.registro}}</span>
                            <span class="relevant-info-font d-inline-block" matTooltip="Clique para visualizar o documento que foi capturado no E-Docs">
                                <i class="fas fa-external-link-alt mr-2"></i><u>{{doc.filename}}</u>
                            </span>
                        </span>
                    </div>
                </div>
            </ng-container>

            <!-- Visão do requerente -->
            <ng-container *ngIf="!isTaskPublicAgentActor">
                <hr />
                <div class="message-container">
                    <p *ngIf="isTaskAutomaticallyCancelled">
                        O Fluxo
                        <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.name}}</span>
                        foi configurado para que qualquer instância do mesmo seja cancelada automaticamente
                        após data e hora específicos ou em caso de inatividade prolongada.
                    </p>
                    <p *ngIf="isTaskManuallyCancelled">
                        O Fluxo
                        <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.name}}</span>
                        com o identificador
                        <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.id.toUpperCase()}}</span>
                        foi cancelado em função de um ou mais erros ocorridos na identificação dos Interessados do Processo a ser autuado no E-Docs.
                    </p>
                    <p>
                        Para todos os efeitos, o Fluxo <b>não foi completado</b> e as eventuais Tarefas previamente executadas <b>não possuem validade</b>.
                    </p>
                    <span>
                        Inicie uma <b>nova instância</b> deste Fluxo para completar a requisição do serviço.
                    </span>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #componentTemplate>
            <!-- Seletor de papel -->
            <ng-container *ngIf="isTaskPublicAgentActor && hasPendingTask && papeisSelector.length > 0">
                <papel-selector [(signerId)]="inputData.eDocsData.signerId"
                                [inputIsReadOnly]="isReadOnly"
                                [inputPapeis]="papeisSelector"
                                [inputFlowObjectType]="FlowObjectType.TaskRegisterProcess"
                ></papel-selector>
                <hr />
            </ng-container>

            <!-- Task body: visão do executor -->
            <ng-container *ngIf="isTaskPublicAgentActor">
                <!-- Local de Autuação -->
                <div class="message-container">
                    <div>Local de Autuação:</div>
                    <div class="my-3">
                        <span class="relevant-info-font text-highlight d-inline-block">
                            {{configSchema?.taskRegisterProcess?.processLocationName.toUpperCase()}}
                        </span>
                    </div>
                    <hr />
                </div>

                <!-- Classe do Processo -->
                <div class="message-container">
                    <div>Classe do Processo:</div>
                    <div class="my-3">
                        <span class="relevant-info-font text-highlight d-inline-block">
                            {{configSchema?.taskRegisterProcess?.processClass.name.toUpperCase()}}
                        </span>
                    </div>
                    <hr />
                </div>

                <!-- Interessados do Processo -->
                <div class="message-container">
                    <div>Interessados do Processo:</div>
                    <div class="stakeholders-container my-3">
                        <ng-container *ngFor="let stakeholder of configSchema?.taskRegisterProcess?.stakeholders">
                            <div class="stakeholder relevant-info-font text-highlight m-1 p-2" *ngIf="!stakeholder.shouldIgnore">
                                <div class="stakeholder-content m-0"
                                     *ngIf="[
                                        StakeholderType.Patriarch,
                                        StakeholderType.Organization,
                                        StakeholderType.Unit,
                                        StakeholderType.Group,
                                        StakeholderType.Comission,
                                        StakeholderType.Citizen
                                     ].includes(stakeholder.type)">
                                    <b class="to-upper" [class.red]="isErrorMessage(stakeholder.name)">{{stakeholder.name}}</b>
                                    <span>{{StakeholderTypeDescription.get(+stakeholder.type)}}</span>
                                </div>

                                <div class="stakeholder-content m-0" *ngIf="stakeholder.type == StakeholderType.PublicAgent">
                                    <b class="to-upper">{{stakeholder.name.split(' - ')[0]}}</b>
                                    <span class="to-upper">{{stakeholder.name.split(' - ').splice(1).join(' - ')}}</span>
                                    <span>{{StakeholderTypeDescription.get(+stakeholder.type)}}</span>
                                </div>

                                <div class="stakeholder-content m-0" *ngIf="stakeholder.type == StakeholderType.Company">
                                    <b class="to-upper" [class.red]="isErrorMessage(stakeholder.companyName)">{{stakeholder.companyName}}</b>
                                    <span class="to-upper" [class.red]="isErrorMessage(stakeholder.cnpj) || isErrorMessage(stakeholder.email)">
                                        {{Utils.maskCnpj(stakeholder.cnpj)}} <b>|</b> {{stakeholder.email}}
                                    </span>
                                    <span>{{StakeholderTypeDescription.get(+stakeholder.type)}}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <hr />
                </div>

                <!-- Resumo do Processo -->
                <div class="message-container">
                    <div>Resumo do Processo:</div>
                    <div class="my-3">
                        <span class="relevant-info-font text-highlight d-inline-block p-2"
                              [innerHtml]="sanitizer.bypassSecurityTrustHtml(processSummary)"></span>
                    </div>
                    <hr />
                </div>

                <!-- Documentos entranhados -->
                <div class="message-container">
                    <div>Documentos entranhados:</div>
                    <div class="documents-container my-3">
                        <div class="position-relative my-1" *ngFor="let doc of previousDocuments">
                            <span class="document-highlight" (click)="openDocumentModal(doc.id)">
                                <span class="e-docs relevant-info-font text-highlight" matTooltip="Protocolo E-Docs do documento">{{doc.registro}}</span>
                                <span class="relevant-info-font d-inline-block" matTooltip="Clique para visualizar o documento que foi capturado no E-Docs">
                                    <i class="fas fa-external-link-alt mr-2"></i><u>{{doc.filename}}</u>
                                </span>
                            </span>
                        </div>
                    </div>
                    <hr />
                </div>
            </ng-container>

            <!-- Task info: Realizada -->
            <div class="message-container" *ngIf="!hasPendingTask; else pendingTaskTemplate">
                <div class="mb-3">
                    O Processo disponível no protocolo E-Docs
                    <span class="relevant-info-font text-highlight">{{process?.protocolo}}</span>
                    foi <b>autuado</b> por:
                </div>
                <div class="mb-3">
                    <span class="relevant-info-font text-highlight d-inline-block">{{registrar?.toUpperCase()}}</span>
                </div>
            </div>

            <!-- Task info: Iniciada -->
            <ng-template #pendingTaskTemplate>
                <!-- Task body: visão do requerente -->
                <div class="message-container" *ngIf="!isTaskPublicAgentActor; else pendingTaskPublicAgentActorTemplate">
                    <div class="mb-3">
                        Aguardando autuação do Processo por:
                    </div>
                    <div>
                        <span class="relevant-info-font text-highlight d-inline-block">{{registrar?.toUpperCase()}}</span>
                    </div>

                    <ng-container *ngIf="!Utils.isNullOrEmpty(flowObjectDefinition?.publicMessageHtml)">
                        <hr />
                        <div class="public-message py-1" [class.loading]="publicMessageHtml == DEFAULT_LOADING_TEXT" [innerHtml]="publicMessageHtml"></div>
                    </ng-container>
                </div>

                <!-- Task footer: visão do executor -->
                <ng-template #pendingTaskPublicAgentActorTemplate>
                    <div class="form-container">
                        <div class="footer" [class.has-errors]="hasErrors">
                            <button type="button" class="btn btn-outline-primary register-process"
                                    [disabled]="hasErrors || Utils.isNullOrEmpty(inputData?.eDocsData?.signerId)"
                                    (click)="submit()"
                            >
                                <i class="fas fa-question-circle" *ngIf="hasErrors || Utils.isNullOrEmpty(inputData?.eDocsData?.signerId)"
                                   matTooltip="Não é possível autuar o Processo porque um ou mais erros foram identificados ao recuperar informações sobre os Interessados do Processo"
                                ></i>
                                Autuar Processo
                            </button>
                            <button type="button" class="btn btn-danger ml-3" *ngIf="hasErrors && !isCancelFlowInstanceAreaVisible"
                                    (click)="showCancelFlowInstanceArea()"
                            >Cancelar Fluxo</button>
                        </div>
                    </div>

                    <div class="message-container" *ngIf="isCancelFlowInstanceAreaVisible">
                        <div class="input-container text-center my-3 px-0">
                            <textarea id="message" placeholder="Entre com a mensagem de cancelamento" rows="4"
                                      [class.minlength]="isLackingMessage"
                                      [(ngModel)]="inputData.cancelMessage"
                            ></textarea>
                        </div>
                        <button type="button" class="btn btn-danger" *ngIf="hasErrors"
                                [disabled]="isLackingMessage"
                                (click)="cancelFlowInstance()"
                        >Cancelar Fluxo</button>
                    </div>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
</ng-container>
