<div class="wrapper px-1" [class.single]="outputData.hasApproved" *ngIf="model != null">
    <!-- Task info: Erro ao avaliar regra -->
    <ng-container *ngIf="Utils.isNullOrEmpty(model.outputData); else truthyTemplate">
        <div class="message-container">
            <div class="message-row">
                <i class="fas fa-minus-circle error mr-3"></i>
                <span class="headline">
                    Erro ao avaliar a regra da Tarefa
                </span>
                <i class="fas fa-minus-circle error ml-3"></i>
            </div>

            <hr />

            <div class="message-row column">
                <p>
                    Não foi possível executar a aplicação da regra configurada pelos administradores do Fluxo.
                </p>
                <p>
                    Para todos os efeitos, o Fluxo <b>não foi completado</b>.
                </p>
                <span>
                    Inicie uma <b>nova instância</b> deste Fluxo para completar a requisição do serviço.
                </span>
            </div>
        </div>
    </ng-container>

    <!-- Task info: Regra truthy -->
    <ng-template #truthyTemplate>
        <div class="message-container" *ngIf="outputData.hasApproved; else falsyTemplate">
            <div class="message-row mt-2">
                <i class="fas fa-check-circle success mr-3"></i>
                <span class="headline">
                    A regra configurada na Tarefa foi avaliada como <b>verdadeira</b>
                </span>
                <i class="fas fa-check-circle success ml-3"></i>
            </div>
        </div>
    </ng-template>

    <!-- Task info: Regra falsy -->
    <ng-template #falsyTemplate>
        <div class="message-container">
            <div class="message-row mt-2">
                <i class="fas fa-minus-circle error mr-3"></i>
                <span class="headline">
                    A regra configurada na Tarefa foi avaliada como <b>falsa</b>
                </span>
                <i class="fas fa-minus-circle error ml-3"></i>
            </div>

            <hr />

            <div class="message-row column">
                <p>
                    A validação obrigatória e automática configurada nesta Tarefa pelos administradores foi avaliada como <b>falsa</b>
                    a partir da resposta retornada pelo sistema de destino, fazendo com que esta instância de Fluxo seja <b>encerrada</b>.
                </p>
                <ng-container *ngIf="!Utils.isNullOrEmpty(configSchema.taskGatewayRules?.message)">
                    <p>
                        Mensagem configurada pelos administradores para exibição em caso de encerramento neste ponto:
                    </p>
                    <span class="document-highlight relevant-info-font">
                        {{configSchema.taskGatewayRules?.message}}
                    </span>
                </ng-container>
            </div>
        </div>
    </ng-template>
</div>
