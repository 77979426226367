<ngx-spinner name="root" bdColor="#000000aa" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="true">
    <h5>Processando...</h5>
</ngx-spinner>

<div class="container">
    <app-header></app-header>

    <div class="content-wrapper">
        <div class="content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>

    <div class="min-res">
        <div class="card p-4 text-center">
            <i class="fas fa-mobile-alt mr-2"></i>
            <i class="fas fa-tablet-alt mx-2"></i>
            <i class="fas fa-laptop mx-2"></i>
            <b>Resolução mínima suportada: 320x320 pixels</b>
        </div>
    </div>
</div>

<p-confirmDialog icon="pi pi-exclamation-triangle" header="Confirmação" #cd>
    <p-footer>
        <button type="button" class="btn btn-outline-danger" (click)="cd.reject()">
            <i class="fas fa-times mr-2"></i>
            <span>Não</span>
        </button>

        <button type="button" class="btn btn-outline-primary float-right" (click)="cd.accept()">
            <i class="fas fa-check mr-2"></i>
            <span>Sim</span>
        </button>
    </p-footer>
</p-confirmDialog>
