<div class="wrapper p-2">
    <div class="container-flex d-flex justify-content-center">
        <div class="mjolnir-area card">
            <img src="/assets/img/mjolnir.svg" class="mjolnir m1" />
            <img src="/assets/img/mjolnir.svg" class="mjolnir m2" />

            <div class="mjolnir-body config card m-1" #configCardRef>
                <ngx-spinner bdColor="#fbfbfb" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>

                <ng-container *ngIf="isBootstrapFinished">
                    <h6>
                        <span>Deseja prosseguir com a execução do Fluxo abaixo?</span>
                    </h6>

                    <hr />

                    <div class="mjolnir-container">
                        <div class="form-group">
                            <label>Nome</label>
                            <div>{{model.name}}</div>
                        </div>

                        <div class="form-group">
                            <label>Caixa de Fluxo</label>
                            <div>{{flowInbox}}</div>
                        </div>

                        <div class="form-group">
                            <label>Versão</label>
                            <div>{{model.version}}</div>
                        </div>

                        <div class="form-group">
                            <label>Solicitante</label>
                            <div>{{model.ownerInfo}}</div>
                        </div>

                        <div class="form-group">
                            <label>Data de Criação</label>
                            <div>{{createDate}}</div>
                        </div>

                        <div class="form-group">
                            <label>Última Atualização</label>
                            <div>{{updateDate}}</div>
                        </div>

                        <form class="px-1">
                            <div class="form-footer mb-1">
                                <hr class="mt-0" />

                                <div class="form-group">
                                    <label for="papelId">Papel</label>
                                    <select class="form-control" id="papelId" name="papelId" [(ngModel)]="papelId" #papelIdElem="ngModel" required>
                                        <option *ngFor="let option of papelOptions" [value]="option.value">{{option.description}}</option>
                                    </select>
                                </div>
                                <div [hidden]="papelIdElem.valid || papelIdElem.pristine" class="alert alert-danger">Campo obrigatório</div>

                                <hr class="mt-0" />

                                <button class="btn btn-outline-primary float-right"
                                        (click)="execute()">
                                    Prosseguir com a Execução
                                </button>
                            </div>
                        </form>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
