import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'home-item',
    templateUrl: './home-item.component.html',
    styleUrls: ['./home-item.component.scss']
})
export class HomeItemComponent implements OnInit {
    @Input() inputCounter: number;
    @Input() inputCounterLabel: string;
    @Output() outputGoToEvent = new EventEmitter<any>();

    constructor() { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    goTo() {
        this.outputGoToEvent.emit();
    }

    // ======================
    // private methods
    // ======================
}
