<div class="container">
    <div class="container-flex d-flex col-md-6 m-4">
        <div class="card m-2 p-4 w-100">
            <h2>Um erro inesperado ocorreu</h2>
            <hr />
            <p>Tente novamente mais tarde ou entre em contato com o administrador do sistema.</p>
            <br /><br /><br />
            <p class="text-center"><strong>Código de referência: <span>{{refCode}}</span></strong></p>
        </div>
    </div>
</div>
