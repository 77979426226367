import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FlowDefinition } from '../../../models/flow.model';
import { NgxMatPopoverComponent } from '../../ngx-mat-popover/ngx-mat-popover.component';

@Component({
    selector: 'flow-definition-item',
    templateUrl: './flow-definition-item.component.html',
    styleUrls: ['./flow-definition-item.component.scss']
})
export class FlowDefinitionItemComponent implements OnInit {
    @ViewChild('popoverRef') popoverRef: NgxMatPopoverComponent;
    @ViewChild('wrapperRef') wrapperRef: ElementRef;
    @ViewChild('textElemRef') textElemRef: ElementRef;
    @ViewChild('rulerElemRef') rulerElemRef: ElementRef;

    model: FlowDefinition;
    pendingTaskRecipient: string = null;
    patriarch: string = null;
    organization: string = null;
    unit: string = null;

    @Input() inputModel: FlowDefinition;
    @Output() outputOpenEvent = new EventEmitter<FlowDefinition>();

    constructor() { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.model = this.inputModel;

        this.patriarch = this.model.publishedPatriarchInfo.split(' - ')[0].trim();
        this.organization = this.model.publishedOrganizationInfo.split(' - ')[0].trim();
        this.unit = this.model.publishedUnitInfo.split(' - ')[0].trim();
    }

    // ======================
    // public methods
    // ======================

    infoClick(event) {
        let target = event.currentTarget;
        target.classList.add('info-on');

        let interval = setInterval(() => {
            if (this.wrapperRef == null) {
                target.classList.remove('info-on');
                clearInterval(interval);
            }
        }, 400);
    }

    getUpdateDateString(): string {
        return this.model.updateDate == null ? '(N/D)' : new Date(this.model.updateDate).toLocaleString();
    }

    open() {
        this.outputOpenEvent.emit(this.model);
    }

    // ======================
    // private methods
    // ======================
}
