import {
    Component,
    ViewChild,
    ElementRef,
    OnInit,
    ChangeDetectorRef,
    AfterContentChecked
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {
    FlowObjectType,
    FlowObjectDefinition,
    FlowObjectTypeCategory
} from '../../models/flow-object.model';
import {
    FlowDefinition,
    FlowTarget,
    FlowDefinitionContext
} from '../../models/flow.model';
import { BaseComponent } from '../../components/base/base.component';
import { FlowDefinitionService } from '../../services/flow-definition.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../shared/enums';
import { FlowInstanceService } from '../../services/flow-instance.service';
import { AuthService } from '../../services/auth.service';
import { Organizacao, Unidade } from '../../models/organograma.model';
import { OrganogramaService } from '../../services/organograma.service';
import { FlowDefinitionContextService } from '../../services/flow-definition-context.service';
import { Utils } from '../../shared/utils';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { InputDataTaskForm } from '../../models/input-output-data.model';
import { FlowObjectDefinitionService } from '../../services/flow-object-definition.service';

@Component({
    selector: 'flow-definition',
    templateUrl: './flow-definition.component.html',
    styleUrls: ['./flow-definition.component.scss']
})
export class FlowDefinitionComponent extends BaseComponent implements OnInit, AfterContentChecked {
    // #region [ViewChild]
    @ViewChild('wrapperRef') wrapperRef: ElementRef;
    @ViewChild('descriptionMessageRef') descriptionMessageRef: ElementRef;
    @ViewChild('descriptionAreaRef') descriptionAreaRef: ElementRef;
    // #endregion

    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    FlowTarget: typeof FlowTarget = FlowTarget;
    FlowObjectTypeCategory: typeof FlowObjectTypeCategory = FlowObjectTypeCategory;
    Enums = Enums;
    // #endregion

    // #region [properties]
    model: FlowDefinition;
    flowObjectToExecute: FlowObjectDefinition;
    flowDefinitionContext: FlowDefinitionContext = null;
    publishedFlowDefinitions: FlowDefinition[] = [];
    papeisUnitIdsArray: string[] = [];
    papeisOrganizationIdsArray: string[] = [];
    papeisPatriarchIdsArray: string[] = [];
    organization: Organizacao = new Organizacao({ razaoSocial: 'N/D', sigla: 'N/D' });
    patriarch: Organizacao = new Organizacao({ razaoSocial: 'N/D', sigla: 'N/D' });
    unit: Unidade = new Unidade({ nome: 'N/D', nomeCurto: 'N/D' });
    instanceVersion: number;
    isBootstrapFinished: boolean = false;
    isFromFlowDefinitionList: boolean = false;
    isFromConecta: boolean = false;
    shouldLoadServiceFromConecta: boolean = false;
    isContextInUse: boolean = false;
    shouldShowContextSelector: boolean = true;
    canShowHeaderInfo: boolean = true;
    isShowingDescription: boolean = false;
    conectaEnvironmentSegment: string = '';
    unitAcronym: string = null;
    organizationAcronym: string = null;
    patriarchAcronym: string = null;
    // #endregion

    constructor(
        route: ActivatedRoute,
        router: Router,
        spinner: NgxSpinnerService,
        toastr: ToastrService,
        public sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
        private titleService: Title,
        private flowDefinitionService: FlowDefinitionService,
        private flowObjectDefinitionService: FlowObjectDefinitionService,
        private flowDefinitionContextService: FlowDefinitionContextService,
        private flowInstanceService: FlowInstanceService,
        private cookieService: CookieService,
        private authService: AuthService,
        private organogramaService: OrganogramaService
    ) {
        super(route, router, spinner, toastr);

        this.isFromFlowDefinitionList = this.route.snapshot.queryParamMap.get('d') == '1';

        this.shouldLoadServiceFromConecta = this.route.snapshot.url.some(x => x.path == Enums.PagesPaths.CatalogService.slice(1));
        if (atob(this.cookieService.get('prodest-eflow-cnc')).toLowerCase() == 'true') {
            this.isFromConecta = this.route.snapshot.url.some(x => x.path == Enums.PagesPaths.CatalogService.slice(1));
        }

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit(flowDefinitionId?: string, isFromContext?: boolean) {
        if (this.conectaEnvironmentSegment == '') {
            switch (atob(this.cookieService.get('prodest-eflow-env')).toLowerCase()) {
                case 'des':
                    this.conectaEnvironmentSegment = 'dev.';
                    break;

                case 'hom':
                    this.conectaEnvironmentSegment = 'hom.';
                    break;

                case 'tre':
                    this.conectaEnvironmentSegment = 'hom.';
                    break;
            }
        }

        this.papeisUnitIdsArray = Object.keys(this.authService.user.papeisUnitIds);
        this.papeisOrganizationIdsArray = Object.keys(this.authService.user.papeisOrganizationIds);
        this.papeisPatriarchIdsArray = Object.keys(this.authService.user.papeisPatriarchIds);

        try {
            let response;
            if (flowDefinitionId != null) {
                response = await this.flowDefinitionService.getById(flowDefinitionId);
            } else if (this.shouldLoadServiceFromConecta) {
                response = await this.flowDefinitionService.getByCatalogServiceId(this.paramId);
            } else {
                response = await this.flowDefinitionService.getById(this.paramId);
            }

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                this.router.navigate([Enums.PagesPaths.FlowDefinitionList]);
                return;
            }

            this.model = response.data;

            this.unitAcronym = this.model.unitInfo.split(' - ')[0].trim();
            this.organizationAcronym = this.model.organizationInfo.split(' - ')[0].trim();
            this.patriarchAcronym = this.model.patriarchInfo.split(' - ')[0].trim();

            this.titleService.setTitle(`${this.titleService.getTitle().split('|')[0].trim()} | ${this.model.publishedName} (${this.organizationAcronym}-${this.unitAcronym})`);

            // caso o FlowDefinition pertença a um Context ou não tenha vindo diretamente da tela "Iniciar um Fluxo"
            if (this.model.contextId != null) {
                const response_ContextInUse = await this.flowDefinitionContextService.isContextInUse(this.model.contextId);

                if (!response_ContextInUse.isSuccess) {
                    this.toastr.error(response_ContextInUse.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    this.router.navigate([Enums.PagesPaths.FlowDefinitionList]);
                    return;
                }

                this.isContextInUse = response_ContextInUse.data;

                if (this.isContextInUse && !isFromContext && !this.isFromFlowDefinitionList) {
                    const response_Context = await this.flowDefinitionContextService.getById(this.model.contextId);

                    if (!response_Context.isSuccess) {
                        this.toastr.error(response_Context.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                        this.router.navigate([Enums.PagesPaths.FlowDefinitionList]);
                        return;
                    }

                    this.flowDefinitionContext = response_Context.data;
                    this.publishedFlowDefinitions = this.flowDefinitionContext.flowDefinitions.filter(x =>
                        x.version > 1
                        || (x.version == 1 && x.isPublished)
                    ).sort((a, b) => a.contextIndex - b.contextIndex);

                    return;
                }
            }

            this.shouldShowContextSelector = false;

            let flowStarter = this.model.flowObjectDefinitions.find(x => x.isFlowStarter);
            this.instanceVersion = this.model.isPublished ? this.model.version : this.model.version - 1;

            if (flowStarter == null) {
                this.toastr.error(Enums.Messages.NoFlowStarterError, Enums.Messages.Error, Utils.getToastrErrorOptions());
                this.router.navigate([Enums.PagesPaths.FlowDefinitionList]);
                return;
            }

            const response_FormData = await this.flowObjectDefinitionService.getFormData(flowStarter.id);

            if (!response_FormData.isSuccess) {
                this.toastr.error(response_FormData.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                this.router.navigate([Enums.PagesPaths.FlowDefinitionList]);
                return;
            }

            flowStarter.formSchema = response_FormData.data;

            this.flowObjectToExecute = flowStarter;

            if (
                this.model.targetId == FlowTarget.Unit && !Object.keys(this.authService.user.papeisUnitIds).includes(this.model.publishedUnitId)
                || this.model.targetId == FlowTarget.Organization && !Object.keys(this.authService.user.papeisOrganizationIds).includes(this.model.publishedOrganizationId)
                || this.model.targetId == FlowTarget.Patriarch && !Object.keys(this.authService.user.papeisPatriarchIds).includes(this.model.publishedPatriarchId)
            ) {
                this.canShowHeaderInfo = false;

                const response = await this.organogramaService.getUnidade(this.model.publishedUnitId);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.unit = response.data;
                this.organization = response.data.organizacao;
                this.patriarch = response.data.organizacao.organizacaoPai;
            }
        } catch (error) {
            this.toastr.error(Enums.Messages.GeneralError, Enums.Messages.Error, Utils.getToastrErrorOptions());
            console.error(error);
            this.router.navigate([Enums.PagesPaths.FlowDefinitionList]);
            this.spinner.hide();
        } finally {
            setTimeout(() => {
                if (!this.shouldShowContextSelector) {
                    this.toggleDescription();
                }
            }, 500);

            setTimeout(() => {
                this.isBootstrapFinished = true;
                setTimeout(() => {
                    if (this.wrapperRef != null) {
                        const element = this.wrapperRef.nativeElement as HTMLElement;
                        element.style.height = '100%';
                        setTimeout(() => {
                            element.style.overflow = 'unset';
                        }, 1000);
                    }

                    this.spinner.hide();
                }, 300);
            }, 300);
        }
    }

    ngAfterContentChecked() {
        this.changeDetectorRef.detectChanges();
    }

    // ======================
    // public methods
    // ======================

    async createFlowInstance(inputData: InputDataTaskForm) {
        Utils.scrollToTop();
        this.toggleDescription(false);

        this.spinner.show('afterSubmit');

        const response = await this.flowInstanceService.create(this.flowObjectToExecute, inputData);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            this.router.navigateByUrl('/', { skipLocationChange: true })
                .then(() => this.router.navigate([Enums.PagesPaths.FlowDefinition + (this.isFromConecta ? Enums.PagesPaths.CatalogService : ''), this.paramId]));
            return;
        }

        this.toastr.success(response.message.description, Enums.Messages.Success);
        this.router.navigate([Enums.PagesPaths.FlowInstance + (this.isFromConecta ? Enums.PagesPaths.CatalogService : ''), response.data.id]);
        this.spinner.hide('afterSubmit');

        // limpa rascunhos do formulário corrente
        window.localStorage.removeItem(this.model.id);
        window.localStorage.removeItem(`${this.model.id}_default`);
    }

    async loadFlowDefinition(flowDefinitionId: string) {
        this.spinner.show();
        await this.ngOnInit(flowDefinitionId, true);
    }

    async loadContextSelection() {
        if (!this.shouldShowContextSelector) {
            this.shouldShowContextSelector = true;
            await this.ngOnInit();
        }
    }

    getDescriptionHtml(): string {
        return this.model.publishedDescription;
    }

    toggleDescription(setValue?: boolean) {
        this.isShowingDescription = setValue == null ? !this.isShowingDescription : setValue;

        setTimeout(() => {
            if (this.descriptionAreaRef != null) {
                this.descriptionAreaRef.nativeElement.style.height = !this.isShowingDescription
                    ? '0'
                    : `calc(${this.descriptionMessageRef.nativeElement.clientHeight}px + 50px)`;
            }
        }, 1);
    }

    // ======================
    // private methods
    // ======================
}
