import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FlowInstance } from '../models/flow.model';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { FlowInstanceServiceGetParams } from './interfaces/flow-instance.interface';
import { FlowObjectDefinition, FlowObjectInstance } from '../models/flow-object.model';

@Injectable({
    providedIn: 'root'
})
export class FlowInstanceService extends BaseService {
    controllerRoute: string = 'FlowInstance';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async get(getParams?: FlowInstanceServiceGetParams): Promise<IBaseModel<FlowInstance[]>> {
        let httpParams = new HttpParams();

        if (getParams?.version != null) httpParams = httpParams.append('version', getParams.version.toString());
        if (getParams?.flowDefinitionId != null) httpParams = httpParams.append('flowDefinitionId', getParams.flowDefinitionId);
        if (getParams?.stateId != null) httpParams = httpParams.append('stateId', getParams.stateId.toString());

        return this.httpClient.get<IBaseModel<FlowInstance[]>>(`${this.apiBaseUrl}/${this.controllerRoute}`, { params: httpParams }).toPromise();
    }

    public async getById(id: string, version?: number): Promise<IBaseModel<FlowInstance>> {
        return this.httpClient.get<IBaseModel<FlowInstance>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/${version || ''}`).toPromise();
    }

    public async filterPaginated(getParams?: FlowInstanceServiceGetParams): Promise<IBaseModel<FlowInstance[]>> {
        let httpParams = new HttpParams();

        if (getParams?.page != null) httpParams = httpParams.append('page', getParams.page.toString());
        if (getParams?.pageSize != null) httpParams = httpParams.append('pageSize', getParams.pageSize.toString());
        if (getParams?.sortField != null) httpParams = httpParams.append('sortField', getParams.sortField);
        if (getParams?.isSortDescending != null) httpParams = httpParams.append('isSortDescending', getParams.isSortDescending.toString());
        if (getParams?.skipDataResults != null) httpParams = httpParams.append('skipDataResults', getParams.skipDataResults.toString());
        if (getParams?.searchTokens != null && getParams.searchTokens.length > 0) {
            httpParams = httpParams.append('searchTokens', getParams.searchTokens.map(x => x.trim()).join('◄►'));
        }

        return this.httpClient.get<IBaseModel<FlowInstance[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/FilterPaginated`, { params: httpParams }).toPromise();
    }

    public async create(flowObjectDefinition: FlowObjectDefinition, inputData: any): Promise<IBaseModel<FlowInstance>> {
        return this.httpClient.post<IBaseModel<FlowInstance>>(`${this.apiBaseUrl}/${this.controllerRoute}`, { flowObjectDefinition, inputData: JSON.stringify(inputData) }).toPromise();
    }

    public async manuallyFinishOrCancel(data: FlowObjectInstance): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/ManuallyFinishOrCancel`, data).toPromise();
    }

    public async getServerTime(): Promise<Date> {
        return this.httpClient.get<Date>(`${this.apiBaseUrl}/${this.controllerRoute}/GetServerTime`).toPromise();
    }
}
