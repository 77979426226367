import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Enums } from '../../shared/enums';
import { Utils } from '../../shared/utils';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        public router: Router,
        private authService: AuthService,
        private toastr: ToastrService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    // ======================
    // public methods
    // ======================

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.allowAccess(next.data);
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.allowAccess(next.data);
    }

    // ======================
    // private methods
    // ======================

    private allowAccess(routeData: any): Promise<boolean> {
        return this.authService.getStatus()
            .then(async response => {
                if (response.isSuccess && response.data.isAuthenticated) {
                    if (!this.authService.user) {
                        await this.authService.getUserProfile(true);
                    }

                    if (routeData && routeData.profiles && routeData.profiles.length > 0) {
                        const isAllowed = this.authService.hasProfiles(routeData.profiles);
                        if (isAllowed) {
                            return true;
                        } else {
                            if (routeData.profiles.includes(Enums.Profiles.ErrorPage)) {
                                return true;
                            }

                            this.toastr.error(Enums.Messages.NoAccessToResource, Enums.Messages.AccessDenied, Utils.getToastrErrorOptions());
                            //this.router.navigate(['/']); /* se houver página pública sem AC, navegar para lá */
                            return false;
                        }
                    } else {
                        return true;
                    }
                } else {
                    this.authService.login();
                    return false;
                }
            })
            .catch(error => {
                this.authService.login();
                return false;
            });
    }
}
