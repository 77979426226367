import { Enums } from "../../../../shared/enums";

export const FORMIO_OPTIONS = {
    hooks: {
        beforeSubmit: (a, b) => { } /* substituído no "flow-object-form" */
    },
    alerts: {
        submitMessage: Enums.Messages.FormSubmitSuccess
    },
    errors: {
        message: Enums.Messages.FormSubmitError
    },
    i18n: {
        pt: {
            submitError: 'Por favor, confira o formulário e corrija as pendências antes de enviá-lo.',
            mask: 'O valor de "{{field}}" não corresponde ao formato esperado',
            select: '"{{field}}" contém uma seleção inválida',
            pattern: '"{{field}}" não atende ao padrão "{{pattern}}"',
            minLength: '"{{field}}" deve conter ao menos "{{length}}" caracteres',
            maxLength: '"{{field}}" deve conter não mais que "{{length}}" caracteres',
            minWords: '"{{field}}" deve conter ao menos "{{length}}" palavras',
            maxWords: '"{{field}}" deve conter não mais que "{{length}}" palavras',
            min: '"{{field}}" não deve ser menor que "{{min}}"',
            max: '"{{field}}" não deve ser maior que "{{max}}"',
            maxDate: '"{{field}}" não deve conter uma data depois de "{{- maxDate}}"',
            minDate: '"{{field}}" não deve conter uma data antes de "{{- minDate}}"',
            maxYear: '"{{field}}" não deve conter um ano maior que "{{maxYear}}"',
            minYear: '"{{field}}" não deve conter um ano menor que "{{minYear}}"',
            invalid_regex: '"{{field}}" não atende ao padrão "{{regex}}"',
            invalid_date: '"{{field}}" não é uma data válida',

            // Geral
            'Drag and Drop a form component': 'Arraste e solte um componente',
            'Search field(s)': 'Procurar campos',
            'Component': '',
            'required': 'Obrigatório',
            'Edit': 'Editar',
            'Move': 'Mover',
            'Paste below': 'Colar Abaixo',
            'Copy': 'Copiar',
            'words': 'palavras',
            'characters': 'caracteres',

            // Campos: Básico
            'Basic': 'Básico',
            'Text Field': 'Campo Texto',
            'Text Area': 'Área de Texto',
            'Number': 'Número',
            'Password': 'Senha',
            'Checkbox': 'Caixa de Verificação',
            'Select Boxes': 'Seleção Múltipla',
            'Select': 'Lista de Seleção',
            'Radio': 'Botão de Opção',
            'Button': 'Botão',

            // Campos: Avançado
            'Advanced': 'Avançado',
            'Email': 'E-mail',
            'Url': 'URL',
            'Phone Number': 'Telefone',
            'Address': 'Endereço',
            'Date / Time': 'Data / Hora',
            'Day': 'Dia',
            'Time': 'Hora',
            'Currency': 'Valor Monetário',
            'File': 'Arquivo',

            // Edit Form: Exibição
            'Display': 'Exibição',
            'Label': 'Rótulo',
            'Field Label': 'Rótulo do Campo',
            'The label for this field that will appear next to it.': 'O rótulo para este campo que aparecerá próximo a ele.',
            'Label Position': 'Posição do Rótulo',
            'Position for the label for this field.': 'Posição do rótulo para este campo.',
            'Type to search': 'Digite para pesquisar',
            'Top': 'Topo',
            'Left (Left-aligned)': 'Esquerda (alinhado à esquerda)',
            'Left (Right-aligned)': 'Esquerda (alinhado à direita)',
            'Right (Left-aligned)': 'Direita (alinhado à esquerda)',
            'Right (Right-aligned)': 'Direita (alinhado à direita)',
            'Bottom': 'Base',
            'Label Width': 'Largura do Rótulo',
            'The width of label on line in percentages.': 'Largura do rótulo em porcentagem.',
            'Label Margin': 'Margem do Rótulo',
            'The width of label margin on line in percentages.': 'Margem do rótulo em porcentagem.',
            'Placeholder': 'Marcador de Lugar',
            'The placeholder text that will appear when this field is empty.': 'O texto marcador de lugar que aparecerá quando este campo estiver vazio.',
            'Description': 'Descrição',
            'The description is text that will appear below the input field.': 'A descrição é um texto que aparecerá abaixo do campo.',
            'Description for this field.': 'Descrição para este campo.',
            'Tooltip': 'Dica',
            'Adds a tooltip to the side of this field.': 'Adiciona uma dica ao lado deste campo.',
            'To add a tooltip to this field, enter text here.': 'Para adicionar uma dica para este campo, entre com texto aqui.',
            'Hide Label': 'Ocultar Rótulo',
            'Hide the label (title, if no label) of this component. This allows you to show the label in the form builder, but not when it is rendered.': 'Ocultar o rótulo (ou título, se não houver rótulo) deste campo. O rótulo estará visível na criação do formulário mas não na visualização do mesmo.',
            'Show Word Counter': 'Exibir Contador de Palavras',
            'Show a live count of the number of words.': 'Exibir um contador ao vivo do número de palavras.',
            'Show Character Counter': 'Exibir Contador de Caracteres',
            'Show a live count of the number of characters.': 'Exibir um contador ao vivo do número de caracteres.',
            'Initial Focus': 'Foco Inicial',
            'Make this field the initially focused element on this form.': 'Conceder a este campo o foco inicial no formulário.',
            'Disabled': 'Desabilitado',
            'Disable the form input.': 'Desabilitar o campo no formulário.',
            'Rows': 'Linhas',
            'This allows control over how many rows are visible in the text area.': 'Quantidade de linhas visíveis na área de texto.',
            'Options Label Position': 'Posição do Rótulo das Opções',
            'Position for the label for options for this field.': 'Posição do rótulo das opções deste campo.',
            'Left': 'Esquerda',
            'Right': 'Direita',
            'Base': 'Embaixo',
            'Inline Layout': 'Exibição em Linha',
            'Displays the checkboxes/radios horizontally.': 'Exibe as opções do campo horizontalmente.',
            'Unique Options': 'Opções Únicas',
            'Display only unique dropdown options.': 'Exibir apenas opções únicas para o campo.',
            'Submit': 'Enviar',
            'Action': 'Ação',
            'This is the action to be performed by this button.': 'A ação a ser executada por este botão.',
            'Reset': 'Reiniciar',
            'Theme': 'Tema de Cores',
            'The color theme of this button.': 'O tema de cores deste botão.',
            'Primary': 'Primário',
            'Secondary': 'Secundário',
            'Info': 'Informação',
            'Success': 'Sucesso',
            'Danger': 'Perigo',
            'Warning': 'Aviso',
            'Size': 'Tamanho',
            'The size of this button.': 'O tamanho deste botão.',
            'Extra Small': 'Muito Pequeno',
            'Small': 'Pequeno',
            'Medium': 'Médio',
            'Large': 'Grande',
            'Block Button': 'Botão em Bloco',
            'This control should span the full width of the bounding container.': 'Expandir o botão para a largura total da área que o engloba.',
            'Disable on Form Invalid': 'Desabilitar se Formulário Inválido',
            'This will disable this field if the form is invalid.': 'Desabilitar o botão caso o estado atual do formulário seja inválido.',
            'Input Mask': 'Máscara',
            'Custom CSS Class': 'Classe CSS Personalizada',
            'Custom CSS class to add to this component.': 'Classe CSS personalizada a ser adicionada ao campo na exibição do formulário.',

            // Edit Form: Arquivo
            'Display as image(s)': 'Exibir Arquivos como Imagens',
            'Instead of a list of linked files, images will be rendered in the view.': 'Em vez de links de arquivos, imagens serão exibidas na visualização do campo.',
            'File Types': 'Tipos de Arquivo',
            'Specify file types to classify the uploads. This is useful if you allow multiple types of uploads but want to allow the user to specify which type of file each is.': 'Especifique os tipos de arquivo para classificar os uploads.',
            'Enable web camera': 'Habilitar Webcam',
            'This will allow using an attached camera to directly take a picture instead of uploading an existing file.': 'Permite utilizar uma webcam conectada para tirar uma foto diretamente em vez de fazer o upload de um arquivo existente.',

            // Edit Form: Dados
            'Data': 'Dados',
            'Default Value': 'Valor Padrão',
            'The will be the value for this field, before user interaction. Having a default value will override the placeholder text.': 'O valor padrão para este campo, antes da interação do usuário.',
            'Use Thousands Separator': 'Usar Separador de Milhares',
            'Separate thousands by local delimiter.': 'Separar os milhares por um caracter delimitador localizado.',
            'Decimal Places': 'Casas Decimais',
            'The maximum number of decimal places.': 'Quantidade máxima de casas decimais.',
            'Require Decimal': 'Sempre Mostrar Casas Decimais',
            'Always show decimals, even if trailing zeros.': 'Sempre mostrar casas decimais, ainda que elas sejam todas zero.',
            'Password fields are automatically encrypted using 1-way salted bcrypt hashes. These hashes are also protected and not returned in the API.': 'Campos de senha são encriptados automaticamente usando hashes BCrypt (one-way, salted).',
            'Text Case': 'Capitalização',
            'When data is entered, you can change the case of the value.': 'Capitalização permitida para o texto contido no campo.',
            'Mixed (Allow upper and lower case)': 'Mista (permitir maiúsculas e minúsculas)',
            'Uppercase': 'Maiúsculas',
            'Lowercase': 'Minúsculas',
            'Values': 'Opções',
            'The radio button values that can be picked for this field. Values are text submitted with the form data. Labels are text that appears next to the radio buttons on the form.': 'As opções para este campo. O "Valor" é o texto submetido juntamente com o formulário. O "Rótulo" é o texto que aparecerá próximo a cada opção.',
            'Value': 'Valor',
            'Shortcut': 'Tecla de Atalho',
            'The shortcut key for this option.': 'A tecla de atalho opcional para esta opção.',
            'Add Another': 'Adicionar Outro',
            'Data Source Values': 'Valores da Fonte de Dados',
            'Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.': 'Valores utilizados como fonte de dados. Rótulos são exibidos na lista de opções. Valores são os textos correspondentes que serão enviados na submissão do formulário.',
            'Use exact search': 'Busca Exata',
            'Disables search algorithm threshold.': 'Efetua apenas buscas exatas, desconsiderando similaridades.',
            'The currency to use in currency formatting. Possible values are (ISO-4217) currency codes.': 'Moeda a ser utilizada na formatação do campo.',
            'US Dollar (USD)': 'Dólar Americano (USD)',
            'Pound Sterling (GBP)': 'Libra Esterlina (GBP)',
            'Brazilian Real (BRL)': 'Real Brasileiro (BRL)',

            // Edit Form: Dia
            'Type': 'Tipo',
            'Hidden': 'Ocultar',
            'Hide the Day part of the component.': 'Ocultar a parte do "Dia" do campo.',

            // Edit Form: Mês
            'Month': 'Mês',
            'Type of input': 'Tipo',
            'Hide the Month part of the component.': 'Ocultar a parte do "Mês" do campo.',

            // Edit Form: Ano
            'Year': 'Ano',
            'Hide the Year part of the component.': 'Ocultar a parte do "Ano" do campo.',
            'Minimum Year': 'Ano Mínimo',
            'The minimum year that can be entered.': 'O ano mínimo a ser aceito.',
            'Maximum Year': 'Ano Máximo',
            'The maximum year that can be entered.': 'O ano máximo a ser aceito.',

            // Edit Form: Validação de Dados
            'Validation': 'Validação de Dados',
            'Required': 'Obrigatório',
            'A required field must be filled in before the form can be submitted.': 'Um campo obrigatório deve ser preenchido antes que se possa submeter o formulário.',
            'Minimum Length': 'Tamanho Mínimo',
            'The minimum length requirement this field must meet.': 'O tamanho mínimo que este campo pode atingir.',
            'Maximum Length': 'Tamanho Máximo',
            'The maximum length requirement this field must meet.': 'O tamanho máximo que este campo pode atingir.',
            'Minimum Value': 'Valor Mínimo',
            'The minimum value this field must have before the form can be submitted.': 'O valor mínimo que este campo deve ter antes que se possa submeter o formulário.',
            'Maximum Value': 'Valor Máximo',
            'The maximum value this field can have before the form can be submitted.': 'O valor máximo que este campo deve ter antes que se possa submeter o formulário.',
            'Regular Expression Pattern': 'Expressão Regular',
            'The regular expression pattern test that the field value must pass before the form can be submitted.': 'O teste de expressão regular pelo qual este campo deve passar antes que o formulário seja submetido.',
            'Custom Error Message': 'Mensagem de Erro Personalizada',
            'Error message displayed if any error occurred.': 'Mensagem de erro exibida caso qualquer erro neste campo ocorra.',
            'Require Day': 'Dia Obrigatório',
            'Require Month': 'Mês Obrigatório',
            'Require Year': 'Ano Obrigatório',
            'Maximum Day': 'Data Máxima Permitida',
            'Minimum Day': 'Data Mínima Permitida',

            // Edit Form: Condicional
            'Conditional': 'Condicional',
            'This component should Display:': 'Este campo deve aparecer...',
            'True': 'Sim',
            'False': 'Não',
            'When the form component:': '...quando o componente do formulário...',
            'Has the value:': '...tiver o seguinte valor:',

            // Edit Form: Layout
            'HTML Attributes': 'Personalizar Atributos HTML do Campo',
            'Attribute Name': 'Nome do Atributo',
            'Attribute Value': 'Valor do Atributo',
            'Add Attribute': 'Adicionar Atributo',

            // Edit Form: Pré-visualização
            'Preview': 'Pré-visualização',
            'No choices to choose from': 'Nenhuma opção para selecionar',
            'invalid_email': 'E-mail inválido',
            'invalid_url': 'URL inválida',
            'January': 'Janeiro',
            'February': 'Fevereiro',
            'March': 'Março',
            'April': 'Abril',
            'May': 'Maio',
            'June': 'Junho',
            'July': 'Julho',
            'August': 'Agosto',
            'September': 'Setembro',
            'October': 'Outubro',
            'November': 'Novembro',
            'December': 'Dezembro',
            "invalid_day": "Data inválida",
            "invalid_month": "Data inválida",
            "invalid_year": "Data inválida",
            'File Name': 'Nome do Arquivo',
            'Drop files to attach,': 'Arraste arquivos para anexar,',
            'or': 'ou',
            'browse': 'navegue',
            'Gallery': 'Galeria',
            'Camera': 'Câmera',
            'Use Camera,': 'Use a Câmera,',
            'Take Picture': 'Tirar Foto',
            'Switch to file upload': 'Trocar para upload de arquivo',
            'No storage has been set for this field. File uploads are disabled until storage is set up.': 'Nenhum armazenamento foi configurado para este campo. O upload está desabilitado até que o armazenamento seja configurado.',
            'Save': 'Salvar Alterações',
            'Cancel': 'Cancelar',
            'Remove': 'Remover Campo'
        }
    }
};
