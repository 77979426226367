import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild
} from '@angular/core';
import {
    FlowObjectDefinition,
    FlowObjectInstance,
    FlowObjectInstanceState,
    FlowObjectType
} from '../../../models/flow-object.model';
import {
    ConfigSchema,
    Stakeholder,
    StakeholderType,
    StakeholderTypeDescription
} from '../../../models/config-schema.model';
import {
    InputDataTaskForm,
    InputDataTaskForward,
    InputDataTaskRegisterProcess,
    OutputDataTaskRegisterProcess
} from '../../../models/input-output-data.model';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../shared/enums';
import { AuthService } from '../../../services/auth.service';
import { AcessoCidadaoService } from '../../../services/acesso-cidadao.service';
import { EDocsService } from '../../../services/edocs.service';
import { MatDialog } from '@angular/material/dialog';
import { PdfPreviewDialogComponent } from '../../pdf-preview-dialog/pdf-preview-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Papel, Processo } from '../../../models/edocs.model';
import { Utils } from '../../../shared/utils';
import { FlowDefinition, FlowInstance } from '../../../models/flow.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { HTML_TEMPLATE_REGISTER_PROCESS_CANCEL } from './tinymce-template-register-process-cancel';
import { FlowObjectDefinitionService } from '../../../services/flow-object-definition.service';

@Component({
    selector: 'flow-object-register-process',
    templateUrl: './flow-object-register-process.component.html',
    styleUrls: ['./flow-object-register-process.component.scss']
})
export class FlowObjectRegisterProcessComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('papelRef') papelRef: ElementRef;
    // #endregion

    // #region [Type properties]
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    StakeholderType: typeof StakeholderType = StakeholderType;
    StakeholderTypeDescription: typeof StakeholderTypeDescription = StakeholderTypeDescription;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [properties]
    DEFAULT_LOADING_TEXT: string = 'Carregando...' as const;

    model: FlowObjectInstance;
    flowObjectDefinition: FlowObjectDefinition = null;
    inputData: InputDataTaskRegisterProcess = null;
    outputData: OutputDataTaskRegisterProcess = null;
    configSchema: ConfigSchema = null;
    papeisSelector: Papel[] = [];
    process: Processo = null;
    registrar: string = '';
    forwardingProtocol: string = '(N/D)';
    processProtocol: string = '(N/D)';
    previousDocuments: any[] = [];
    currentDocuments: any[] = [];
    isCancelFlowInstanceAreaVisible: boolean = false;
    hasErrorsState: {
        type: 'citizenName' | 'companyName' | 'cnpj' | 'companyEmail',
        hasErrors: boolean
    }[];
    publicMessageHtml: SafeHtml = this.DEFAULT_LOADING_TEXT;
    // #endregion

    // #region [getters]
    get hasPendingTask(): boolean {
        return this.model?.stateId == FlowObjectInstanceState.Started;
    }
    get hasErrors(): boolean {
        return this.hasErrorsState?.some(x => x.hasErrors);
    }
    get isTaskPublicAgentActor(): boolean {
        return this.authService?.user?.isPublicAgent
            && this.model?.flowObjectInstanceActors.some(x => x.actorId == this.authService.user.id);
    }
    get isReadOnly(): boolean {
        return [
            FlowObjectInstanceState.Finished,
            FlowObjectInstanceState.NotStarted
        ].includes(this.model?.stateId);
    }
    get isTaskCancelled(): boolean {
        return this.isTaskAutomaticallyCancelled || this.isTaskManuallyCancelled;
    }
    get isTaskAutomaticallyCancelled(): boolean {
        return this.model?.stateId == FlowObjectInstanceState.AutomaticallyCancelled;
    }
    get isTaskManuallyCancelled(): boolean {
        return this.model?.stateId == FlowObjectInstanceState.ManuallyCancelled;
    }
    get isLackingMessage(): boolean {
        return Utils.isNullOrEmpty(this.inputData?.cancelMessage) || this.inputData?.cancelMessage.trim().length < 5;
    }
    get dateString(): string {
        return this.model == null ? '' : new Date(this.model.updateDate).toLocaleString();
    }
    get processSummary(): string {
        return this.configSchema?.taskRegisterProcess?.processSummary?.replaceAll('\n', '<br>');
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectInstance;
    @Input() inputFlowInstance: FlowInstance;
    @Input() inputFlowDefinition: FlowDefinition;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectInstance>();
    @Output() outputCancelFlowInstanceEvent = new EventEmitter<FlowObjectInstance>();
    // #endregion

    constructor(
        private sanitizer: DomSanitizer,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private authService: AuthService,
        private flowObjectDefinitionService: FlowObjectDefinitionService,
        private acessoCidadaoService: AcessoCidadaoService,
        private eDocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.initializeHasErrorsState();

        this.model = this.inputModel;
        this.flowObjectDefinition = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.id == this.model.flowObjectDefinitionId);
        this.inputData = !Utils.isNullOrEmpty(this.model.inputData)
            ? Object.assign(new InputDataTaskRegisterProcess(), JSON.parse(this.model.inputData))
            : new InputDataTaskRegisterProcess()
        this.outputData = !Utils.isNullOrEmpty(this.model.outputData)
            ? Object.assign(new OutputDataTaskRegisterProcess(), JSON.parse(this.model.outputData))
            : new OutputDataTaskRegisterProcess();
        this.configSchema = JSON.parse(this.model.flowObjectDefinition.configSchema) as ConfigSchema;

        if (this.isTaskPublicAgentActor) {
            this.papeisSelector = this.authService.user.papeis;

            // força o usuário a selecionar um papel manualmente
            setTimeout(() => this.inputData.eDocsData.signerId = null, 50);
        }

        if (this.papeisSelector.length == 0) {
            this.inputData.eDocsData.signerId = this.authService.user.id;
        }

        // #region [Encaminhamento]
        const response = await this.eDocsService.getEncaminhamento(this.inputData.eDocsData.forwardingId);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        const encaminhamento = response.data;
        this.forwardingProtocol = encaminhamento.protocolo;
        // #endregion

        // #region [Processo]
        if (!Utils.isNullOrEmpty(this.outputData.eDocsData.processId)) {
            // caso já tenha sido autuado
            const response = await this.eDocsService.getProcesso(this.outputData.eDocsData.processId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.process = response.data;
            this.processProtocol = this.process.protocolo;

            const response_Autuador = await this.eDocsService.getProcessoAutuador(this.outputData.eDocsData.processId);

            if (!response_Autuador.isSuccess) {
                this.toastr.error(response_Autuador.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            // resolve o nome do autuador de maneira específica
            const autuador = response_Autuador.data;
            this.registrar = autuador.nome;
            this.registrar += ` - ${autuador.papel.setor.sigla}`;
            this.registrar += ` - ${autuador.papel.setor.organizacao.sigla}`;
            this.registrar += ` - ${autuador.papel.setor.organizacao.patriarca.sigla}`;
        } else {
            // caso ainda não tenha sido autuado, resolve o nome do autuador de maneira genérica
            let lastForwardingTask = this.inputFlowInstance.flowObjectInstances
                .filter(x => x.flowObjectDefinition.typeId == FlowObjectType.TaskForward)
                .sort((a, b) => new Date(b.createDate).toISOString().localeCompare(new Date(a.createDate).toISOString()))[0];
            this.registrar = (JSON.parse(lastForwardingTask.inputData) as InputDataTaskForward).recipientName;
        }
        // #endregion

        // #region [Interessados do Processo]
        this.configSchema.taskRegisterProcess.stakeholders.forEach(async x => {
            if (x.type == StakeholderType.Citizen) {
                x = await this.resolveCitizenStakeholder(x);
            } else if (x.type == StakeholderType.Company) {
                x = await this.resolveCompanyStakeholder(x);
            }
        });
        // #endregion

        // #region [Resumo do Processo]
        this.resolveProcessSummary();
        // #endregion

        // #region [Documentos Entranhados]
        for (let item of encaminhamento.documentosEncaminhamento.filter(x => !x.controle)) {
            if (!this.previousDocuments.some(x => x.registro == item.documento.registro)) {
                this.previousDocuments.push({
                    id: item.documento.id,
                    registro: item.documento.registro,
                    filename: `${item.documento.nome}.${item.documento.extensao}`
                });
            }
        }
        // #endregion

        // #region [documentos capturados na Tarefa corrente]
        if (!Utils.isNullOrEmpty(this.outputData)) {
            let filtered = encaminhamento.documentosEncaminhamento.filter(x =>
                x.documento.encaminhamentoId == this.outputData.eDocsData.forwardingId
                && !x.controle
            );

            for (let item of filtered) {
                if (!this.previousDocuments.some(x => x.registro == item.documento.registro)) {
                    this.currentDocuments.push({
                        id: item.documento.id,
                        registro: item.documento.registro,
                        filename: `${item.documento.nome}.${item.documento.extensao}`
                    });
                }
            }
        }
        // #endregion

        this.publicMessageHtml = this.sanitizer.bypassSecurityTrustHtml(Utils.getPublicMessageHtml(
            this.cookieService,
            this.flowObjectDefinition,
            this.forwardingProtocol,
            this.processProtocol
        ));
    }

    // ======================
    // public methods
    // ======================

    async openDocumentModal(id: string) {
        this.spinner.show('flowInstance');

        const response = await this.eDocsService.getDocumentoDownloadUrl(id);

        this.spinner.hide('flowInstance');

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        setTimeout(() => {
            this.dialog.open(PdfPreviewDialogComponent, {
                data: {
                    content: response.data
                }
            });
        }, 200);
    }

    isErrorMessage(message: string): boolean {
        return message[0] == '(' && message.slice(-1) == ')';
    }

    showCancelFlowInstanceArea() {
        this.isCancelFlowInstanceAreaVisible = true;
    }

    cancelFlowInstance() {
        if (this.isLackingMessage) {
            this.toastr.warning(Enums.Messages.MessageMinlengthError, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            return;
        }

        this.flowObjectDefinition.documentHtmlContent = HTML_TEMPLATE_REGISTER_PROCESS_CANCEL;
        this.model.flowObjectDefinition = this.flowObjectDefinition;
        this.model.inputData = JSON.stringify(this.inputData);

        this.outputCancelFlowInstanceEvent.emit(this.model);
    }

    async submit() {
        this.model.inputData = JSON.stringify(this.inputData);

        // caso haja algum Interessado do Processo que não conseguiu ser resolvido mas pode ser ignorado
        let ignoredStakeholders = this.configSchema.taskRegisterProcess.stakeholders.filter(x => x.shouldIgnore);
        if (ignoredStakeholders.length > 0) {
            let tempConfigSchema = JSON.parse(this.model.flowObjectDefinition.configSchema) as ConfigSchema;
            for (let ignoredStakeholder of ignoredStakeholders) {
                tempConfigSchema.taskRegisterProcess.stakeholders.find(x => x.timestamp == ignoredStakeholder.timestamp).shouldIgnore = ignoredStakeholder.shouldIgnore;
            }

            this.model.flowObjectDefinition.configSchema = JSON.stringify(tempConfigSchema);

            const response = await this.flowObjectDefinitionService.update(this.model.flowObjectDefinition);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }
        }

        this.outputSubmitEvent.emit(this.model);
    }

    // ======================
    // private methods
    // ======================

    private async resolveCitizenStakeholder(stakeholder: Stakeholder): Promise<Stakeholder> {
        const initialName = stakeholder.name;

        if (stakeholder.isCitizenFlowInstanceOwner) {
            stakeholder.name = this.inputFlowInstance.ownerInfo.split(' - ')[0];
        } else if (!Utils.isNullOrEmpty(stakeholder.citizenCpfFormField)) {
            let formData = this.getFormData();

            if (formData != null) {
                let formFieldCpfValue = formData[stakeholder.citizenCpfFormField] as string;
                if (!Utils.isNullOrEmpty(formFieldCpfValue)) {
                    formFieldCpfValue = formFieldCpfValue.replace(/[^\d]/g, '');

                    const response_CidadaoExiste = await this.acessoCidadaoService.getCidadaoExiste(formFieldCpfValue);

                    if (!response_CidadaoExiste.isSuccess) {
                        this.toastr.error(response_CidadaoExiste.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                        return;
                    }

                    const response_CidadaoSub = await this.acessoCidadaoService.getCidadaoSub(formFieldCpfValue);

                    if (!response_CidadaoSub.isSuccess) {
                        this.toastr.error(response_CidadaoSub.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                        return;
                    }

                    const response_RestritoCidadao = await this.acessoCidadaoService.getRestritoCidadao(response_CidadaoSub.data.sub);

                    if (!response_RestritoCidadao.isSuccess) {
                        this.toastr.error(response_RestritoCidadao.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                        return;
                    }

                    stakeholder.name = this.hideLastName(response_RestritoCidadao.data.nome);
                }
            }
        }

        if (stakeholder.name == initialName) {
            if (stakeholder.isMandatory) {
                this.hasErrorsState.find(x => x.type == 'citizenName').hasErrors = true;
                stakeholder.name = '(erro ao obter nome do cidadão)';
            } else {
                stakeholder.shouldIgnore = true;
            }
        }

        return stakeholder;
    }

    private async resolveCompanyStakeholder(stakeholder: Stakeholder): Promise<Stakeholder> {
        let formData = null;

        if (!Utils.isNullOrEmpty(stakeholder.companyNameFormField)) {
            formData = formData || this.getFormData();

            if (formData != null) {
                let companyNameFormFieldValue = formData[stakeholder.companyNameFormField] as string;

                if (!Utils.isNullOrEmpty(companyNameFormFieldValue)) {
                    stakeholder.companyName = companyNameFormFieldValue;
                } else {
                    if (stakeholder.isMandatory) {
                        this.hasErrorsState.find(x => x.type == 'companyName').hasErrors = true;
                        stakeholder.companyName = '(erro ao obter razão social)';
                    } else {
                        stakeholder.shouldIgnore = true;
                    }
                }
            }
        }

        if (!Utils.isNullOrEmpty(stakeholder.cnpjFormField)) {
            formData = formData || this.getFormData();

            if (formData != null) {
                let cnpjFormFieldValue = formData[stakeholder.cnpjFormField] as string;

                if (!Utils.isNullOrEmpty(cnpjFormFieldValue)) {
                    stakeholder.cnpj = cnpjFormFieldValue;
                } else {
                    if (stakeholder.isMandatory) {
                        this.hasErrorsState.find(x => x.type == 'cnpj').hasErrors = true;
                        stakeholder.cnpj = '(erro ao obter cnpj)';
                    } else {
                        stakeholder.shouldIgnore = true;
                    }
                }
            }
        }

        if (!Utils.isNullOrEmpty(stakeholder.emailFormField)) {
            formData = formData || this.getFormData();

            if (formData != null) {
                let emailFormFieldValue = formData[stakeholder.emailFormField] as string;

                if (!Utils.isNullOrEmpty(emailFormFieldValue)) {
                    stakeholder.email = emailFormFieldValue;
                } else {
                    if (stakeholder.isMandatory) {
                        this.hasErrorsState.find(x => x.type == 'companyEmail').hasErrors = true;
                        stakeholder.email = '(erro ao obter e-mail)';
                    } else {
                        stakeholder.shouldIgnore = true;
                    }
                }
            }
        }

        return stakeholder;
    }

    private getFormData(): any {
        let formTaskDefinition = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
        if (formTaskDefinition == null) return null;

        let formTaskInstance = this.inputFlowInstance.flowObjectInstances.find(x => x.flowObjectDefinitionId == formTaskDefinition.id);
        let formData = JSON.parse(formTaskInstance.inputData) as InputDataTaskForm;

        return formData.data;
    }

    private hideLastName(name: string): string {
        let firstName = name.split(' ');
        let lastName = firstName.splice(1);
        lastName = lastName.map(x => x.replace(/[a-zA-Z]/g, '*'));

        return firstName.concat(lastName).join(' ');
    }

    private resolveProcessSummary() {
        let formData = this.getFormData();
        if (formData == null) return;

        let tags = this.configSchema.taskRegisterProcess.processSummary.match(/\{\|([\w]+)\|\}/g);
        if (tags?.length > 0) {
            let formFields = tags.map(x => x.replace(/[^\w]/g, ''));

            for (let i = 0; i < tags.length; i++) {
                this.configSchema.taskRegisterProcess.processSummary = this.configSchema.taskRegisterProcess.processSummary.replaceAll(
                    tags[i],
                    formData[formFields[i]] || ''
                ).replace(/ +/g, ' ').trim();
            }
        }
    }

    private initializeHasErrorsState() {
        this.hasErrorsState = [
            {
                type: 'citizenName',
                hasErrors: false
            },
            {
                type: 'companyName',
                hasErrors: false
            },
            {
                type: 'cnpj',
                hasErrors: false
            },
            {
                type: 'companyEmail',
                hasErrors: false
            }
        ];
    }
}
