<div class="page-container">
    <filter-menu ngDefaultControl #filterMenuRef
                 [(filterState)]="filterState"
                 (filterStateChange)="addFilter()"
                 (outputCloseEvent)="closeFilterMenu()"
    ></filter-menu>

    <div class="working-area p-4">
        <h1 class="page-title m-0">
            <i class="fas fa-folder-open mr-2"></i>
            <span>Meus Fluxos</span>
        </h1>

        <p class="page-subtitle m-0 pl-4 pt-3 pr-2 pb-2">
            <i class="fas fa-level-up-alt mr-2"></i>
            Acesse aqui os Fluxos iniciados por você ou dos quais participe de alguma forma.
        </p>

        <hr />

        <div class="section mt-5">
            <div class="custom-filter-config mb-3">
                <div class="header">
                    <div class="config-title mb-3">
                        <h6 class="title m-0">Exibindo {{flowInstances.length}} de {{currentTotalCount}}</h6>
                    </div>
                    <div class="config-inputs">
                        <div class="custom-filter-search mr-2">
                            <input type="text" class="form-control" maxlength="200"
                                    placeholder="Buscar pelo nome do Fluxo, sua data de criação ou usuário que o iniciou"
                                    matTooltip="Buscar pelo nome do Fluxo, sua data de criação ou usuário que o iniciou"
                                    (keyup.enter)="addCustomFilter()"
                                    [(ngModel)]="customFilterSearch"
                            >
                            <span class="search-button-container" (click)="addCustomFilter()">
                                <i class="fas fa-search" matTooltip="Buscar em meus Fluxos Iniciados"></i>
                            </span>
                        </div>

                        <div class="button-wrapper">
                            <button class="btn btn-outline-secondary show-filter-menu mr-1"
                                    matTooltip="Exibir menu com filtros predefinidos"
                                    [class.is-active]="!isPredefinedFilterStateDefault()"
                                    (click)="openFilterMenu()"
                            >
                                <i class="fas fa-filter"></i>
                            </button>
                            <button class="btn btn-outline-secondary show-sort-menu"
                                    matTooltip="Exibir menu com as configurações de ordenação"
                                    [class.is-active]="!isSortStateDefault()"
                                    (click)="openSortMenu()"
                            >
                                <i class="fas"
                                    [ngClass]="{
                                        'fa-sort-alpha-down': sortState.descendingName === false || sortState.descendingOwnerInfo === false,
                                        'fa-sort-alpha-down-alt': sortState.descendingName === true || sortState.descendingOwnerInfo === true,
                                        'fa-sort-numeric-down': sortState.descendingCreateDate === false,
                                        'fa-sort-numeric-down-alt': sortState.descendingCreateDate === true,
                                        'fa-sort': sortState.descendingName === null && sortState.descendingOwnerInfo === null && sortState.descendingCreateDate === null
                                    }"
                                ></i>
                            </button>
                        </div>

                        <div class="sorting-container" #sortingMenuRef>
                            <div class="title">
                                <i class="fas fa-sort mr-2"></i>
                                <span>Ordenação</span>
                                <i class="fas fa-times close-button" matTooltip="Fechar" (click)="closeSortMenu()"></i>
                            </div>
                            <hr />
                            <button class="btn btn-outline-secondary sort"
                                    matTooltip="Alternar direção de ordenação pelo nome do Fluxo"
                                    [class.is-active]="sortState.descendingName !== null"
                                    (click)="sortByName()"
                            >
                                <i class="fas mr-2"
                                   [ngClass]="{
                                       'fa-sort-alpha-down': sortState.descendingName === false,
                                       'fa-sort-alpha-down-alt': sortState.descendingName === true,
                                       'fa-sort': sortState.descendingName === null
                                   }"
                                ></i>
                                <span>Nome do Fluxo</span>
                            </button>
                            <button class="btn btn-outline-secondary sort"
                                    matTooltip="Alternar direção de ordenação pelo nome do usuário que iniciou o Fluxo"
                                    [class.is-active]="sortState.descendingOwnerInfo !== null"
                                    (click)="sortByOwnerInfo()"
                            >
                                <i class="fas mr-2"
                                   [ngClass]="{
                                       'fa-sort-alpha-down': sortState.descendingOwnerInfo === false,
                                       'fa-sort-alpha-down-alt': sortState.descendingOwnerInfo === true,
                                       'fa-sort': sortState.descendingOwnerInfo === null
                                   }"
                                ></i>
                                <span>Nome do Usuário</span>
                            </button>
                            <button class="btn btn-outline-secondary sort"
                                    matTooltip="Alternar direção de ordenação pela data de criação do Fluxo"
                                    [class.is-active]="sortState.descendingCreateDate !== null"
                                    (click)="sortByCreationDate()"
                            >
                                <i class="fas fa-sort mr-2"
                                   [ngClass]="{
                                       'fa-sort-numeric-down': sortState.descendingCreateDate === false,
                                       'fa-sort-numeric-down-alt': sortState.descendingCreateDate === true,
                                       'fa-sort': sortState.descendingCreateDate === null
                                   }"
                                ></i>
                                <span>Data de Criação</span>
                            </button>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="chip-container">
                    <div class="chip no-filters" *ngIf="searchTokens.length == 0; else searchTokensTemplate"
                         matTooltip="Clique para exibir menu com filtros predefinidos"
                         (click)="openFilterMenu()"
                    >
                        <span>Sem Filtros</span>
                    </div>
                    <ng-template #searchTokensTemplate>
                        <div class="chip" *ngFor="let searchToken of searchTokens"
                             matTooltip="Clique para exibir menu com filtros predefinidos"
                             [matTooltipDisabled]="searchToken.isCustomFilter"
                             [class.default-filter]="!searchToken.isCustomFilter"
                             (click)="!searchToken.isCustomFilter ? openFilterMenu() : return"
                        >
                            <span>{{searchToken.text}}</span>
                            <i class="icon fas fa-times-circle ml-1"
                               matTooltip="Remover filtro"
                               (click)="removeFilter(searchToken)"
                            ></i>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="cards-container">
                <ngx-spinner bdColor="#f5f5f5" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>
                <ng-container *ngIf="isBootstrapFinished">
                    <ng-container *ngIf="flowInstances.length == 0; else flowInstanceItemsTemplate">
                        <div class="card empty">
                            <i class="fas fa-box-open"></i>
                            <span class="mx-2">Nenhum item a exibir</span>
                            <i class="fas fa-box-open"></i>
                        </div>
                    </ng-container>
                    <ng-template #flowInstanceItemsTemplate>
                        <div class="cards">
                            <flow-instance-item *ngFor="let flow of flowInstances"
                                                [inputModel]="flow"
                                                (outputOpenEvent)="openFlowInstance($event)"
                            ></flow-instance-item>
                        </div>
                        <div class="load-more mt-4" *ngIf="flowInstances.length < currentTotalCount">
                            <button type="button" class="btn btn-outline-primary" (click)="loadMore()">
                                <i class="fas fa-download mr-2"></i>
                                <span>Carregar mais</span>
                            </button>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
</div>
