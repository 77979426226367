import {
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IBaseModel } from 'src/app/models/base.model';
import { AuthService } from 'src/app/services/auth.service';
import { Enums } from '../../shared/enums';
import { Utils } from '../../shared/utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<
        HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
    > {
        //const authService = this.injector.get(AuthService);
        const toastr = this.injector.get(ToastrService);
        const spinner = this.injector.get(NgxSpinnerService);

        return next.handle(request).pipe(
            catchError(error => {
                spinner.hide();
                spinner.hide('flowInstance');
                spinner.hide('flowObjectForm');
                spinner.hide('afterSubmit');

                // caso o erro detectado seja referente a login não realizado no/"sessão" inválida do Acesso Cidadão
                if (
                    error.status === 401
                    || (!error.ok && error.status === 0)
                ) {
                    toastr.warning(Enums.Messages.AcessoCidadaoExpiredWarningReload, Enums.Messages.Warning, Utils.getToastrErrorOptions());
                    return next.handle(request);
                }

                if (error.error instanceof ErrorEvent) {
                    // erro client-side ou de rede
                } else if (error.error && error.error.isSuccess != null && error.error.message != null) {
                    return throwError(error.error as IBaseModel<any>);
                }

                toastr.error(Enums.Messages.GeneralErrorInterceptor, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return throwError(error);
            })
        );
    }
}
