<div class="wrapper">
    <div class="filter-menu-container">
        <div class="title">
            <i class="fas fa-filter mr-2"></i>
            <span>Filtros</span>
            <i class="fas fa-times close-button" matTooltip="Fechar" (click)="close()"></i>
        </div>

        <hr />

        <div class="entry-row">
            <mat-checkbox class="pl-2" color="primary"
                          [(ngModel)]="filterState.isOngoingFilterOn"
                          (click)="toggleOngoingFilter($event)"
            >{{PredefinedFilterDescription.get(PredefinedFilter.Ongoing)}}</mat-checkbox>
        </div>
        <div class="entry-row">
            <mat-checkbox class="pl-2" color="primary"
                          [(ngModel)]="filterState.isFinishedFilterOn"
                          (click)="toggleFinishedFilter($event)"
            >{{PredefinedFilterDescription.get(PredefinedFilter.Finished)}}</mat-checkbox>
        </div>
        <div class="entry-row">
            <mat-checkbox class="pl-2" color="primary"
                          [(ngModel)]="filterState.isCancelledFilterOn"
                          (click)="toggleCancelledFilter($event)"
            >{{PredefinedFilterDescription.get(PredefinedFilter.Cancelled)}}</mat-checkbox>
        </div>

        <hr />

        <div class="entry-row">
            <mat-checkbox class="pl-2" color="primary"
                          [(ngModel)]="filterState.isStartedByMeFilterOn"
                          (click)="toggleStartedByMeFilter($event)"
            >{{PredefinedFilterDescription.get(PredefinedFilter.StartedByMe)}}</mat-checkbox>
        </div>
        <div class="entry-row">
            <mat-checkbox class="pl-2" color="primary"
                          [(ngModel)]="filterState.isNotStartedByMeFilterOn"
                          (click)="toggleNotStartedByMeFilter($event)"
            >{{PredefinedFilterDescription.get(PredefinedFilter.NotStartedByMe)}}</mat-checkbox>
        </div>

        <hr />

        <div class="entry-row">
            <mat-checkbox class="pl-2" color="primary"
                          [(ngModel)]="filterState.isPendingForMeFilterOn"
                          (click)="togglePendingForMeFilter($event)"
            >{{PredefinedFilterDescription.get(PredefinedFilter.PendingForMe)}}</mat-checkbox>
        </div>
        <div class="entry-row">
            <mat-checkbox class="pl-2" color="primary"
                          [(ngModel)]="filterState.isNotPendingForMeFilterOn"
                          (click)="toggleNotPendingForMeFilter($event)"
            >{{PredefinedFilterDescription.get(PredefinedFilter.NotPendingForMe)}}</mat-checkbox>
        </div>

        <hr />

        <div class="footer mt-3">
            <button type="button" class="btn btn-outline-primary"
                    *ngIf="!isFilterStateDefault()"
                    (click)="clearFilter()"
            >
                <i class="fas fa-times-circle mr-2"></i>
                <span>Limpar filtros</span>
            </button>
        </div>
    </div>
</div>
