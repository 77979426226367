<ng-container *ngIf="model != null">
    <div class="wrapper px-1">
        <!-- Task info: Cancelada -->
        <ng-container *ngIf="isTaskCancelled; else componentTemplate">
            <div class="message-container" *ngIf="isTaskAutomaticallyCancelled">
                <b>
                    Tarefa cancelada automaticamente pelo sistema em
                    <span class="d-inline-block text-highlight relevant-info-font">{{dateString}}</span>
                </b>
            </div>
            <div class="message-container" *ngIf="isTaskManuallyCancelled">
                <b>
                    Tarefa cancelada manualmente pelos administradores do Fluxo em
                    <span class="d-inline-block text-highlight relevant-info-font">{{dateString}}</span>
                </b>
            </div>

            <!-- Visão do executor -->
            <ng-container *ngIf="isTaskPublicAgentActor">
                <hr />
                <div class="mb-3">
                    Documento comprobatório do cancelamento desta Tarefa:
                </div>
                <div class="documents-container">
                    <div class="position-relative my-1" *ngFor="let doc of currentDocuments">
                        <span class="document-highlight" (click)="openDocumentModal(doc.id)">
                            <span class="e-docs relevant-info-font text-highlight" matTooltip="Protocolo E-Docs do documento">{{doc.registro}}</span>
                            <span class="relevant-info-font d-inline-block" matTooltip="Clique para visualizar o documento que foi capturado no E-Docs">
                                <i class="fas fa-external-link-alt mr-2"></i><u>{{doc.filename}}</u>
                            </span>
                        </span>
                    </div>
                </div>
            </ng-container>

            <!-- Visão do requerente -->
            <ng-container *ngIf="!isTaskPublicAgentActor">
                <hr />
                <div class="message-container">
                    <p *ngIf="isTaskAutomaticallyCancelled">
                        O Fluxo
                        <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.name}}</span>
                        foi configurado para que qualquer instância do mesmo seja cancelada automaticamente
                        após data e hora específicos ou em caso de inatividade prolongada.
                    </p>
                    <p *ngIf="isTaskManuallyCancelled">
                        O Fluxo
                        <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.name}}</span>
                        com o identificador
                        <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.id.toUpperCase()}}</span>
                        foi cancelado em função de um ou mais erros ocorridos na identificação dos Interessados do Processo a ser autuado no E-Docs.
                    </p>
                    <p>
                        Para todos os efeitos, o Fluxo <b>não foi completado</b> e as eventuais Tarefas previamente executadas <b>não possuem validade</b>.
                    </p>
                    <span>
                        Inicie uma <b>nova instância</b> deste Fluxo para completar a requisição do serviço.
                    </span>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #componentTemplate>
            <!-- Seletor de papel -->
            <ng-container *ngIf="isTaskPublicAgentActor && hasPendingTask && papeisSelector.length > 0">
                <papel-selector [(signerId)]="inputData.eDocsData.signerId"
                                [inputIsReadOnly]="isReadOnly"
                                [inputPapeis]="papeisSelector"
                                [inputFlowObjectType]="FlowObjectType.TaskDispatchProcess"
                ></papel-selector>
                <hr />
            </ng-container>

            <!-- Task body: visão do executor -->
            <ng-container *ngIf="isTaskPublicAgentActor">
                <div class="message-container">
                    <div>Processo:</div>
                    <div class="process-container my-3">
                        <div class="position-relative my-1">
                            <span class="document-highlight" (click)="goToProcess()">
                                <span class="e-docs relevant-info-font text-highlight" matTooltip="Protocolo E-Docs do Processo">{{processProtocol}}</span>
                                <span class="relevant-info-font d-inline-block" matTooltip="Resumo do Processo">
                                    <i class="fas fa-external-link-alt mr-1"></i><u class="summary">{{process?.resumo}}</u>
                                </span>
                            </span>
                        </div>
                    </div>
                    <hr />
                </div>

                <div class="message-container" *ngIf="hasPendingTask">
                    <div>Custódia atual:</div>
                    <div class="my-3">
                        <span class="relevant-info-font text-highlight d-inline-block">
                            {{custodyHolder.toUpperCase()}}
                        </span>
                    </div>
                    <hr />
                </div>

                <div class="message-container" *ngIf="hasPendingTask">
                    <div>Destinatário:</div>
                    <div class="my-3">
                        <span class="relevant-info-font text-highlight d-inline-block">
                            {{configSchema?.taskDispatchProcess?.dispatchRecipient.name?.toUpperCase() || inputData.recipientName?.toUpperCase()}}
                        </span>
                    </div>
                    <hr />
                </div>

                <div class="message-container">
                    <div>Mensagem do Despacho:</div>
                    <div class="my-3">
                        <span class="relevant-info-font text-highlight d-inline-block p-2"
                              [innerHtml]="sanitizer.bypassSecurityTrustHtml(dispatchSummary)"
                        ></span>
                    </div>
                    <hr />
                </div>
            </ng-container>

            <!-- Task info: Realizada -->
            <div class="message-container" *ngIf="!hasPendingTask; else pendingTaskTemplate">
                <div class="mb-3">
                    O Processo disponível no protocolo E-Docs
                    <span class="relevant-info-font text-highlight">{{process?.protocolo}}</span>
                    foi <b>despachado</b> para:
                </div>
                <div class="mb-3">
                    <span class="relevant-info-font text-highlight d-inline-block">
                        {{configSchema?.taskDispatchProcess?.dispatchRecipient.name?.toUpperCase() || inputData.recipientName?.toUpperCase()}}
                    </span>
                </div>
            </div>

            <!-- Task info: Iniciada -->
            <ng-template #pendingTaskTemplate>
                <!-- Task body: visão do requerente -->
                <div class="message-container" *ngIf="!isTaskPublicAgentActor; else pendingTaskPublicAgentActorTemplate">
                    <div class="mb-3">
                        Aguardando despacho do Processo por:
                    </div>
                    <div>
                        <span class="relevant-info-font text-highlight d-inline-block">{{dispatcher?.toUpperCase()}}</span>
                    </div>

                    <ng-container *ngIf="!Utils.isNullOrEmpty(flowObjectDefinition?.publicMessageHtml)">
                        <hr />
                        <div class="public-message py-1" [class.loading]="publicMessageHtml == DEFAULT_LOADING_TEXT" [innerHtml]="publicMessageHtml"></div>
                    </ng-container>
                </div>

                <!-- Task footer: visão do executor -->
                <ng-template #pendingTaskPublicAgentActorTemplate>
                    <div class="form-container">
                        <div class="footer">
                            <button type="button" class="btn btn-outline-primary" (click)="submit()">
                                Despachar Processo
                            </button>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
</ng-container>
