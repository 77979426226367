<div class="section mt-5" *ngIf="flowDefinitions != null && currentUnfilteredCount > 0">
    <!-- Filtro -->
    <div class="custom-filter-config mb-3">
        <!-- Header -->
        <div class="header">
            <!-- Título -->
            <div class="config-title mb-3">
                <div class="title-container">
                    <h4 class="title m-0" [class.nowrap]="!isSmallestBreakpoint" [matTooltip]="sectionFullPath || sectionName">
                        <span class="label mr-2">Público-alvo:</span>
                        <span class="relevant-info-font text-highlight" [class.d-inline-block]="isSmallestBreakpoint">
                            <span class="section-name mr-1">{{sectionName}}</span>
                            <span class="full-path" *ngIf="sectionFullPath != null">({{sectionFullPath}})</span>
                        </span>
                    </h4>
                    <i class="fas fa-question-circle"
                       matTooltip="{{(inputFlowTarget == FlowTarget.Citizen
                            ? 'Fluxos disponíveis para o cidadão'
                            : 'Fluxos disponíveis para usuários com algum papel lotado no {0}'
                       ).replace('{0}', titleInfoTooltip)}}"
                    ></i>
                </div>
                <h6 class="subtitle m-0" [class.mt-1]="isSmallestBreakpoint">Exibindo {{flowDefinitions.length}} de {{currentTotalCount}} Fluxos</h6>
            </div>

            <!-- Inputs de configuração -->
            <div class="config-inputs">
                <div class="custom-filter-search mr-2">
                    <input type="text" class="form-control" minlength="3" maxlength="200"
                           placeholder="Buscar pelo nome do Fluxo, seu órgão/setor ou seu ano/data de disponibilização"
                           matTooltip="Buscar pelo nome do Fluxo, seu órgão/setor ou seu ano/data de disponibilização"
                           (keyup.enter)="addCustomFilter()"
                           [(ngModel)]="customFilterSearch"
                    >
                    <span class="search-button-container" (click)="addCustomFilter()">
                        <i class="fas fa-search" matTooltip="Buscar nos Fluxos disponíveis"></i>
                    </span>
                </div>

                <div class="button-wrapper">
                    <button class="btn btn-outline-secondary show-sort-menu"
                            matTooltip="Exibir menu com as configurações de ordenação"
                            [class.is-active]="!isSortStateDefault()"
                            (click)="openSortMenu()"
                    >
                        <i class="fas"
                           [ngClass]="{
                                'fa-sort-alpha-down': sortState.descendingName === false,
                                'fa-sort-alpha-down-alt': sortState.descendingName === true,
                                'fa-sort-numeric-down': sortState.descendingUpdateDate === false,
                                'fa-sort-numeric-down-alt': sortState.descendingUpdateDate === true,
                                'fa-sort': sortState.descendingName === null && sortState.descendingUpdateDate === null
                           }"
                        ></i>
                    </button>
                </div>

                <div class="sorting-container" #sortingMenuRef>
                    <div class="title">
                        <i class="fas fa-sort mr-2"></i>
                        <span>Ordenação</span>
                        <i class="fas fa-times close-button" matTooltip="Fechar" (click)="closeSortMenu()"></i>
                    </div>
                    <hr />
                    <button class="btn btn-outline-secondary sort"
                            matTooltip="Alternar direção de ordenação pelo nome do Fluxo"
                            [class.is-active]="sortState.descendingName !== null"
                            (click)="sortByName()"
                    >
                        <i class="fas mr-2"
                           [ngClass]="{
                                'fa-sort-alpha-down': sortState.descendingName === false,
                                'fa-sort-alpha-down-alt': sortState.descendingName === true,
                                'fa-sort': sortState.descendingName === null
                           }"
                        ></i>
                        <span>Nome do Fluxo</span>
                    </button>
                    <button class="btn btn-outline-secondary sort"
                            matTooltip="Alternar direção de ordenação pela data de disponibilização do Fluxo"
                            [class.is-active]="sortState.descendingUpdateDate !== null"
                            (click)="sortByAvailabilityDate()"
                    >
                        <i class="fas fa-sort mr-2"
                           [ngClass]="{
                                'fa-sort-numeric-down': sortState.descendingUpdateDate === false,
                                'fa-sort-numeric-down-alt': sortState.descendingUpdateDate === true,
                                'fa-sort': sortState.descendingUpdateDate === null
                           }"
                        ></i>
                        <span>Data de Disponibilização</span>
                    </button>
                </div>
            </div>
        </div>

        <hr />

        <!-- Chip container -->
        <div class="chip-container">
            <div class="chip" *ngIf="searchTokens.length == 0; else searchTokensTemplate">
                <span>Sem Filtros</span>
            </div>
            <ng-template #searchTokensTemplate>
                <div class="chip" [class.default-filter]="!searchToken.isCustomFilter" *ngFor="let searchToken of searchTokens">
                    <span>{{searchToken.text}}</span>
                    <i class="icon fas fa-times-circle ml-1"
                       matTooltip="Remover filtro"
                       (click)="removeFilter(searchToken)"
                    ></i>
                </div>
            </ng-template>
        </div>
    </div>

    <!-- Cards container -->
    <div class="cards-container">
        <ngx-spinner name="FlowTarget_{{inputFlowTarget}}" bdColor="#f5f5f5" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>

        <ng-container *ngIf="flowDefinitions.length == 0">
            <div class="card empty">
                <i class="fas fa-box-open"></i>
                <span class="mx-2">Nenhum item a exibir</span>
                <i class="fas fa-box-open"></i>
            </div>
        </ng-container>

        <div class="cards">
            <flow-definition-item *ngFor="let flow of flowDefinitions"
                                  [inputModel]="flow"
                                  (outputOpenEvent)="openFlowDefinition($event)"
            ></flow-definition-item>
        </div>

        <div class="load-more mt-4" *ngIf="flowDefinitions.length > 0 && flowDefinitions.length < currentTotalCount">
            <button type="button" class="btn btn-outline-primary" (click)="loadMore()">
                <i class="fas fa-download mr-2"></i>
                <span>Carregar mais</span>
            </button>
        </div>
    </div>
</div>
