export class Encaminhamento {
    urlHistorico?: string;
    identificadorExterno?: string;
    id?: string;
    assunto?: string;
    mensagem?: string;
    dataHora?: Date;
    protocolo?: string;
    urlDocumentoEdocs?: string;
    origem?: Local;
    destinos?: Local[];
    documentosEncaminhamento?: DocumentoEncaminhamento[];
}

export class Local {
    tipo?: string;
    id?: string;
    nome?: string;
    sigla?: string;
    nomeOrgao?: string;
    siglaOrgao?: string;
    nomeUnidade?: string;
    siglaUnidade?: string;
}

export class DocumentoEncaminhamento {
    controle?: boolean;
    documento?: Documento;
}

export class Documento {
    id?: string;
    registro?: string;
    url?: string;
    nome?: string;
    extensao?: string;
    base64?: string;
    encaminhamentoId?: string;
}

export class DocumentoSearchRequest {
    ids?: string[];
    registros?: string[];
    somenteDiretorio?: boolean;
    somenteMeusDocumentos?: boolean;
    pesquisaLivre?: string;
    pagina?: number;
    tamanhoPagina?: number;
}

export class DocumentoSearchResponse {
    result?: DocumentoSearchResult[];
    count?: number;
}

export class DocumentoSearchResult {
    id?: string;
    registro?: string;
    nome?: string;
    extensao?: string;
    nivelAcesso?: number;
}

export class Papel {
    id?: string;
    nome?: string;
    tipo?: string;
    siglaOrganizacao?: string;
    siglaUnidade?: string;
    nomeOrganizacaoUnidade?: string;
    siglaOrganizacaoUnidade?: string;
    tipoLocalizacaoGrupo?: string;
    nomeUnidadeGrupo?: string;
    siglaUnidadeGrupo?: string;
    nomeOrganizacaoGrupo?: string;
    siglaOrganizacaoGrupo?: string;
    nomeServidorPapel?: string;
    tipoLocalizacaoPapel?: string;
    nomeUnidadePapel?: string;
    siglaUnidadePapel?: string;
    nomeOrganizacaoPapel?: string;
    siglaOrganizacaoPapel?: string;
}

export class GerarUrl {
    url?: string;
    identificadorTemporarioArquivoNaNuvem?: string;
    body?: any;
}

export class AssinaturaDigitalValida {
    isSuccess?: boolean;
    message?: string;
}

export class DocumentoValido {
    isSuccess?: boolean;
    message?: string;
}

export class Processo {
    id?: string;
    ano?: string;
    protocolo?: string;
    resumo?: string;
    situacao?: string;
}

export class ProcessoSistema {
    id?: string;
    nome?: string;
    tipoAgente?: number;
    descricaoTipoAgente?: string;
}

export class ProcessoLocalizacao {
    id?: string;
    nome?: string;
    sigla?: string;
    tipoAgente?: number;
    descricaoTipoAgente?: string;
    sistema?: ProcessoSistema;
}

export class ProcessoOrganizacao {
    id?: string;
    nome?: string;
    sigla?: string;
    tipoAgente?: number;
    descricaoTipoAgente?: string;
    patriarca?: ProcessoPatriarca;
    sistema?: ProcessoSistema;
}

export class ProcessoPapel {
    id?: string;
    nome?: string;
    cargo?: string;
    tipoAgente?: number;
    descricaoTipoAgente?: string;
    setor?: ProcessoSetor;
    sistema?: ProcessoSistema;
}

export class ProcessoPatriarca {
    id?: string;
    nome?: string;
    sigla?: string;
    tipoAgente?: number;
    descricaoTipoAgente?: string;
    sistema?: ProcessoSistema;
}

export class ProcessoSetor {
    id?: string;
    nome?: string;
    sigla?: string;
    tipoAgente?: number;
    descricaoTipoAgente?: string;
    organizacao?: ProcessoOrganizacao;
    sistema?: ProcessoSistema;
}

export class ProcessoAutuador {
    id?: string;
    nome?: string;
    papel?: ProcessoPapel;
    localizacao?: ProcessoLocalizacao;
}

export enum AccessLevelType {
    Public = 1,
    Organizational = 2,
    Confidential = 3,
    Classified = 4
}

export const AccessLevelTypeDescription = new Map<number, string>([
    [AccessLevelType.Public, 'Público'],
    [AccessLevelType.Organizational, 'Organizacional'],
    [AccessLevelType.Confidential, 'Sigiloso'],
    [AccessLevelType.Classified, 'Classificado']
]);

export enum DocumentLegalValueCombination {
    ServidorDocumentoEletronicoSemAssinatura = 0,
    ServidorDocumentoEletronicoAssinadoEletronicamente = 1,
    ServidorDocumentoEletronicoAssinadoDigitalmente = 2,
    ServidorDocumentoEscaneadoDocumentoOriginal = 3,
    ServidorDocumentoEscaneadoCopiaSimples = 4,
    ServidorDocumentoEscaneadoCopiaAutenticadaCartorio = 5,
    ServidorDocumentoEscaneadoCopiaAutenticadaAdministrativamente = 6,
    CidadaoDocumentoEletronicoSemAssinatura = 7,
    CidadaoDocumentoEletronicoAssinadoEletronicamente = 8,
    CidadaoDocumentoEletronicoAssinadoDigitalmente = 9,
    CidadaoDocumentoEscaneadoCopiaSimples = 10
}
