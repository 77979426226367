import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    FlowDefinition,
    FlowTarget,
    FlowTargetLabel
} from '../../models/flow.model';
import {
    CookieGeneralState,
    CookieParticularState,
    SearchToken,
    SortState
} from '../../models/helper-classes.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../shared/enums';
import { Utils } from '../../shared/utils';
import { SCSS_VARS } from '../../shared/shared-scss-ts-variables';
import { AuthService } from '../../services/auth.service';
import { FlowDefinitionService } from '../../services/flow-definition.service';
import { Input } from '@angular/core';
import { OrganogramaService } from '../../services/organograma.service';

@Component({
    selector: 'flow-target-section',
    templateUrl: './flow-target-section.component.html',
    styleUrls: ['./flow-target-section.component.scss']
})
export class FlowTargetSectionComponent implements OnInit {
    @ViewChild('sortingMenuRef') sortingMenuRef: ElementRef;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.sortingMenuRef?.nativeElement.classList.contains('show')) {
            if (!event.composedPath().includes(this.sortingMenuRef.nativeElement)) {
                this.closeSortMenu();
            }
        }
    }

    FlowTarget: typeof FlowTarget = FlowTarget;
    window: Window = window;

    flowDefinitions: FlowDefinition[] = null;
    searchTokens: SearchToken[] = [];
    customFilterSearch: string = null;
    sortState: SortState = new SortState({ descendingUpdateDate: true });
    currentPage: number = 1;
    currentPageSize: number = 1;
    currentTotalCount: number = 0;
    currentUnfilteredCount: number = 0;
    currentTotalPages: number = 1;
    sectionFullPath: string = null;
    sectionName: string = null;
    titleInfoTooltip: string = null;
    isSmallestBreakpoint: boolean = false;

    @Input() inputGeneralState: CookieGeneralState;
    @Input() inputFlowTarget: FlowTarget;
    @Input() inputLocationId: string;
    @Output() outputSetCookiesEvent = new EventEmitter<CookieParticularState>();

    constructor(
        private router: Router,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private authService: AuthService,
        private organogramaService: OrganogramaService,
        private flowDefinitionService: FlowDefinitionService
    ) {
        this.spinner.show(`FlowTarget_${this.inputFlowTarget}`);
        this.isSmallestBreakpoint = this.window.visualViewport.width <= SCSS_VARS['$breakpoint-max-1'];
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.processPageSize();
        this.getCookies();
        await this.getFilteredResults();

        switch (this.inputFlowTarget) {
            case FlowTarget.Unit:
                this.sectionName = this.authService.user.papeisUnitIds[this.inputLocationId];
                this.sectionFullPath = await this.getUnitSectionFullPath(this.inputLocationId);
                break;

            case FlowTarget.Organization:
                this.sectionName = this.authService.user.papeisOrganizationIds[this.inputLocationId];
                this.sectionFullPath = await this.getOrganizationSectionFullPath(this.inputLocationId);
                break;

            case FlowTarget.Patriarch:
                this.sectionName = this.authService.user.papeisPatriarchIds[this.inputLocationId];
                this.sectionFullPath = await this.getPatriarchSectionFullPath(this.inputLocationId);
                break;

            case FlowTarget.Citizen:
                this.sectionName = FlowTargetLabel.get(this.inputFlowTarget);
                break;
        }

        this.titleInfoTooltip = `${FlowTargetLabel.get(this.inputFlowTarget)} "${this.sectionName}"`;
    }

    // ======================
    // public methods
    // ======================

    addFilter() {
        setTimeout(async () => {
            await this.getFilteredResults();
        }, 1);
    }

    async addCustomFilter() {
        if (this.customFilterSearch == null) return;

        if (this.customFilterSearch.length < 3) {
            this.toastr.warning(Enums.Messages.SearchMinLength, Enums.Messages.Warning, Utils.getToastrErrorOptions(true));
            return;
        }

        this.searchTokens.push(new SearchToken({
            isCustomFilter: true,
            text: this.customFilterSearch
        }));
        this.searchTokens = [...new Set(this.searchTokens)];
        this.customFilterSearch = null;

        await this.getFilteredResults();
    }

    async removeFilter(searchToken: SearchToken) {
        this.searchTokens = this.searchTokens.filter(x => x != searchToken);
        await this.getFilteredResults();
    }

    async sortByAvailabilityDate() {
        this.sortState = new SortState({ descendingUpdateDate: !!!this.sortState.descendingUpdateDate });
        await this.getFilteredResults();
    }

    sortCurrentContentByAvailabilityDate() {
        if (this.flowDefinitions.length == 0) return;

        if (this.sortState.descendingUpdateDate) {
            this.flowDefinitions.sort((a, b) => b.updateDate > a.updateDate ? 1 : -1);
        } else {
            this.flowDefinitions.sort((a, b) => a.updateDate > b.updateDate ? 1 : -1);
        }
    }

    async sortByName() {
        this.sortState = new SortState({ descendingName: !!!this.sortState.descendingName });
        await this.getFilteredResults();
    }

    sortCurrentContentByName() {
        if (this.flowDefinitions.length == 0) return;

        if (this.sortState.descendingName) {
            this.flowDefinitions.sort((a, b) => b.name.localeCompare(a.name));
        } else {
            this.flowDefinitions.sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    openSortMenu() {
        setTimeout(() => this.sortingMenuRef.nativeElement.classList.add('show'), 1);
    }

    closeSortMenu() {
        setTimeout(() => this.sortingMenuRef.nativeElement.classList.remove('show'), 1);
    }

    async loadMore() {
        await this.getFilteredResults(this.currentPage + 1, true);
    }

    isSortStateDefault() {
        return JSON.stringify(this.sortState) == JSON.stringify(new SortState());
    }

    openFlowDefinition(flowDefinition: FlowDefinition) {
        this.router.navigate([Enums.PagesPaths.FlowDefinition, flowDefinition.id], { queryParams: { d: 1 } });
    }

    // ======================
    // private methods
    // ======================

    private getFilteredResults(
        page?: number,
        shouldMaintainCurrentSet?: boolean,
        pageSize?: number
    ) {
        this.spinner.show(`FlowTarget_${this.inputFlowTarget}`);

        setTimeout(async () => {
            this.setCookies();

            try {
                this.currentPage = page || 1;

                const response = await this.flowDefinitionService.filterPaginated({
                    flowTarget: this.inputFlowTarget,
                    locationId: this.inputLocationId,
                    page: page,
                    pageSize: pageSize || this.currentPageSize,
                    sortField: this.getCurrentSortField(),
                    isSortDescending: this.getCurrentIsSortDescending(),
                    searchTokens: this.searchTokens.map(x => x.key || x.text)
                });

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.flowDefinitions = shouldMaintainCurrentSet
                    ? this.flowDefinitions.concat(response.data)
                    : response.data;
                this.currentPageSize = response.pageSize;
                this.currentTotalCount = response.totalCount;
                this.currentUnfilteredCount = response.unfilteredCount;
                this.currentTotalPages = response.totalPages;

                if (!this.isSortStateDefault()) {
                    if (this.sortState.descendingUpdateDate !== null) {
                        this.sortCurrentContentByAvailabilityDate();
                    } else if (this.sortState.descendingName !== null) {
                        this.sortCurrentContentByName();
                    }
                }
            } finally {
                setTimeout(() => {
                    this.spinner.hide(`FlowTarget_${this.inputFlowTarget}`);
                }, 300);
            }
        }, 300);
    }

    private getCurrentSortField(): string {
        return this.sortState.descendingUpdateDate !== null
            ? Enums.SortField.UpdateDate
            : Enums.SortField.Name;
    }

    private getCurrentIsSortDescending(): boolean {
        return this.getCurrentSortField() === null
            ? null
            : this.sortState.descendingName !== null
                ? this.sortState.descendingName
                : this.sortState.descendingUpdateDate;
    }

    private processPageSize() {
        if (this.window.visualViewport.width <= SCSS_VARS['$breakpoint-max-1']) {
            this.currentPageSize = 5;
        } else if (this.window.visualViewport.width <= SCSS_VARS['$breakpoint-max-2']) {
            this.currentPageSize = 4;
        } else if (this.window.visualViewport.width <= SCSS_VARS['$breakpoint-max-last']) {
            this.currentPageSize = 6;
        } else {
            this.currentPageSize = 8;
        }
    }

    private getCookies() {
        switch (this.inputFlowTarget) {
            case FlowTarget.Unit:
                this.searchTokens = this.inputGeneralState.unit.searchTokens;
                this.sortState = this.inputGeneralState.unit.sortState;
                break;

            case FlowTarget.Organization:
                this.searchTokens = this.inputGeneralState.organization.searchTokens;
                this.sortState = this.inputGeneralState.organization.sortState;
                break;

            case FlowTarget.Patriarch:
                this.searchTokens = this.inputGeneralState.patriarch.searchTokens;
                this.sortState = this.inputGeneralState.patriarch.sortState;
                break;

            case FlowTarget.Citizen:
                this.searchTokens = this.inputGeneralState.citizen.searchTokens;
                this.sortState = this.inputGeneralState.citizen.sortState;
                break;
        }
    }

    private setCookies() {
        this.outputSetCookiesEvent.emit(new CookieParticularState({
            searchTokens: this.searchTokens,
            sortState: this.sortState
        }));
    }

    private async getUnitSectionFullPath(unitId: string): Promise<string> {
        const response_Unit = await this.organogramaService.getUnidade(unitId);

        if (!response_Unit.isSuccess) {
            this.toastr.error(response_Unit.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        let unit = response_Unit.data;

        const response_Organization = await this.organogramaService.getOrganizacao(unit.organizacao.guid);

        if (!response_Organization.isSuccess) {
            this.toastr.error(response_Organization.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        let organization = response_Organization.data;

        return `${organization.organizacaoPai.sigla} - ${organization.sigla} - ${unit.nomeCurto}`;
    }

    private async getOrganizationSectionFullPath(organizationId): Promise<string> {
        const response = await this.organogramaService.getOrganizacao(organizationId);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        let organization = response.data;

        return `${organization.organizacaoPai.sigla} - ${organization.sigla}`;
    }

    private async getPatriarchSectionFullPath(patriarchId): Promise<string> {
        const response = await this.organogramaService.getOrganizacao(patriarchId);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        let patriarch = response.data;

        return `${patriarch.sigla}`;
    }
}
