import { Injector } from '@angular/core';
import { FormioCustomComponentInfo, registerCustomFormioComponent } from '@formio/angular';
import { DateTimePickerComponent } from './datetime-picker.component';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
    type: 'dateTimePicker',
    selector: 'datetime-picker',
    title: 'Data e Hora',
    group: 'advanced',
    weight: 52,
    icon: 'fa fa-calendar'
};

export function registerDateTimePickerComponent(injector: Injector) {
    registerCustomFormioComponent(COMPONENT_OPTIONS, DateTimePickerComponent, injector);
}
