export namespace Enums {
    export enum Messages {
        GeneralError = 'Erro ao executar a operação (FE01)',
        GeneralErrorInterceptor = 'Erro ao executar a operação (FE07)',
        AcessoCidadaoExpiredWarningReload = 'Sessão inválida do Acesso Cidadão. Recarregue a página ou acesse o E-Flow em outra aba para renovar a sessão automaticamente ou realizar o login, caso seja necessário',
        AccessDenied = 'Acesso Negado',
        NoAccessToResource = 'Você não possui acesso a esse recurso',
        MyNewFlow = 'Meu Novo Fluxo',
        Error = 'Erro',
        Pendency = 'Pendência',
        Warning = 'Alerta',
        Success = 'Sucesso',
        Form = 'Formulário',
        SendButtonText = 'Pré-visualizar o PDF resultante do preenchimento do formulário',
        LandlinePhoneText = 'Fixo',
        PageTitleEFlow = 'E-Flow',
        PageTitleConectaEs = 'Conecta ES',
        EnvironmentLocalhost = 'Localhost',
        EnvironmentDevelopment = 'Desenvolvimento',
        EnvironmentStaging = 'Homologação',
        EnvironmentLearning = 'Treinamento',
        NoFlowStarterError = 'Não foi encontrada Tarefa Inicial para o Fluxo especificado',
        FormSubmitSuccess = 'Formulário submetido.',
        FormSubmitError = 'Erro ao submeter formulário.',
        MessageFieldMandatory = 'O campo "Mensagem" é obrigatório',
        PdfFilesOnly = 'Apenas arquivos com extensão PDF são permitidos',
        PdfMaxSizeLimit = 'O tamanho do arquivo PDF deve ser menor que {0}MB',
        MinioPostError = 'Erro HTTP {0}: {1}',
        InvalidIcpBrasil = 'Assinatura ICP-Brasil inválida para o documento anexado no campo "{0}"',
        InvalidEDocsDocument = 'O documento anexado no campo "{0}" não pode ser capturado no E-Docs. Motivo: "{1}"',
        NoSignerIdError = 'Selecione um papel antes de prosseguir com o envio do formulário',
        MjolnirProceedError = 'Não é possível prosseguir com a execução deste Fluxo',
        SearchMinLength = 'Insira um mínimo de 3 caracteres',
        Citizen = 'CIDADÃO',
        FinalPreviewDialogTitle = "Confira a pré-visualização do documento que será capturado a partir do envio do formulário. Note que apenas APÓS o envio será gerada a versão final do documento sem a marca d'água.",
        ConfirmEnqueueTask = 'Deseja enfileirar a Tarefa novamente para execução?',
        ConfirmFinishTask = 'Deseja encerrar com sucesso tanto a Tarefa quanto o Fluxo?',
        ConfirmCancelTask = 'Deseja cancelar a Tarefa e, consequentemente, esta instância de Fluxo?',
        ConfirmExecuteTask = 'Deseja prosseguir com a execução desta Tarefa?',
        ConfirmSendForm = 'Deseja prosseguir com o envio dos dados do formulário?',
        MessageMinlengthError = 'Forneça uma mensagem com no mínimo 5 caracteres',
        RequiredFilesPendency = 'Os seguintes campos de anexos obrigatórios não foram preenchidos: {0}',
        EDocsProtocolDocumentsPendency = 'Você não possui permissão de uso dos documentos E-Docs referentes aos seguintes protocolos: "{0}"',
        EDocsProtocolNotFound = 'O seguinte protocolo de Documento E-Docs não foi encontrado: "{0}"',
        UnfinishedFileUploads = 'Aguardando o término do upload de todos os arquivos',
        FormDraftRecovered = 'Rascunho do formulário recuperado com sucesso',
        FormDraftCleared = 'Rascunho do formulário descartado com sucesso',
        MjolnirSuccess = 'Operação executada com sucesso'
    }

    export enum Profiles {
        Administrator = 'Administrador',
        Owner = 'Owner',
        Public = 'Publico',
        ErrorPage = 'ErrorPage'
    }

    export enum GatewayPathsLabels {
        True = 'Sim',
        False = 'Não'
    }

    export enum PagesPaths {
        FlowDefinitionList = '/flow-definition-list',
        FlowInstanceList = '/flow-instance-list',
        FlowDefinition = '/flow-definition',
        FlowInstance = '/flow-instance',
        CatalogService = '/service',
        Home = '/',
        Tests = '/tests',
        Mjolnir = '/mjolnir',
        Error = '/error'
    }

    export enum SortField {
        CreateDate = 'CreateDate',
        UpdateDate = 'UpdateDate',
        Name = 'Name',
        OwnerInfo = 'OwnerInfo'
    }

    export enum PredefinedFilter {
        Finished = 10,
        Ongoing = 11,
        Cancelled = 12,
        StartedByMe = 20,
        NotStartedByMe = 21,
        PendingForMe = 30,
        NotPendingForMe = 31
    }

    export const PredefinedFilterDescription = new Map<number, string>([
        [PredefinedFilter.Finished, 'Encerrados'],
        [PredefinedFilter.Ongoing, 'Em andamento'],
        [PredefinedFilter.Cancelled, 'Cancelados'],
        [PredefinedFilter.StartedByMe, 'Iniciados por mim'],
        [PredefinedFilter.NotStartedByMe, 'Iniciados por outros'],
        [PredefinedFilter.PendingForMe, 'Com pendências para mim'],
        [PredefinedFilter.NotPendingForMe, 'Sem pendências para mim']
    ]);

    export const PredefinedFilterKey = new Map<number, string>([
        [PredefinedFilter.Finished, '{|_Finished_|}'],
        [PredefinedFilter.Ongoing, '{|_Ongoing_|}'],
        [PredefinedFilter.Cancelled, '{|_Cancelled_|}'],
        [PredefinedFilter.StartedByMe, '{|_StartedByMe_|}'],
        [PredefinedFilter.NotStartedByMe, '{|_NotStartedByMe_|}'],
        [PredefinedFilter.PendingForMe, '{|_PendingForMe_|}'],
        [PredefinedFilter.NotPendingForMe, '{|_NotPendingForMe|}']
    ]);

    export enum LocationType {
        Unit = 0,
        Organization = 1,
        Patriarch = 2
    }
}
