<footer>
    <div class="wrapper px-3">
        <div class="keep-left">
            <a href="//www.es.gov.br/" target="_blank" matTooltip="Acessar o portal do Governo do Estado do Espírito Santo">
                <img class="gov-logo" src="//cdn.es.gov.br/images/logo/governo/brasao/left-white/Brasao_Governo_240.png" alt="Governo do Estado Espírito Santo">
            </a>
        </div>
        <div class="keep-center mx-3">
            <a matTooltip="Acessar a página inicial do E-Flow" *ngIf="!isFromConecta; else conectaTemplate"
               [routerLink]="[Enums.PagesPaths.Home]"
            >
                <img class="eflow-logo mb-3" alt="E-Flow" src="/assets/img/logo_eflow.svg" />
            </a>
            <ng-template #conectaTemplate>
                <img class="eflow-logo mb-3" alt="E-Flow" src="/assets/img/logo_eflow.svg" />
            </ng-template>
            <div class="m-1">Secretaria de Gestão e Recursos Humanos (SEGER)</div>
            <div class="m-1">Governo do Estado do Espírito Santo</div>
        </div>
        <div class="keep-right">
            <a href="//prodest.es.gov.br/" target="_blank" matTooltip="Acessar o site do PRODEST">
                <img class="prodest-logo" src="//cdn.es.gov.br/images/logo/prodest/logomarca/left-white/Logomarca_Prodest_240.png" alt="Prodest">
            </a>
        </div>
    </div>
</footer>
