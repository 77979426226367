<div class="wrapper px-1" *ngIf="model != null">
    <ng-container *ngIf="isFinished(); else pollingTemplate">
        <!-- Task info: Finished -->
        <div class="message-container" *ngIf="model.stateId == FlowObjectInstanceState.Finished">
            <div class="message-row">
                <i class="fas fa-check-circle success mr-3"></i>
                <span class="headline">
                    Comunicação com o sistema
                    <span class="text-highlight relevant-info-font">{{publicSystemName}}</span>
                    realizada com sucesso
                </span>
                <i class="fas fa-check-circle success ml-3"></i>
            </div>

            <ng-container *ngIf="message != ''">
                <div class="message-row mt-4" *ngIf="isMessageFromApi">
                    <p>Mensagem retornada pelo sistema:</p>
                </div>

                <div class="message-row" [class.mt-4]="!isMessageFromApi">
                    <span class="document-highlight relevant-info-font">{{message}}</span>
                </div>
            </ng-container>

            <hr />

            <div class="message-row">
                <span>Fluxo <b>encerrado</b></span>
            </div>

            <ng-container *ngIf="!Utils.isNullOrEmpty(flowObjectDefinition?.publicMessageHtml)">
                <hr />
                <div class="public-message py-1" [class.loading]="publicMessageHtml == DEFAULT_LOADING_TEXT" [innerHtml]="publicMessageHtml"></div>
            </ng-container>
        </div>

        <!-- Task info: ManuallyFinished -->
        <div class="message-container" *ngIf="model.stateId == FlowObjectInstanceState.ManuallyFinished">
            <div class="message-row">
                <i class="fas fa-check-circle success mr-3"></i>
                <span class="headline">
                    Tarefa encerrada manualmente com sucesso pelos administradores do Fluxo
                </span>
                <i class="fas fa-check-circle success ml-3"></i>
            </div>

            <hr />

            <div class="message-row">
                <span>Fluxo <b>encerrado</b></span>
            </div>

            <ng-container *ngIf="!Utils.isNullOrEmpty(flowObjectDefinition?.publicMessageHtml)">
                <hr />

                <div class="public-message py-1" [class.loading]="publicMessageHtml == DEFAULT_LOADING_TEXT" [innerHtml]="publicMessageHtml"></div>
            </ng-container>
        </div>

        <!-- Task info: ManuallyCancelled -->
        <div class="message-container" *ngIf="model.stateId == FlowObjectInstanceState.ManuallyCancelled">
            <div class="message-row">
                <i class="fas fa-minus-circle error mr-3"></i>
                <span class="headline">
                    Tarefa cancelada manualmente pelos administradores do Fluxo em
                    <span class="d-inline-block text-highlight relevant-info-font">{{getDateString()}}</span>
                </span>
                <i class="fas fa-minus-circle error ml-3"></i>
            </div>

            <hr />

            <div class="message-row column">
                <p>
                    Houve um erro na comunicação com o sistema
                    <span class="d-inline-block text-highlight relevant-info-font">{{publicSystemName}}</span>
                    e os administradores do Fluxo
                    <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.name}}</span>
                    optaram por cancelar manualmente esta instância de identificador
                    <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.id.toUpperCase()}}</span>.
                </p>
                <p>
                    Para todos os efeitos, o Fluxo <b>não foi completado</b> e as eventuais Tarefas previamente executadas <b>não possuem validade</b>.
                </p>
                <span>
                    Inicie uma <b>nova instância</b> deste Fluxo para completar a requisição do serviço.
                </span>
            </div>
        </div>

        <!-- Task info: AutomaticallyCancelled -->
        <div class="message-container" *ngIf="model.stateId == FlowObjectInstanceState.AutomaticallyCancelled">
            <div class="message-row">
                <b>
                    Tarefa cancelada automaticamente pelo sistema em
                    <span class="d-inline-block text-highlight relevant-info-font">{{getDateString()}}</span>
                </b>
            </div>

            <hr />

            <div class="message-row column">
                <p>
                    O Fluxo
                    <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.name}}</span>
                    foi configurado para que qualquer instância do mesmo seja cancelada automaticamente
                    após data e hora específicos ou em caso de inatividade prolongada.
                </p>
                <p>
                    Para todos os efeitos, o Fluxo <b>não foi completado</b> e as eventuais Tarefas previamente executadas <b>não possuem validade</b>.
                </p>
                <span>
                    Inicie uma <b>nova instância</b> deste Fluxo para completar a requisição do serviço.
                </span>
            </div>
        </div>
    </ng-container>

    <!-- Task info: Iniciada -->
    <ng-template #pollingTemplate>
        <!-- Task info: polling em curso -->
        <div class="message-container" *ngIf="!isPollingFinished; else pendingActionTemplate">
            <div class="message-row mt-2">
                <i class="fas fa-sync fa-spin await mr-3"></i>
                <span class="headline">
                    Aguardando comunicação com o sistema
                    <span class="d-inline-block text-highlight relevant-info-font">{{publicSystemName}}</span>
                </span>
                <i class="fas fa-sync fa-spin await ml-3"></i>
            </div>
        </div>

        <!-- Task info: ação pendente -->
        <ng-template #pendingActionTemplate>
            <div class="message-container">
                <div class="message-row mt-2">
                    <i class="fas fa-minus-circle error mr-3"></i>
                    <span class="headline">
                        Erro na comunicação com o sistema
                        <span class="d-inline-block text-highlight relevant-info-font">{{publicSystemName}}</span>
                    </span>
                    <i class="fas fa-minus-circle error ml-3"></i>
                </div>

                <ng-container *ngIf="message != ''">
                    <div class="message-row" *ngIf="isMessageFromApi">
                        <p>Mensagem retornada pelo sistema:</p>
                    </div>

                    <div class="message-row" [class.mt-4]="!isMessageFromApi">
                        <span class="document-highlight relevant-info-font">{{message}}</span>
                    </div>
                </ng-container>

                <hr />

                <!-- Task footer -->
                <div class="message-row column mt-3">
                    <p>
                        A comunicação automatizada com o sistema
                        <span class="text-highlight relevant-info-font">{{publicSystemName}}</span>
                        não pôde ser realizada.
                    </p>

                    <!-- Task footer: visão do requerente -->
                    <div *ngIf="!isResolver; else resolverTemplate">
                        Em breve a equipe responsável atuará manualmente nesta Tarefa para que o Fluxo possa ser devidamente encerrado.
                    </div>

                    <!-- Task footer: visão do executor -->
                    <ng-template #resolverTemplate>
                        <p>Como membro do grupo resolvedor configurado para esta Tarefa, você está habilitado para executar uma das seguintes ações:</p>

                        <div class="message-row column button-row-container py-1">
                            <div class="button-row primary">
                                <div class="keep top">
                                    <button type="button" class="btn btn-outline-primary"
                                            (click)="enqueueTask()"
                                    >Reexecutar</button>
                                </div>
                                <div class="keep bottom">
                                    <span>
                                        Enfileira a Tarefa novamente para execução. O Fluxo permanecerá no estado
                                        <b>Em andamento</b> aguardando o desfecho da Tarefa.
                                    </span>
                                </div>
                            </div>

                            <div class="button-row success">
                                <div class="keep top">
                                    <button type="button" class="btn btn-outline-success"
                                            (click)="updateTask(FlowObjectInstanceState.ManuallyFinished)"
                                    >Encerrar</button>
                                </div>
                                <div class="keep bottom">
                                    <span>
                                        Encerra com sucesso tanto a Tarefa quanto o Fluxo. Não haverá
                                        novas tentativas de comunicação com a API do sistema
                                        <span class="d-inline-block text-highlight relevant-info-font">{{publicSystemName}}</span>.
                                    </span>
                                </div>
                            </div>

                            <div class="button-row danger">
                                <div class="keep top">
                                    <button type="button" class="btn btn-outline-danger"
                                            (click)="updateTask(FlowObjectInstanceState.ManuallyCancelled)"
                                    >Cancelar</button>
                                </div>
                                <div class="keep bottom">
                                    <span>
                                        Cancela tanto a Tarefa quanto o Fluxo, que por sua vez terá a
                                        validade <b>desconsiderada</b> para fins de auditoria — ainda que eventuais
                                        documentos tenham sido gerados e processados no E-Docs.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>

