<ng-container *ngIf="isBootstrapFinished">
    <div class="wrapper px-1">
        <!-- Task info: Cancelada -->
        <ng-container *ngIf="isTaskCancelled; else componentTemplate">
            <div class="message-container">
                <b>
                    Tarefa cancelada automaticamente pelo sistema em
                    <span class="d-inline-block text-highlight relevant-info-font">{{dateString}}</span>
                </b>
            </div>
            <hr />
            <div class="message-container">
                <p>
                    O Fluxo
                    <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.name}}</span>
                    foi configurado para que qualquer instância do mesmo seja cancelada automaticamente
                    após data e hora específicos ou em caso de inatividade prolongada.
                </p>
                <p>
                    Para todos os efeitos, o Fluxo <b>não foi completado</b> e as eventuais Tarefas previamente executadas <b>não possuem validade</b>.
                </p>
                <span>
                    Inicie uma <b>nova instância</b> deste Fluxo para completar a requisição do serviço.
                </span>
            </div>
        </ng-container>

        <ng-template #componentTemplate>
            <!-- Seletor de papel -->
            <ng-container *ngIf="shouldShowPapelSelector">
                <papel-selector [(signerId)]="inputData.eDocsData.signerId"
                                [inputIsReadOnly]="isReadOnly"
                                [inputPapeis]="papeisSelector"
                                [inputFlowObjectType]="FlowObjectType.StartInboundApi"
                ></papel-selector>
                <hr />
            </ng-container>

            <!-- Task body: visão do executor -->
            <div class="message-container" *ngIf="pdfFiles.length > 0 && !isTaskPublicAgentActor">
                <div>Documentos para captura no E-Docs:</div>
                <div class="documents-container mt-3">
                    <div class="position-relative my-1" *ngFor="let file of pdfFiles">
                        <span class="document-highlight">
                            <span class="relevant-info-font d-inline-block">
                                <span class="filename">{{file.filename}}</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <!-- Task info: Realizada -->
            <div class="message-container" *ngIf="!hasPendingTask; else pendingTaskTemplate">
                <div class="mb-3">
                    Documentos capturados no E-Docs
                </div>
            </div>

            <!-- Task info: Iniciada -->
            <ng-template #pendingTaskTemplate>
                <!-- Task body: visão do agente público -->
                <div class="message-container" *ngIf="isTaskPublicAgentActor; else pendingTaskOwnerTemplate">
                    <div class="mb-3">
                        Aguardando captura de Documentos no E-Docs pelo usuário instanciador
                    </div>
                </div>

                <!-- Task footer: visão do executor -->
                <ng-template #pendingTaskOwnerTemplate>
                    <div class="form-container">
                        <ng-container *ngIf="!Utils.isNullOrEmpty(inputFlowObjectDefinition?.publicMessageHtml)">
                            <hr />
                            <div class="public-message py-1" [class.loading]="publicMessageHtml == DEFAULT_LOADING_TEXT" [innerHtml]="publicMessageHtml"></div>
                        </ng-container>

                        <div class="mt-4">
                            <formio [form]="formSchema"
                                    [renderOptions]="{ language: 'pt' }"
                                    [options]="formioOptions"
                                    [readOnly]="isReadOnly"
                                    [submission]="inputData"
                                    (submit)="onSubmit($event)"
                            ></formio>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
</ng-container>
