import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    public apiBaseUrl = `${window.location.origin}/api`;

    constructor(public httpClient: HttpClient) { }
}
