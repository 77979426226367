<ng-container *ngIf="model != null">
    <div class="wrapper px-1" [class.min-height-on]="isLocalLoading">
        <!-- Loading (fullscreen genérico) -->
        <ngx-spinner name="genericFullscreen" bdColor="#00000099" size="medium" color="#8bc5d9" type="line-scale-pulse-out-rapid" [fullScreen]="true">
            <h5>Processando...</h5>
        </ngx-spinner>

        <!-- Loading (local) -->
        <ngx-spinner name="local" bdColor="#fbfbfb" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false">
            <h6>Processando...</h6>
        </ngx-spinner>

        <!-- Seletor de papéis -->
        <ng-container *ngIf="shouldShowPapelSelector">
            <papel-selector [(signerId)]="inputData.eDocsData.signerId"
                            [inputIsReadOnly]="isReadOnly"
                            [inputPapeis]="papeisSelector"
                            [inputFlowObjectType]="FlowObjectType.StartForm"
            ></papel-selector>
            <hr />
        </ng-container>

        <!-- Formio -->
        <div *ngIf="isBootstrapFinished">
            <formio [form]="formSchema" *ngIf="formSchema != null; else emptyTemplate"
                    [renderOptions]="{ language: 'pt' }"
                    [options]="formioOptions"
                    [readOnly]="isReadOnly"
                    [submission]="inputData"
                    (change)="saveDraft($event)"
                    (submit)="onSubmit($event)"
                    (formLoad)="onFormLoad($event)"
            ></formio>
            <ng-template #emptyTemplate>
                <div class="button-container mt-4">
                    <button type="button" class="btn btn-outline-primary" (click)="loadFormData()">Carregar Formulário Respondido</button>
                </div>
            </ng-template>
        </div>

        <!-- Descartar rascunho -->
        <button type="button" class="btn btn-outline-secondary clear-draft" *ngIf="hasDraft"
                matTooltip="Descarta o rascunho salvo previamente para este formulário mantendo-se o conteúdo preenchido no momento"
                (click)="clearDraft()"
        >
            Descartar rascunho salvo deste formulário
        </button>
    </div>
</ng-container>
