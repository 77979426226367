<div *ngIf="model != null" class="wrapper px-2 py-4">
    <div class="container-flex d-flex justify-content-center">
        <div class="card definition-area">
            <!-- Fluxo desativado -->
            <div *ngIf="!model.isActive; else publishedTemplate" class="message h4">
                <b>Este Fluxo encontra-se desativado!</b>
            </div>

            <!-- Fluxo não publicado -->
            <ng-template #publishedTemplate>
                <div *ngIf="model.version == 1 && !model.isPublished; else unitTemplate" class="message h4">
                    <b>Este Fluxo não está publicado!</b>
                </div>
            </ng-template>

            <!-- Restrição de público-alvo: Unidade -->
            <ng-template #unitTemplate>
                <div class="message h4"
                     *ngIf="model.targetId == FlowTarget.Unit && !papeisUnitIdsArray.includes(model.publishedUnitId); else organizationTemplate"
                >
                    <b>O público-alvo deste Fluxo está restrito aos servidores com algum papel lotado no seguinte Setor:</b>
                    <br />
                    <span class="h6 relevant-info-font text-highlight">{{unit.nome}} ({{unit.nomeCurto}})</span>
                </div>
            </ng-template>

            <!-- Restrição de público-alvo: Organização -->
            <ng-template #organizationTemplate>
                <div class="message h4"
                     *ngIf="model.targetId == FlowTarget.Organization && !papeisOrganizationIdsArray.includes(model.publishedOrganizationId); else patriarchTemplate"
                >
                    <b>O público-alvo deste Fluxo está restrito aos servidores com algum papel lotado no seguinte Órgão:</b>
                    <br />
                    <span class="h6 relevant-info-font text-highlight">{{organization.razaoSocial}} ({{organization.sigla}})</span>
                </div>
            </ng-template>

            <!-- Restrição de público-alvo: Patriarca -->
            <ng-template #patriarchTemplate>
                <div class="message h4"
                     *ngIf="model.targetId == FlowTarget.Patriarch && !papeisPatriarchIdsArray.includes(model.publishedPatriarchId); else taskTemplate"
                >
                    <b>O público-alvo deste Fluxo está restrito aos servidores com algum papel lotado no seguinte Patriarca:</b>
                    <br />
                    <span class="h6 relevant-info-font text-highlight">{{patriarch.razaoSocial}} ({{patriarch.sigla}})</span>
                </div>
            </ng-template>

            <ng-template #taskTemplate>
                <!-- Header -->
                <div class="title">
                    <!-- Breadcrumb -->
                    <ng-container *ngIf="!isFromConecta">
                        <a [routerLink]="[Enums.PagesPaths.FlowDefinitionList]"><span>Iniciar um Fluxo</span></a>
                        <ng-container *ngIf="isContextInUse && model.contextId != null && !this.isFromFlowDefinitionList">
                            <i class="fas fa-chevron-right mx-2"></i>
                            <a (click)="loadContextSelection()"><span>{{flowDefinitionContext?.name || 'Grupo de Fluxos'}}</span></a>
                        </ng-container>

                        <i class="fas fa-chevron-right mx-2"
                           [class.invisible]="shouldShowContextSelector"
                        ></i>
                    </ng-container>

                    <!-- Versão -->
                    <span class="version-tag float-right px-1 py-0"
                          matTooltip="Versão a ser instanciada"
                          [class.invisible]="shouldShowContextSelector"
                    >Versão {{instanceVersion}}</span>

                    <!-- Caixa de Fluxos -->
                    <span class="flow-inbox relevant-info-font text-highlight float-right mr-2"
                          matTooltip="Setor responsável pelo Fluxo: {{patriarchAcronym}} - {{organizationAcronym}} - {{unitAcronym}}"
                          *ngIf="patriarchAcronym != null && organizationAcronym != null && unitAcronym != null"
                    >{{patriarchAcronym}} - {{organizationAcronym}} - {{unitAcronym}}</span>

                    <!-- Nome -->
                    <div class="flow-definition-name">
                        <span class="h5 text-highlight m-0"
                              [class.invisible]="shouldShowContextSelector"
                        >{{canShowHeaderInfo ? model.publishedName : '???'}}</span>
                    </div>
                </div>

                <hr>

                <!-- Seletor de Fluxos de um Grupo -->
                <div class="card task-area m-1" *ngIf="shouldShowContextSelector; else definitionAreaTemplate">
                    <ngx-spinner bdColor="#fbfbfb" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>

                    <ng-container *ngIf="isBootstrapFinished">
                        <!-- Header -->
                        <ng-container *ngIf="publishedFlowDefinitions.length > 0">
                            <div class="task-header px-1">
                                <h6>Escolha, a seguir, qual dos Fluxos publicados deste Grupo você deseja iniciar:</h6>
                            </div>
                            <hr>
                        </ng-container>

                        <div class="task-container context">
                            <!-- Sem Fluxos publicados no Grupo -->
                            <div *ngIf="publishedFlowDefinitions.length == 0; else flowDefinitionListTemplate" class="message h5">
                                <b>Não há Fluxos publicados neste Grupo!</b>
                            </div>

                            <!-- Lista de Fluxos publicados no Grupo -->
                            <ng-template #flowDefinitionListTemplate>
                                <div class="link-container font-weight-bold p-1" *ngFor="let flowDefinition of publishedFlowDefinitions">
                                    <i class="fas fa-external-link-alt"></i>

                                    <!-- Nome -->
                                    <span class="flow-definition-name text-highlight"
                                          matTooltip='Clique para iniciar o Fluxo "{{flowDefinition.name}}"'
                                          (click)="loadFlowDefinition(flowDefinition.id)"
                                    >{{flowDefinition.name}}</span>

                                    <!-- Versão -->
                                    <span class="version-tag px-1 py-0" matTooltip="Versão a ser instanciada">
                                        Versão {{flowDefinition.isPublished ? flowDefinition.version : flowDefinition.version - 1}}
                                    </span>
                                </div>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>

                <ng-template #definitionAreaTemplate>
                    <!-- Descrição e Informações -->
                    <div class="card description-area mx-1 mt-2 mb-4" #descriptionAreaRef
                         *ngIf="model.publishedDescription != null && model.publishedDescription?.trim() != ''"
                         [class.show]="isShowingDescription"
                    >
                        <div class="description-tag relevant-info-font" (click)="toggleDescription()">
                            <i class="toggle-description fas fa-chevron-circle-right mr-1" [class.on]="isShowingDescription"></i>
                            <span>Descrição e Informações</span>
                            <i class="toggle-description fas fa-chevron-circle-left ml-1" [class.on]="isShowingDescription"></i>
                        </div>
                        <div class="description-message-container" [class.show]="isShowingDescription">
                            <div class="description-message" #descriptionMessageRef
                                 [hidden]="!isShowingDescription"
                                 [innerHtml]="sanitizer.bypassSecurityTrustHtml(getDescriptionHtml())"
                            ></div>
                        </div>
                    </div>

                    <!-- Canvas -->
                    <div class="card task-area m-1" *ngIf="flowObjectToExecute != null">
                        <!-- Header -->
                        <div class="task-header px-1">
                            <!-- Ícone da Tarefa -->
                            <div class="task-icon-container mr-2"
                                 matTooltip="Tarefa de {{FlowObjectTypeCategory.get(+flowObjectToExecute.typeId)}}"
                            >
                                <i class="icon fas fa-file-alt"
                                   *ngIf="[FlowObjectType.StartForm].includes(flowObjectToExecute.typeId)"
                                ></i>
                                <i class="icon fas fa-paper-plane"
                                   *ngIf="[FlowObjectType.TaskForward].includes(flowObjectToExecute.typeId)"
                                ></i>
                                <i class="icon fas fa-check"
                                   *ngIf="[FlowObjectType.TaskAcknowledge].includes(flowObjectToExecute.typeId)"
                                ></i>
                                <i class="icon fas fa-thumbs-up"
                                   *ngIf="[FlowObjectType.GatewayApprove].includes(flowObjectToExecute.typeId)"
                                ></i>
                                <i class="icon fas fa-times-circle"
                                   *ngIf="[FlowObjectType.Finish].includes(flowObjectToExecute.typeId)"
                                ></i>
                            </div>

                            <!-- Nome da Tarefa -->
                            <h6>
                                <span>{{flowObjectToExecute.publicName || flowObjectToExecute.name}}</span>
                            </h6>
                        </div>

                        <hr />

                        <!-- Body -->
                        <div class="task-container">
                            <!-- Loading (antes do "submit") -->
                            <ngx-spinner bdColor="#fbfbfb" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false">
                                <h6>Processando...</h6>
                            </ngx-spinner>

                            <!-- Loading (após o "submit") -->
                            <ngx-spinner name="afterSubmit" bdColor="#fbfbfb" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false">
                                <h6>Processando...</h6>
                            </ngx-spinner>

                            <!-- Switch do tipo de Tarefa a ser desenhada -->
                            <div class="wrapper" *ngIf="isBootstrapFinished" #wrapperRef>
                                <flow-object-form *ngIf="flowObjectToExecute.isFlowStarter && flowObjectToExecute.typeId == FlowObjectType.StartForm"
                                                  [inputFlowObjectDefinition]="flowObjectToExecute"
                                                  [inputFlowDefinition]="model"
                                                  (outputSubmitEvent)="createFlowInstance($event)"
                                ></flow-object-form>
                            </div>
                        </div>
                    </div>

                    <!-- Botão para retorno ao Conecta ES -->
                    <a class="return-to-conecta" *ngIf="isFromConecta && isBootstrapFinished"
                       href="//conecta.{{conectaEnvironmentSegment}}es.gov.br/servico/{{model.catalogServiceId}}"
                    >
                        <div class="btn btn-outline-primary mt-4">
                            <i class="fas fa-arrow-left mr-2"></i>
                            <span>Voltar para o serviço</span>
                        </div>
                    </a>
                </ng-template>
            </ng-template>
        </div>
    </div>
</div>
