import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { Enums } from '../../../../shared/enums';

@Component({
    selector: 'main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
    @ViewChild('dropdownMenuRef') dropdownMenuRef: ElementRef;
    @ViewChild('headerButtonRef') headerButtonRef: ElementRef;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.dropdownMenuRef.nativeElement.classList.contains('show')) {
            if (!event.composedPath().includes(this.dropdownMenuRef.nativeElement)) {
                this.toggleMenu();
            }
        } else {
            if (event.composedPath().includes(this.headerButtonRef.nativeElement)) {
                this.toggleMenu();
            }
        }
    }

    Enums = Enums;

    constructor(
        private elementRef: ElementRef,
        public authService: AuthService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    toggleMenu() {
        this.dropdownMenuRef.nativeElement.classList.toggle('show');
    }

    // ======================
    // private methods
    // ======================
}
