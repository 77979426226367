import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
    @ViewChild('dropdownMenuRef') dropdownMenuRef: ElementRef;
    @ViewChild('headerButtonRef') headerButtonRef: ElementRef;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.dropdownMenuRef.nativeElement.classList.contains('show')) {
            if (!event.composedPath().includes(this.dropdownMenuRef.nativeElement)) {
                this.toggleMenu();
            }
        } else {
            if (event.composedPath().includes(this.headerButtonRef.nativeElement)) {
                this.toggleMenu();
            }
        }
    }

    userName: string = '';
    userEmail: string = '';

    constructor(
        private elementRef: ElementRef,
        public authService: AuthService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        let interval = setInterval(() => {
            this.userName = this.authService.user?.apelido || this.authService.user?.nome || '';
            this.userEmail = this.authService.user?.email;
            if (this.userName != '') {
                clearInterval(interval);
            }
        }, 300);
    }

    // ======================
    // public methods
    // ======================

    toProfile() {
        open('https://acessocidadao.es.gov.br/Perfil/Editar');
    }

    logout() {
        this.authService.logout();
    }

    toggleMenu() {
        this.dropdownMenuRef.nativeElement.classList.toggle('show');
    }

    // ======================
    // private methods
    // ======================
}
