<h4 mat-dialog-title>{{data.title || 'Visualizar Documento'}}</h4>

<div mat-dialog-content>
    <iframe [src]="safeUrl" frameborder="0" scrolling="no">Este navegador não suporta iframes</iframe>
</div>

<div class="actions mt-3" align="end" mat-dialog-actions>
    <button class="btn btn-primary" (click)="close()" *ngIf="data.title != null; else defaultTemplate">
        Concluir o envio do formulário
    </button>

    <ng-template #defaultTemplate>
        <button class="btn btn-outline-secondary" (click)="close()">
            Fechar
        </button>
    </ng-template>
</div>
