import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-base',
    template: ''
})
export class BaseComponent implements OnInit {
    public paramId: string = null;
    public paramVersion: number = null;
    public isCreate: boolean = true;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public spinner: NgxSpinnerService,
        public toastr: ToastrService
    ) {
        const id = this.route.snapshot.paramMap.get('id');
        if (id != null) {
            this.paramId = id;
            this.isCreate = false;
        }

        const version = parseInt(this.route.snapshot.paramMap.get('version'));
        if (!!version) {
            this.paramVersion = version;
        }

        this.spinner.show();
    }

    ngOnInit(): void { }
}
