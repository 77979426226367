<ng-container *ngIf="inputPapeis.length > 0 || papelOptions.length > 0">
    <div class="form-group w-100 m-0 pb-2" [class.formio-error-wrapper]="!papelElem.valid && !inputIsReadOnly">
        <label for="papelAssinanteId" class="font-weight-bold mr-2">
            Papel utilizado para {{inputFlowObjectType == FlowObjectType.TaskRegisterProcess
                ? 'autuar o processo'
                : inputFlowObjectType == FlowObjectType.TaskDispatchProcess
                    ? 'despachar o processo'
                    : 'capturar o(s) documento(s) da Tarefa'
            }} no E-Docs
        </label>
        <select class="form-control relevant-info-font light" id="papelAssinanteId" name="papelAssinanteId" required #papelElem="ngModel" #papelRef
                [(ngModel)]="signerId"
                [disabled]="inputIsReadOnly"
                (change)="onChange()"
        >
            <option *ngFor="let option of papelOptions" [value]="option.value">{{option.description}}</option>
        </select>
    </div>
</ng-container>
