import {
    Component,
    OnInit,
    Input
} from '@angular/core';
import {
    FlowObjectInstance,
    FlowObjectInstanceState,
    FlowObjectInstanceStateDescription
} from '../../../models/flow-object.model';
import { EDocsService } from '../../../services/edocs.service';
import { Enums } from '../../../shared/enums';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { PdfPreviewDialogComponent } from '../../pdf-preview-dialog/pdf-preview-dialog.component';
import { Utils } from '../../../shared/utils';
import { FlowInstance } from '../../../models/flow.model';
import { ConfigSchema } from '../../../models/config-schema.model';
import { InputDataTaskForward, OutputDataTaskForward } from '../../../models/input-output-data.model';
import { DocumentoEncaminhamento } from '../../../models/edocs.model';

@Component({
    selector: 'flow-object-forward',
    templateUrl: './flow-object-forward.component.html',
    styleUrls: ['./flow-object-forward.component.scss']
})
export class FlowObjectForwardComponent implements OnInit {
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    FlowObjectInstanceStateDescription: typeof FlowObjectInstanceStateDescription = FlowObjectInstanceStateDescription;

    model: FlowObjectInstance;
    configSchema: ConfigSchema;
    inputData: InputDataTaskForward;
    outputData: OutputDataTaskForward;
    protocolo: string = '';
    previousDocuments: any[] = [];

    // #region [getters]
    get isPublicAgent(): boolean {
        return this.authService?.user?.isPublicAgent;
    }
    get isTaskCancelled(): boolean {
        return [
            FlowObjectInstanceState.AutomaticallyCancelled,
            FlowObjectInstanceState.ManuallyCancelled
        ].includes(this.model?.stateId);
    }
    get dateString(): string {
        return this.model == null ? '' : new Date(this.model.updateDate).toLocaleString();
    }
    // #endregion

    @Input() inputModel: FlowObjectInstance;
    @Input() inputFlowInstance: FlowInstance;

    constructor(
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private authService: AuthService,
        private eDocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.model = this.inputModel;
        this.configSchema = JSON.parse(this.model.flowObjectDefinition.configSchema) as ConfigSchema;
        this.inputData = JSON.parse(this.model.inputData) as InputDataTaskForward;
        this.outputData = JSON.parse(this.model.outputData) as OutputDataTaskForward;

        const response_Encaminhamento = await this.eDocsService.getEncaminhamento(this.outputData.eDocsData.forwardingId);

        if (!response_Encaminhamento.isSuccess) {
            this.toastr.error(response_Encaminhamento.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        const encaminhamento = response_Encaminhamento.data;
        this.protocolo = encaminhamento.protocolo;

        // #region [documentos capturados anteriormente à Tarefa corrente]
        for (let item of encaminhamento.documentosEncaminhamento.filter(x => !x.controle)) {
            if (!Utils.isNullOrEmpty(this.outputData) && this.outputData.eDocsData.forwardingId == item.documento.encaminhamentoId) {
                break;
            }

            if (!this.previousDocuments.some(x => x.registro == item.documento.registro)) {
                this.previousDocuments.push({
                    id: item.documento.id,
                    registro: item.documento.registro,
                    filename: `${item.documento.nome}.${item.documento.extensao}`
                });
            }
        }
        // #endregion

        // #region [documentos capturados na Tarefa corrente]
        if (!Utils.isNullOrEmpty(this.outputData)) {
            let filteredDocs = encaminhamento.documentosEncaminhamento.filter(x =>
                x.documento.encaminhamentoId == this.outputData.eDocsData.forwardingId
                && !x.controle
            );

            for (let item of filteredDocs) {
                if (!this.previousDocuments.some(x => x.registro == item.documento.registro)) {
                    this.previousDocuments.push({
                        id: item.documento.id,
                        registro: item.documento.registro,
                        filename: `${item.documento.nome}.${item.documento.extensao}`
                    });
                }
            }
        }
        // #endregion
    }

    // ======================
    // public methods
    // ======================

    async openDocumentModal(id: string) {
        this.spinner.show('flowInstance');

        const response = await this.eDocsService.getDocumentoDownloadUrl(id);

        this.spinner.hide('flowInstance');

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        setTimeout(() => {
            this.dialog.open(PdfPreviewDialogComponent, {
                data: {
                    content: response.data
                }
            });
        }, 200);
    }

    // ======================
    // private methods
    // ======================
}
