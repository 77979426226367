import { Injector } from '@angular/core';
import { FormioCustomComponentInfo, registerCustomFormioComponent } from '@formio/angular';
import { DatePickerComponent } from './date-picker.component';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
    type: 'datePicker',
    selector: 'date-picker',
    title: 'Data',
    group: 'advanced',
    weight: 51,
    icon: 'fa fa-calendar'
};

export function registerDatePickerComponent(injector: Injector) {
    registerCustomFormioComponent(COMPONENT_OPTIONS, DatePickerComponent, injector);
}
