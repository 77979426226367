import { GroupingColumn } from "./grouping-column.model";
import { Guid } from "guid-typescript";

// #region [FlowObjectDefinition]
export class FlowObjectDefinition {
    id?: string = Guid.create().toString();
    name?: string;
    publicName?: string;
    configSchema?: string = null;
    publicMessageHtml?: string = null;
    formSchema?: string = null;
    documentHtmlContent?: string = null;
    version?: number = 1;
    innerIndex?: number = 0;
    outerIndex?: number = 0;
    isFlowStarter?: boolean = false;
    isFirstGatewayPath?: boolean = false;
    isSecondGatewayPath?: boolean = false;
    isLatestVersion?: boolean = true;
    flowDefinitionId?: string;
    groupingColumnId?: string;
    typeId?: FlowObjectType;
    groupingColumn?: GroupingColumn;
    next?: FlowObjectDefinition[] = [];
    flowObjectDefinitionNextFlowObjectDefinitions?: FlowObjectDefinitionNextFlowObjectDefinition[] = [];

    public constructor(init?: Partial<FlowObjectDefinition>) {
        if (init != null && init.name == null) {
            this.name = FlowObjectTypeDescription.get(init.typeId);
        }

        Object.assign(this, init);
    }
}

export class FlowObjectDefinitionNextFlowObjectDefinition {
    id?: number;
    flowObjectDefinitionId?: string;
    nextFlowObjectDefinitionId?: string;
}

export enum FlowObjectType {
    StartForm = 10,
    StartInboundApi = 11,
    TaskForward = 20,
    TaskAcknowledge = 21,
    TaskMidwayOutboundApi = 22,
    TaskRegisterProcess = 23,
    TaskDispatchProcess = 24,
    GatewayApprove = 30,
    GatewayRules = 31,
    GatewayPaths = 32,
    Finish = 40,
    FinishOutboundApi = 41
}

export const FlowObjectTypeClassName = new Map<number, string>([
    [FlowObjectType.StartForm, 'start-form'],
    [FlowObjectType.StartInboundApi, 'start-inbound-api'],
    [FlowObjectType.TaskForward, 'task-forward'],
    [FlowObjectType.TaskAcknowledge, 'task-acknowledge'],
    [FlowObjectType.TaskMidwayOutboundApi, 'task-midway-outbound-api'],
    [FlowObjectType.TaskRegisterProcess, 'task-register-process'],
    [FlowObjectType.TaskDispatchProcess, 'task-dispatch-process'],
    [FlowObjectType.GatewayApprove, 'gateway-approve'],
    [FlowObjectType.GatewayRules, 'gateway-rules'],
    [FlowObjectType.GatewayPaths, 'gateway-paths'],
    [FlowObjectType.FinishOutboundApi, 'finish-outbound-api'],
    [FlowObjectType.Finish, 'finish']
]);

export const FlowObjectTypeDescription = new Map<number, string>([
    [FlowObjectType.StartForm, 'Preencher Formulário'],
    [FlowObjectType.StartInboundApi, 'Iniciar por API'],
    [FlowObjectType.TaskForward, 'Encaminhar'],
    [FlowObjectType.TaskAcknowledge, 'Dar Ciência'],
    [FlowObjectType.TaskMidwayOutboundApi, 'Comunicar com API'],
    [FlowObjectType.TaskRegisterProcess, 'Autuar Processo'],
    [FlowObjectType.TaskDispatchProcess, 'Despachar Processo'],
    [FlowObjectType.GatewayApprove, 'Aprovar'],
    [FlowObjectType.GatewayRules, 'Aplicar Regras'],
    [FlowObjectType.GatewayPaths, 'Dividir Fluxo'],
    [FlowObjectType.FinishOutboundApi, 'Enviar para API'],
    [FlowObjectType.Finish, 'Encerrar Fluxo']
]);

export const FlowObjectTypeCategory = new Map<number, string>([
    [FlowObjectType.StartForm, 'Preenchimento de Formulário (Início de Fluxo)'],
    [FlowObjectType.StartInboundApi, 'Início por API (Início de Fluxo)'],
    [FlowObjectType.TaskForward, 'Encaminhamento de Conteúdo'],
    [FlowObjectType.TaskAcknowledge, 'Ciência de Conteúdo'],
    [FlowObjectType.TaskAcknowledge, 'Comunicação de Conteúdo'],
    [FlowObjectType.TaskMidwayOutboundApi, 'Comunicação com API'],
    [FlowObjectType.TaskRegisterProcess, 'Autuação de Processo'],
    [FlowObjectType.TaskDispatchProcess, 'Despacho de Processo'],
    [FlowObjectType.GatewayApprove, 'Aprovação de Conteúdo'],
    [FlowObjectType.GatewayRules, 'Aplicação de Regras'],
    [FlowObjectType.GatewayPaths, 'Divisão de Fluxo'],
    [FlowObjectType.FinishOutboundApi, 'Envio para API'],
    [FlowObjectType.Finish, 'Encerramento de Fluxo']
]);
// #endregion

// #region [FlowObjectInstance]
export class FlowObjectInstance {
    id?: string;
    inputData?: string;
    outputData?: string;
    hasUserInteraction?: boolean;
    flowObjectDefinitionId?: string;
    flowInstanceId?: string;
    stateId?: FlowObjectInstanceState;
    finisherInfo?: string;
    createDate?: Date;
    updateDate?: Date;
    startDate?: Date;
    finishDate?: Date;
    flowObjectDefinition?: FlowObjectDefinition;
    flowObjectInstanceActors?: FlowObjectInstanceActor[] = [];

    public constructor(init?: Partial<FlowObjectInstance>) {
        Object.assign(this, init);
    }
}

export class FlowObjectInstanceActor {
    id?: number;
    flowInstanceId?: string;
    flowObjectInstanceId?: string;
    actorId?: string;

    public constructor(init?: Partial<FlowObjectInstance>) {
        Object.assign(this, init);
    }
}

export enum FlowObjectInstanceState {
    NotStarted = 10,
    Started = 20,
    AwaitingAction = 21,
    Finished = 30,
    ManuallyFinished = 31,
    AutomaticallyCancelled = 32,
    ManuallyCancelled = 33
}

export const FlowObjectInstanceStateDescription = new Map<number, string>([
    [FlowObjectInstanceState.NotStarted, 'Não Iniciada'],
    [FlowObjectInstanceState.Started, 'Iniciada'],
    [FlowObjectInstanceState.AwaitingAction, 'Aguardando Ação'],
    [FlowObjectInstanceState.Finished, 'Realizada'],
    [FlowObjectInstanceState.ManuallyFinished, 'Encerrada Manualmente'],
    [FlowObjectInstanceState.AutomaticallyCancelled, 'Cancelada Automaticamente'],
    [FlowObjectInstanceState.ManuallyCancelled, 'Cancelada Manualmente']
]);
// #endregion
