import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowObjectDefinition, FlowObjectInstance, FlowObjectInstanceState, FlowObjectType } from '../../models/flow-object.model';
import { BaseComponent } from '../../components/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../shared/enums';
import { FlowInstanceService } from '../../services/flow-instance.service';
import { Utils } from '../../shared/utils';
import { FlowInstance, FlowInstanceState } from '../../models/flow.model';
import { InputDataTaskAcknowledge, InputDataTaskApprove, InputDataTaskFinish, InputDataTaskForward, OutputDataTaskAcknowledge, OutputDataTaskApprove } from '../../models/input-output-data.model';
import { IBaseOption } from '../../models/base.model';
import { AuthService } from '../../services/auth.service';
import { ConfigSchema } from '../../models/config-schema.model';
import { FlowObjectInstanceService } from '../../services/flow-object-instance.service';

@Component({
    selector: 'mjolnir',
    templateUrl: './mjolnir.component.html',
    styleUrls: ['./mjolnir.component.scss']
})
export class MjolnirComponent extends BaseComponent implements OnInit {
    model: FlowInstance;
    foiToExecute: FlowObjectInstance;
    fodToExecute: FlowObjectDefinition;
    isBootstrapFinished: boolean = false;
    createDate: string;
    updateDate: string;
    flowInbox: string;
    papelId: string;
    papelOptions: IBaseOption[] = [];

    constructor(
        route: ActivatedRoute,
        router: Router,
        spinner: NgxSpinnerService,
        toastr: ToastrService,
        private flowInstanceService: FlowInstanceService,
        private flowObjectInstanceService: FlowObjectInstanceService,
        private authService: AuthService
    ) {
        super(route, router, spinner, toastr);

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.papelOptions = Array.from(this.authService.user.papeis, item => {
            return {
                value: item.guid,
                description: `${item.nome} - ${item.lotacao.nomeCurto} - ${item.lotacao.organizacao.sigla}`
            };
        });
        this.papelId = this.authService.user.papeis[0].guid;

        const response = await this.flowInstanceService.getById(this.paramId);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            this.router.navigate([Enums.PagesPaths.FlowInstanceList]);
            return;
        }

        this.model = response.data;

        if (!(
            this.model.stateId < FlowInstanceState.Finished
            && !this.model.flowObjectInstances.some(x => x.stateId == FlowObjectInstanceState.Started)
        )) {
            this.router.navigate([Enums.PagesPaths.FlowInstanceList]);
        }

        this.createDate = new Date(this.model.createDate).toLocaleString();
        this.updateDate = new Date(this.model.updateDate).toLocaleString();
        this.flowInbox = this.model.organizationInfo.split(' - ')[0] + ' - ' + this.model.unitInfo.split(' - ')[0];

        setTimeout(() => {
            this.isBootstrapFinished = true;
            setTimeout(() => {
                this.spinner.hide();
            }, 300);
        }, 300);
    }

    // ======================
    // public methods
    // ======================

    async execute() {
        let foiLastExecuted = this.model.flowObjectInstances
            .filter(x => x.finishDate != null)
            .sort((a, b) => (new Date(b.finishDate).getTime() - new Date(a.finishDate).getTime()))[0];

        if (foiLastExecuted.flowObjectDefinition.flowObjectDefinitionNextFlowObjectDefinitions.length == 0) {
            this.toastr.error(Enums.Messages.MjolnirProceedError, Enums.Messages.Error, Utils.getToastrErrorOptions());
        }

        let next: FlowObjectDefinition;
        let lastInputData: any;
        let lastOutputData: any;
        let nextBuffer = [];

        switch (foiLastExecuted.flowObjectDefinition.typeId) {
            case FlowObjectType.GatewayApprove:
                lastInputData = JSON.parse(foiLastExecuted.inputData) as InputDataTaskApprove;
                lastOutputData = JSON.parse(foiLastExecuted.outputData) as OutputDataTaskApprove;

                for (let item of foiLastExecuted.flowObjectDefinition.flowObjectDefinitionNextFlowObjectDefinitions) {
                    nextBuffer.push(this.model.flowDefinition.flowObjectDefinitions.find(x => x.id == item.nextFlowObjectDefinitionId));
                }

                nextBuffer = nextBuffer.sort((a, b) => a.outerIndex - b.outerIndex);

                if (lastInputData.hasApproved) {
                    next = nextBuffer[0];
                } else {
                    next = nextBuffer[1];
                }

                break;

            case FlowObjectType.TaskAcknowledge:
                lastInputData = JSON.parse(foiLastExecuted.inputData) as InputDataTaskAcknowledge;
                lastOutputData = JSON.parse(foiLastExecuted.outputData) as OutputDataTaskAcknowledge;

                next = this.model.flowDefinition.flowObjectDefinitions.find(x =>
                    x.id == foiLastExecuted.flowObjectDefinition.flowObjectDefinitionNextFlowObjectDefinitions[0].nextFlowObjectDefinitionId
                );

                break;
        }

        let foiToExecute = this.model.flowObjectInstances.find(x => x.flowObjectDefinitionId == next.id);
        let nextInputData: any;

        switch (foiToExecute.flowObjectDefinition.typeId) {
            case FlowObjectType.TaskForward:
                nextInputData = new InputDataTaskForward();
                nextInputData.eDocsData = lastInputData.eDocsData;
                Object.assign(nextInputData.eDocsData, lastOutputData.eDocsData);
                nextInputData.eDocsData.signerId = this.papelId;

                let nextConfigSchema = JSON.parse(foiToExecute.flowObjectDefinition.configSchema) as ConfigSchema;
                if (nextConfigSchema.taskForward.toImmediateSupervisor) return null;

                nextInputData.recipientId = nextConfigSchema.taskForward.recipient.id;
                nextInputData.recipientName = nextConfigSchema.taskForward.recipient.name;

                break;

            case FlowObjectType.Finish:
                nextInputData = new InputDataTaskFinish();
                nextInputData.eDocsData = lastInputData.eDocsData;
                Object.assign(nextInputData.eDocsData, lastOutputData.eDocsData);
                nextInputData.eDocsData.signerId = this.papelId;

                break;
        }

        if (nextInputData == null) {
            this.toastr.error(Enums.Messages.MjolnirProceedError, Enums.Messages.Error, Utils.getToastrErrorOptions());
        }

        const response = await this.flowObjectInstanceService.bringMjolnir(foiToExecute.id, nextInputData);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.toastr.success(Enums.Messages.MjolnirSuccess, Enums.Messages.Success, Utils.getToastrErrorOptions());
    }

    // ======================
    // private methods
    // ======================
}
