import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormioCustomComponent } from '@formio/angular';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { EDocsService } from '../../../../../services/edocs.service';
import { Enums } from '../../../../../shared/enums';
import { Utils } from '../../../../../shared/utils';
import { PdfPreviewDialogComponent } from '../../../../pdf-preview-dialog/pdf-preview-dialog.component';

@Component({
    selector: 'pdf-upload',
    templateUrl: './pdf-upload.component.html',
    styleUrls: ['./pdf-upload.component.scss']
})
export class PdfUploadComponent implements FormioCustomComponent<any>, OnInit {
    // #region [ViewChild]
    @ViewChild('fileRef') fileRef: ElementRef;
    // #endregion

    // #region [constants]
    private MAX_SIZE_MB: number = 100 as const;
    // #endregion

    // #region [properties]
    fileName: string = null;
    rerenderer: number[] = [0];
    ms: number = 0;
    shouldPreventUpload: boolean = false;
    activeButton: ('main' | 'prevent' | 'cancelled' | 'progress' | 'done') = 'main';
    // #endregion

    // #region [getters]
    get progressState(): ('start' | 'half' | 'end') {
        if (this.ms <= 333) {
            return 'start';
        } else if (this.ms <= 666) {
            return 'half';
        }

        return 'end';
    }
    // #endregion

    // #region [Input/Output]
    @Input() disabled: boolean;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<any>();
    // #endregion

    constructor(
        private dialog: MatDialog,
        private toastr: ToastrService,
        private eDocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        // contorno de bug do Formio: aguarda por até 2s para que o "value" esteja disponível.
        // Lifecycle do Angular não funciona como deveria pois o componente existe no
        // contexto do Formio e recebe os valores nos parâmetros de @Input assincronamente
        let count = -1;
        let interval = setInterval(() => {
            count++;
            if (this.value?.fileName != null || count == 10) {
                this.fileName = this.value?.fileName;
                clearInterval(interval);
            }
        }, 200);
    }

    // ======================
    // public methods
    // ======================

    changeFile(event) {
        const file = event.target.files[0] as File;

        // caso a extensão do arquivo não seja PDF
        if (file.name.split('.').slice(-1)[0].toLowerCase() != 'pdf') {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.PdfFilesOnly, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        // caso o tamanho do arquivo seja maior que o máximo estabelecido
        if (file.size > this.MAX_SIZE_MB * 1024 * 1024) {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.PdfMaxSizeLimit.replace('{0}', this.MAX_SIZE_MB.toString()), Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        // reserva um spot na fila de upload de arquivos
        let fileQueueSpot = Guid.create().toString();
        window['_$_fileQueue'] = window['_$_fileQueue'] || [];
        window['_$_fileQueue'].push(fileQueueSpot);

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onloadend = () => {
            // exibe o botão para cancelar o upload
            this.activeButton = 'prevent';

            setTimeout(async () => {
                // clicou-se para cancelar o upload
                if (this.shouldPreventUpload) {
                    this.removeFile();
                    this.shouldPreventUpload = false;
                    this.activeButton = 'main';
                    return;
                }

                // início da fase de loading
                this.activeButton = 'progress';
                let interval = setInterval(() => {
                    this.ms = new Date().getMilliseconds();
                }, 100);

                // #region [upload do arquivo no Minio]
                const response_Get = await this.eDocsService.getGerarUrl(file.size);

                if (!response_Get.isSuccess) {
                    this.toastr.error(response_Get.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                let gerarUrl = response_Get.data;

                // monta um FormData para facilitar o POST do arquivo
                let formData = new FormData();
                for (let key in gerarUrl.body) {
                    formData.append(key, gerarUrl.body[key])
                }

                // binário tem que vir depois dos parâmetros no payload se não o Minio do E-Docs explode
                formData.append('file', file);

                // realiza o POST via fetch para facilitar o POST de conteúdo "multipart/form-data"
                const response_Post = await fetch(gerarUrl.url, {
                    method: 'post',
                    body: formData
                });

                if (!response_Post.ok) {
                    this.toastr.error(
                        Enums.Messages.MinioPostError.replace('{0}', response_Post.status.toString()).replace('{1}', response_Post.statusText),
                        Enums.Messages.Error,
                        Utils.getToastrErrorOptions()
                    );
                    return;
                }
                // #endregion

                // libera o spot na fila de upload de arquivos
                window['_$_fileQueue'] = (window['_$_fileQueue'] || []).filter(x => x != fileQueueSpot);

                // fim da fase de loading
                clearInterval(interval);
                this.activeButton = 'done';

                setTimeout(() => {
                    // retorno ao modo default
                    this.activeButton = 'main';

                    // registro e publicação das alterações
                    this.fileName = file.name;
                    this.value = {
                        isCustomFileComponent: true,
                        fileName: [this.fileName],
                        minioKey: [gerarUrl.identificadorTemporarioArquivoNaNuvem]
                    };

                    this.valueChange.emit(this.value);
                }, 2000);
            }, 3000);
        };
    }

    removeFile(event?) {
        if (event != null) {
            event.target.value = '';
        }
        this.fileName = null;
        this.value = {};
        this.valueChange.emit(this.value);

        this.rerenderer.pop();
        setTimeout(() => this.rerenderer.push(0), 100);
    }

    viewFile() {
        this.dialog.open(PdfPreviewDialogComponent, {
            data: {
                content: URL.createObjectURL(this.fileRef.nativeElement.files[0])
            }
        });
    }

    preventUpload() {
        this.shouldPreventUpload = true;
        this.activeButton = 'cancelled';
    }

    // ======================
    // private methods
    // ======================
}
