<div class="wrapper p-2">
    <div class="container-flex d-flex justify-content-center">
        <div class="tests-area card">
            <div class="test-header">
                <div class="test-name">
                    <span class="h5 m-0">Concurrency Tests</span>
                </div>
            </div>
            <hr>
            <div class="test-body config card m-1" #configCardRef>
                <ngx-spinner bdColor="#fbfbfb" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>
                <ng-container *ngIf="isBootstrapFinished">
                    <h6>
                        <span>Config</span>
                        <span class="show-hide float-right ml-2" (click)="toggleAll()">(+/- all)</span>
                        <span class="show-hide float-right" (click)="toggleConfig()">(+/- details)</span>
                    </h6>
                    <hr />
                    <div class="test-container" #testContainerRef>
                        <form class="px-1" #tests="ngForm">
                            <div class="form-group toggle m-0">
                                <label>( ... )</label>
                            </div>

                            <div class="form-group toggle off">
                                <label for="papelId">Papel</label>
                                <select class="form-control" id="papelId" name="papelId" [(ngModel)]="papelId" #papelIdElem="ngModel" required>
                                    <option *ngFor="let option of papelOptions" [value]="option.value">{{option.description}}</option>
                                </select>
                            </div>
                            <div [hidden]="papelIdElem.valid || papelIdElem.pristine" class="alert alert-danger">Campo obrigatório</div>

                            <div class="form-group toggle off">
                                <label for="flowDefinitionId">FlowDefinitionId</label>
                                <input type="text" class="form-control" id="flowDefinitionId" name="flowDefinitionId" [(ngModel)]="flowDefinitionId" #flowDefinitionIdElem="ngModel" required>
                                <div class="form-inline">
                                    <mat-checkbox class="mr-3" id="defaultDes" name="defaultDes" color="primary" (change)="setDefaultDes($event)" [checked]="true" #defaultDesRef>Default DES</mat-checkbox>
                                    <mat-checkbox class="mr-3" id="defaultHom" name="defaultHom" color="primary" (change)="setDefaultHom($event)" #defaultHomRef>Default HOM</mat-checkbox>
                                    <mat-checkbox id="defaultTre" name="defaultTre" color="primary" (change)="setDefaultTre($event)" #defaultTreRef>Default TRE</mat-checkbox>
                                </div>
                            </div>
                            <div [hidden]="flowObjectDefinitionIdElem.valid || flowObjectDefinitionIdElem.pristine" class="alert alert-danger">Campo obrigatório</div>

                            <div class="form-group toggle off">
                                <label for="flowObjectDefinitionId">FlowObjectDefinitionId</label>
                                <input type="text" class="form-control" id="flowObjectDefinitionId" name="flowObjectDefinitionId" [(ngModel)]="flowObjectDefinitionId" #flowObjectDefinitionIdElem="ngModel" required>
                            </div>
                            <div [hidden]="flowObjectDefinitionIdElem.valid || flowObjectDefinitionIdElem.pristine" class="alert alert-danger">Campo obrigatório</div>

                            <div class="form-group toggle off">
                                <label for="formData">FormData</label>
                                <textarea rows="3" class="form-control" id="formData" name="formData" [(ngModel)]="formData" #formDataElem="ngModel" required></textarea>
                            </div>
                            <div [hidden]="formDataElem.valid || formDataElem.pristine" class="alert alert-danger">Campo obrigatório</div>

                            <div class="d-flex justify-content-between">
                                <div class="form-group reqs mr-1">
                                    <label for="reqs">Requisições simultâneas</label>
                                    <input type="text" class="form-control" id="reqs" name="reqs" [(ngModel)]="reqs">
                                </div>
                                <div class="form-group reqs ml-1">
                                    <label for="reqsPerSec">Requisições por segundo</label>
                                    <input type="text" class="form-control" id="reqsPerSec" name="reqsPerSec" [(ngModel)]="reqsPerSec">
                                    <label for="secs">Quantos segundos</label>
                                    <input type="text" class="form-control" id="secs" name="secs" [(ngModel)]="secs">
                                </div>
                            </div>

                            <div class="form-footer mb-1">
                                <hr class="mt-0" />
                                <button class="btn btn-outline-primary"
                                        (click)="fireConcurrentReqs()"
                                        [disabled]="!tests.form.valid || areTestsRolling"
                                >Disparar simultaneamente</button>
                                <button class="btn btn-outline-primary float-right"
                                        (click)="fireScaledReqs()"
                                        [disabled]="!tests.form.valid || areTestsRolling"
                                >Disparar escalonadamente</button>
                            </div>
                        </form>
                    </div>
                </ng-container>
            </div>
            <div class="test-body card m-1">
                <ngx-spinner bdColor="#fbfbfb" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false"></ngx-spinner>
                <ng-container *ngIf="isBootstrapFinished">
                    <div class="test-container" #testResultsContainerRef>
                        <div class="header">
                            <button class="btn btn-outline-danger sort" [disabled]="areTestsRolling" (click)="sortRoundtrip()"><i class="fas fa-sort mr-1"></i>roundtrip</button>
                            <button class="btn btn-outline-danger sort" [disabled]="areTestsRolling" (click)="sortSequence()"><i class="fas fa-sort mr-1"></i>sequence</button>
                            <div class="form-inline">
                                <label class="mb-0 mr-2 pr-2">Requisições remanescentes</label>
                                <span>{{areTestsRolling ? (isTheTestConcurrent ? reqs - finishedTests : (reqsPerSec * secs) - finishedTests) : 0}}</span>
                            </div>
                            <div class="form-inline">
                                <label class="mb-0 mr-2 pr-2">Tempo decorrido</label>
                                <span>{{(Math.round(elapsedTime/100)/10).toFixed(1) + 's'}}</span>
                            </div>
                        </div>
                        <hr class="mt-2 mb-1" />
                        <div class="dino-container d-flex justify-content-center align-items-center" *ngIf="loadTestsResults.length == 0">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAA8CAYAAACQPx/OAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuNBNAaMQAAAPsSURBVHhe7Zy9ixNBGMYjUcQP7NTTw04s/MLGUohaHAsmYXObRoigRRpBOyv/AGu7KxQsrKwFUbEWK1EPsbARDitR8BrP8+N5994Jc5PZ7MfNZuey7wM/LtmZnZl9nmR3b3c2jVnS4uLiT/CvBG5wF6I8gnESSNXq9XpXoyi6T8C4NcNIV7xUfQRBsJu7FtkEsx4Y5ino26KwlRei3+/v565FNsEkayAwrknlg8Fgn628KBJIimCSBOKTYJI1kG63e6zT6RyFgcdt5UWRQFIEk5KOIaUggaQIJkkgPgkmSSA+CSZJID4JJkkgPgkmSSA+CSZJID4JJkkgPgkmFQokDMPT3ITIpWCuBOKTYG6hQLDrOddqtXZOgrsQ5RHMLesYssxdiPIIxkkgk8SXuxcI7BbmebFT6X2AZ8Bm6BhRFK3gL9XPwhJ3t72FEE5iY5QJpdyLNvrITK/Xe8RN1EcSiGcqIxDsai6grVcar8Ems7MggTgKBEaGWpuFkUAkkOplBPIHrBNYnvkaEOq/Vesx1M4mcwvyF+jtxmBsc9z17MkIZETOQN6b65dJXQOZo1CSgqFLFaoc9T+Y6zviN1CT5+jbEi+nsfEwZk/YOGsgOqgTz5nShTOpa7a6LtGPIXj/Ti2XQCSQ6SlLIGoSmw6W3zbrucYIhCZQrxDo/zAvnj1lCaQq5LTXMyQQz5BAPEMC8Yy6BtIMw/AQARMem6ZUSS0D0QUTyrq9mod1nNo+IfD6Jg+tnoIBPgSyysMR6YIxTh++zIEEYhOMkUB8Eoz5DuhqK2EzzjWqrx88BFGSYBLdGLKZ6Aw62+PuRGmCYRKIT4JhT4GakLYMRjeLtgJObZ/jb9yuBFJQQRAcgIFvlKlbASHIsxsuhP+gj4CPNpPzMNOBYOP2YCP1yWnOsO1OsPwWsBqtQGgXCeyartvKbYGUuR0OeMHDTFe73d6LFT6XgS0QmJx6C5erJl7AtAVS5nY44BMP0z+VFYiooJICgcnzoElw1VhqGXZhD7W6EogrTQhk4owQ1BldwKw6EHw47mE74l+eI2jyBhdNR2SACT1Mw8W5ZASyCuIJbWhz2wSCMXyhMWuc4qLpCB1+BcpERaFHwfRAYOwZXpwq1PcmkMoFExbwNe3ohGF4iYtzSQLxTHogeH1ZTaBD0Y6NGnbpgeDDcEJbL1U0nzhLH7WUcQwZgU995mOIDtZLvZaFb/R5ruv/VFJ+0vWXBv0m7lrRX0DAut9UGzazqgiENBwOd/HLRKG9JTDmRdY+nIgGik/QXRMM4iBXySWse0e1gbdjuwhs4FlVroP+Jh4XEOQV23oocrYbmkYfG2o0/gNXAbpJ82++0gAAAABJRU5ErkJggg==" />
                        </div>
                        <div class="result-item p-2" *ngFor="let result of loadTestsResults"
                             [ngClass]="{'success': result.isSuccess === true, 'error' : result.isSuccess === false }"
                        >
                            <div class="form-inline sortable sequence">
                                <label>sequence</label>
                                <span>{{result.sequence}}</span>
                            </div>
                            <div class="form-inline">
                                <label>batch</label>
                                <span>{{result.batch}}</span>
                            </div>
                            <div class="form-inline">
                                <label>start</label>
                                <span>{{result.start}}</span>
                            </div>
                            <div class="form-inline">
                                <label>finish</label>
                                <span>{{result.finish}}</span>
                            </div>
                            <div class="form-inline sortable roundtrip">
                                <label>roundtrip</label>
                                <span>{{result.roundtrip}}</span>
                            </div>
                            <div class="form-inline">
                                <label>id</label>
                                <span class="fixed">{{result.id}}</span>
                            </div>
                            <div class="form-inline">
                                <label>message</label>
                                <span>{{result.message}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
