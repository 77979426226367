export class FlowInstanceCounters {
    total?: number = 0;
    ongoing?: number = 0;
    startedByMe?: number = 0;
    pendingForMe?: number = 0;
}

export class CookieParticularState {
    searchTokens?: SearchToken[] = [];
    sortState?: SortState = new SortState();
    filterState?: PredefinedFilterState = new PredefinedFilterState();

    public constructor(init?: Partial<CookieParticularState>) {
        Object.assign(this, init);
    }
}

export class CookieGeneralState {
    main?: CookieParticularState = new CookieParticularState();
    unit?: CookieParticularState = new CookieParticularState();
    organization?: CookieParticularState = new CookieParticularState();
    patriarch?: CookieParticularState = new CookieParticularState();
    citizen?: CookieParticularState = new CookieParticularState();

    public constructor(init?: Partial<CookieGeneralState>) {
        Object.assign(this, init);
    }
}

export class SortState {
    descendingCreateDate: boolean = null;
    descendingUpdateDate: boolean = null;
    descendingName: boolean = null;
    descendingOwnerInfo: boolean = null;

    public constructor(init?: Partial<SortState>) {
        Object.assign(this, init);
    }
}

export class SearchToken {
    isCustomFilter?: boolean;
    text?: string;
    key?: string;
    addedTimestamp?: Date = new Date();

    public constructor(init?: Partial<SearchToken>) {
        Object.assign(this, init);
    }
}

export class PredefinedFilterState {
    isFinishedFilterOn: boolean = false;
    isOngoingFilterOn: boolean = false;
    isCancelledFilterOn: boolean = false;
    isStartedByMeFilterOn: boolean = false;
    isNotStartedByMeFilterOn: boolean = false;
    isPendingForMeFilterOn: boolean = false;
    isNotPendingForMeFilterOn: boolean = false;
}
