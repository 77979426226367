<div class="card flow-item-container shadow" *ngIf="model != null">
    <div class="card-body">
        <!-- Header -->
        <div class="header">
            <!-- Nome -->
            <h6 class="title m-0"
                matTooltipPosition="above"
                [matTooltip]="model.name"
                (click)="open()"
            >{{model.name}}</h6>

            <hr #rulerElemRef>

            <!-- Usuário instanciador -->
            <div class="body-line">
                <i class="fas fa-user pr-2" matTooltip="Usuário que iniciou o Fluxo"></i>
                <span class="relevant-info-font text-highlight" #textElemRef
                      [matTooltip]="model.ownerInfo"
                      [matTooltipDisabled]="!isUserNameOverflown"
                >{{model.ownerInfo}}</span>
            </div>

            <!-- Data de início -->
            <div class="body-line">
                <i class="far fa-calendar-alt pr-2" matTooltip="Data de início do Fluxo"></i>
                <span class="relevant-info-font">{{getCreateDateString()}}</span>
            </div>
        </div>

        <hr>

        <!-- Body -->
        <div class="body">
            <!-- Tags -->
            <div class="tags">
                <span class="tag finished" *ngIf="isFinished; else pendingTagTemplate">
                    <i class="fas fa-check-circle mr-1" matTooltip="Fluxo encerrado"></i>
                    <span class="text">Encerrado</span>
                </span>
                <ng-template #pendingTagTemplate>
                    <span class="tag pending" *ngIf="hasPendingTask; else cancelledTagTemplate">
                        <i class="fas fa-exclamation-circle mr-1" matTooltip="Fluxo com pendências para o usuário"></i>
                        <span class="text">Pendente</span>
                    </span>
                </ng-template>
                <ng-template #cancelledTagTemplate>
                    <span class="tag cancelled" *ngIf="isCancelled; else ongoingTagTemplate">
                        <i class="fas fa-times-circle mr-1" matTooltip="Fluxo cancelado"></i>
                        <span class="text">Cancelado</span>
                    </span>
                </ng-template>
                <ng-template #ongoingTagTemplate>
                    <span class="tag ongoing">
                        <i class="fas fa-hourglass-half mr-1" matTooltip="Fluxo em andamento"></i>
                        <span class="text">Em andamento</span>
                    </span>
                </ng-template>
            </div>

            <!-- Ações -->
            <div class="actions">
                <!-- Visualizar Fluxo -->
                <a class="card-link mr-2"
                   matTooltip="Visualizar Fluxo"
                   (click)="open()"
                >
                    <i class="fas fa-eye"></i>
                </a>

                <!-- Informações sobre o Fluxo -->
                <ng-container>
                    <ngx-mat-popover [popoverContent]="popoverContentTemplate" #popoverRef>
                        <a class="card-link"
                           matTooltip="Visualizar informações sobre o Fluxo"
                           (click)="infoClick($event)"
                        >
                            <i class="fas fa-info-circle"></i>
                        </a>
                    </ngx-mat-popover>
                    <ng-template #popoverContentTemplate>
                        <div class="wrapper" #wrapperRef>
                            <h6 class="mb-0 mt-2">{{model.name}}</h6>
                            <hr #rulerElemPopover>
                            <div class="info-line">
                                <label class="my-0 mr-2" #labelElem1Popover>Usuário solicitante</label>
                                <span class="relevant-info-font text-highlight" #textElem1Popover
                                      [matTooltip]="model.ownerInfo"
                                      [matTooltipDisabled]="!isInfoPanelOverflown(textElem1Popover, rulerElemPopover, labelElem1Popover)"
                                >{{model.ownerInfo}}</span>
                            </div>
                            <div class="info-line">
                                <label class="my-0 mr-2" #labelElem2Popover>Pendente com</label>
                                <span class="relevant-info-font" [class.text-highlight]="pendingTaskRecipient != null" #textElem2Popover
                                      [matTooltip]="pendingTaskRecipient"
                                      [matTooltipDisabled]="!isInfoPanelOverflown(textElem2Popover, rulerElemPopover, labelElem2Popover)"
                                >{{pendingTaskRecipient || '(N/D)'}}</span>
                            </div>
                            <div class="info-line">
                                <label class="my-0 mr-2">Data de início</label>
                                <span class="relevant-info-font">{{getCreateDateString()}}</span>
                            </div>
                            <div class="info-line">
                                <label class="my-0 mr-2">Última atualização</label>
                                <span class="relevant-info-font">{{getUpdateDateString()}}</span>
                            </div>
                            <div class="info-line">
                                <label class="my-0 mr-2">Data de encerramento</label>
                                <span class="relevant-info-font">{{getFinishDateString()}}</span>
                            </div>
                            <div class="info-line">
                                <label class="my-0 mr-2">Versão instanciada</label>
                                <span class="version-tag px-1 py-0">Versão {{model.version}}</span>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
</div>
