import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { FlowInstance, FlowInstanceState } from '../../../models/flow.model';
import { AuthService } from '../../../services/auth.service';
import { FlowObjectInstanceState } from '../../../models/flow-object.model';
import { NgxMatPopoverComponent } from '../../ngx-mat-popover/ngx-mat-popover.component';
import { ToastrService } from 'ngx-toastr';
import { EDocsService } from '../../../services/edocs.service';
import { Enums } from '../../../shared/enums';
import { Utils } from '../../../shared/utils';

@Component({
    selector: 'flow-instance-item',
    templateUrl: './flow-instance-item.component.html',
    styleUrls: ['./flow-instance-item.component.scss']
})
export class FlowInstanceItemComponent implements OnInit, OnChanges {
    @ViewChild('popoverRef') popoverRef: NgxMatPopoverComponent;
    @ViewChild('wrapperRef') wrapperRef: ElementRef;
    @ViewChild('textElemRef') textElemRef: ElementRef;
    @ViewChild('rulerElemRef') rulerElemRef: ElementRef;

    model: FlowInstance;
    pendingTaskRecipient: string = null;
    isUserNameOverflown: boolean = false;

    // #region [getters]
    get isFinished(): boolean {
        return [
            FlowInstanceState.Finished,
            FlowInstanceState.ManuallyFinished
        ].includes(this.model?.stateId);
    }

    get isCancelled(): boolean {
        return [
            FlowInstanceState.AutomaticallyCancelled,
            FlowInstanceState.ManuallyCancelled
        ].includes(this.model?.stateId);
    }

    get hasPendingTask(): boolean {
        return this.model?.flowObjectInstances.some(x =>
            [
                FlowObjectInstanceState.Started,
                FlowObjectInstanceState.AwaitingAction
            ].includes(x.stateId)
            && x.flowObjectInstanceActors.some(y => y.actorId == this.authService?.user?.id)
        );
    }
    // #endregion

    @Input() inputModel: FlowInstance;
    @Output() outputOpenEvent = new EventEmitter<FlowInstance>();

    constructor(
        private toastr: ToastrService,
        private authService: AuthService,
        private eDocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    async ngOnChanges(changes) {
        this.model = this.inputModel;

        let nextOpenTask = this.model.flowObjectInstances.find(x => x.stateId == FlowObjectInstanceState.Started);
        if (nextOpenTask?.inputData != null) {
            let inputData = JSON.parse(nextOpenTask.inputData);
            if (inputData.recipientName != null) {
                this.pendingTaskRecipient = inputData.recipientName;
            } else {
                const response_Encaminhamento = await this.eDocsService.getEncaminhamento(inputData.eDocsData.forwardingId);

                if (!response_Encaminhamento.isSuccess) {
                    this.toastr.error(response_Encaminhamento.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                const encaminhamentoLocal = response_Encaminhamento.data.destinos[0];

                if (!Utils.isNullOrEmpty(encaminhamentoLocal?.nome?.trim())) {
                    this.pendingTaskRecipient = encaminhamentoLocal.nome.toUpperCase().trim() + (
                        encaminhamentoLocal.nome.toUpperCase().trim() != encaminhamentoLocal.sigla.toUpperCase().trim()
                            && !Utils.isNullOrEmpty(encaminhamentoLocal.sigla.trim())
                            ? ` (${encaminhamentoLocal.sigla.toUpperCase().trim()})`
                            : ''
                    );
                }
            }
        }

        setTimeout(() => this.checkUserNameOverflow(), 1);
    }

    // ======================
    // public methods
    // ======================

    infoClick(event) {
        let target = event.currentTarget;
        target.classList.add('info-on');

        let interval = setInterval(() => {
            if (this.wrapperRef == null) {
                target.classList.remove('info-on');
                clearInterval(interval);
            }
        }, 400);
    }

    getCreateDateString(): string {
        return new Date(this.model.createDate).toLocaleString();
    }

    getUpdateDateString(): string {
        return this.model.updateDate == null ? '(N/D)' : new Date(this.model.updateDate).toLocaleString();
    }

    getFinishDateString(): string {
        return this.model.finishDate == null ? '(N/D)' : new Date(this.model.finishDate).toLocaleString();
    }

    checkUserNameOverflow() {
        this.isUserNameOverflown = this.textElemRef.nativeElement.parentElement.scrollWidth > this.rulerElemRef.nativeElement.offsetWidth;
    }

    isInfoPanelOverflown(textElem: HTMLElement, rulerElem: HTMLElement, labelElem: HTMLElement): boolean {
        return textElem.offsetWidth + labelElem.offsetWidth > rulerElem.offsetWidth;
    }

    open() {
        this.outputOpenEvent.emit(this.model);
    }

    // ======================
    // private methods
    // ======================
}
