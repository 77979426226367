import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { FlowObjectInstance } from '../models/flow-object.model';

@Injectable({
    providedIn: 'root'
})
export class FlowObjectInstanceService extends BaseService {
    controllerRoute: string = 'FlowObjectInstance';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getById(id: string, version?: number): Promise<IBaseModel<FlowObjectInstance>> {
        return this.httpClient.get<IBaseModel<FlowObjectInstance>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/${version || ''}`).toPromise();
    }

    public async update(data: FlowObjectInstance): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}`, data).toPromise();
    }

    public async executeTask(data: FlowObjectInstance): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/ExecuteTask`, data).toPromise();
    }

    public async enqueueTask(data: FlowObjectInstance): Promise<IBaseModel<FlowObjectInstance>> {
        return this.httpClient.put<IBaseModel<FlowObjectInstance>>(`${this.apiBaseUrl}/${this.controllerRoute}/EnqueueTask`, data).toPromise();
    }

    public async cancelFlowInstanceRegisterProcess(data: FlowObjectInstance): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/CancelRegisterProcessTask`, data).toPromise();
    }

    public async bringMjolnir(id: string, inputData: any): Promise<IBaseModel<string>> {
        return this.httpClient.post<IBaseModel<string>>(
            `${this.apiBaseUrl}/${this.controllerRoute}/BringMjolnir`,
            { id: id, inputData: JSON.stringify(inputData) }
        ).toPromise();
    }
}
