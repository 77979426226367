<ng-container *ngIf="model != null">
    <div class="wrapper px-1">
        <!-- Task info: Cancelada -->
        <ng-container *ngIf="isTaskCancelled; else componentTemplate">
            <div class="message-container">
                <b>Tarefa {{FlowObjectInstanceStateDescription.get(+model.stateId).toLowerCase()}} em {{dateString}}</b>
            </div>
            <hr />
            <div class="message-container">
                <p>
                    O Fluxo <b>{{inputFlowInstance.name}}</b> foi configurado para que qualquer instância do mesmo seja cancelada automaticamente em caso de inatividade.
                </p>
                <p>
                    Para todos os efeitos, o Fluxo <b>não foi completado</b> e as eventuais Tarefas previamente executadas <b>não possuem validade</b>.
                </p>
                <span>
                    Inicie uma <b>nova instância</b> deste Fluxo para completar a requisição do serviço.
                </span>
            </div>
        </ng-container>

        <ng-template #componentTemplate>
            <!-- Task body: visão do executor -->
            <div class="message-container" *ngIf="previousDocuments.length > 0 && isPublicAgent">
                <div>Conteúdo para encaminhamento:</div>
                <div class="documents-container">
                    <div class="position-relative my-1" *ngFor="let doc of previousDocuments">
                        <span class="document-highlight" (click)="openDocumentModal(doc.id)">
                            <span class="e-docs relevant-info-font text-highlight" matTooltip="Protocolo E-Docs do documento">{{doc.registro}}</span>
                            <span class="relevant-info-font d-inline-block" matTooltip="Clique para visualizar o documento que foi capturado no E-Docs">
                                <i class="fas fa-external-link-alt mr-1"></i><u>{{doc.filename}}</u>
                            </span>
                        </span>
                    </div>
                </div>
                <hr />
            </div>

            <!-- Task info: Realizada -->
            <div class="message-container">
                <div class="mb-3">
                    O conteúdo disponível no protocolo E-Docs
                    <span class="relevant-info-font text-highlight">{{protocolo}}</span>
                    foi encaminhado para<b>{{configSchema.taskForward.toImmediateSupervisor ? ' o gestor imediato' : ''}}</b>:
                </div>
                <div>
                    <span class="relevant-info-font text-highlight d-inline-block">{{inputData.recipientName.toUpperCase()}}</span>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>
