import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild
} from '@angular/core';
import {
    FlowObjectDefinition,
    FlowObjectInstance,
    FlowObjectInstanceState,
    FlowObjectType
} from '../../../models/flow-object.model';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../shared/enums';
import { AuthService } from '../../../services/auth.service';
import { EDocsService } from '../../../services/edocs.service';
import { Papel } from '../../../models/edocs.model';
import { Utils } from '../../../shared/utils';
import { FlowDefinition, FlowInstance, FlowTarget } from '../../../models/flow.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { InputDataTaskForm } from '../../../models/input-output-data.model';
import { ConfigSchema } from '../../../models/config-schema.model';
import { FORMIO_OPTIONS } from '../flow-object-form/formio/formio-options';

@Component({
    selector: 'flow-object-start-inbound-api',
    templateUrl: './flow-object-start-inbound-api.component.html',
    styleUrls: ['./flow-object-start-inbound-api.component.scss']
})
export class FlowObjectStartInboundApiComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('papelRef') papelRef: ElementRef;
    // #endregion

    // #region [Type properties]
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [const]
    DEFAULT_LOADING_TEXT: string = 'Carregando...' as const;
    // #endregion

    // #region [properties]
    inputData: InputDataTaskForm;
    configSchema: ConfigSchema;
    formSchema: any;
    formioOptions: any = FORMIO_OPTIONS;
    papeisSelector: Papel[] = [];
    fileUrls: any;
    pdfFiles: any[] = [];
    publicMessageHtml: SafeHtml = this.DEFAULT_LOADING_TEXT;
    // #endregion

    // #region [getters]
    get shouldShowPapelSelector(): boolean {
        return this.inputFlowDefinition?.targetId != FlowTarget.Citizen && this.papeisSelector.length > 0;
    }
    get hasPendingTask(): boolean {
        return this.inputFlowObjectInstance?.stateId == FlowObjectInstanceState.Started;
    }
    get isTaskPublicAgentActor(): boolean {
        return this.authService?.user?.isPublicAgent
            && this.inputFlowObjectInstance?.flowObjectInstanceActors.some(x => x.actorId == this.authService.user.id)
            && this.inputFlowInstance?.ownerId != this.authService.user.id;
    }
    get isReadOnly(): boolean {
        return [
            FlowObjectInstanceState.Finished,
            FlowObjectInstanceState.NotStarted
        ].includes(this.inputFlowObjectInstance?.stateId);
    }
    get isTaskCancelled(): boolean {
        return [
            FlowObjectInstanceState.AutomaticallyCancelled,
            FlowObjectInstanceState.ManuallyCancelled
        ].includes(this.inputFlowObjectInstance?.stateId);
    }
    get isBootstrapFinished(): boolean {
        return this.inputFlowObjectInstance != null
            && this.inputFlowObjectDefinition != null
            && this.inputFlowInstance != null
            && this.inputFlowDefinition != null;
    }
    get dateString(): string {
        return this.inputFlowObjectInstance == null ? '' : new Date(this.inputFlowObjectInstance.updateDate).toLocaleString();
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputFlowObjectInstance: FlowObjectInstance;
    @Input() inputFlowObjectDefinition: FlowObjectDefinition;
    @Input() inputFlowInstance: FlowInstance;
    @Input() inputFlowDefinition: FlowDefinition;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectInstance>();
    // #endregion

    constructor(
        private sanitizer: DomSanitizer,
        private toastr: ToastrService,
        private authService: AuthService,
        private eDocsService: EDocsService
    ) {
        // #region [hook do Formio para interceptação do submit e eventual alteração dos dados submetidos]
        // utilizado aqui para o tratamento diferenciado necessário aos
        // componentes customizados de arquivo (i.e.POST no Minio)
        this.formioOptions.hooks.beforeSubmit = async (submission, next) => {
            // checa se há um papel selecionado antes de prosseguir
            if (Utils.isNullOrEmpty(this.inputData.eDocsData.signerId)) {
                Utils.scrollToTop();
                this.toastr.error(Enums.Messages.NoSignerIdError, Enums.Messages.Error, Utils.getToastrErrorOptions());
                next([{ message: null }]);
                return;
            }

            // #region [tratamento de componentes do tipo "Arquivo PDF"]
            let submittedCustomFileComponentKeys = Object.keys(this.fileUrls);

            for (let key of submittedCustomFileComponentKeys) {
                let label = this.formSchema.components.find(x => x.key == key).label;

                if (Array.isArray(this.fileUrls[key])) {
                    submission.data[key] = {
                        fileName: [],
                        label: [],
                        minioKey: [],
                        isCustomFileComponent: true
                    };

                    for (let item of this.fileUrls[key]) {
                        submission.data[key].fileName.push(`${label}.pdf`);
                        submission.data[key].label.push(label);
                        submission.data[key].minioKey.push(item);
                    }
                } else {
                    submission.data[key] = {
                        fileName: [`${label}.pdf`],
                        label: [label],
                        minioKey: [this.fileUrls[key]],
                        isCustomFileComponent: true
                    };
                }
            }

            if (
                // checa a validade da assinatura ICP-Brasil de anexos que a exijam, caso exista algum no form
                await Utils.isValidIcpBrasil(submission.data, this.formSchema, this.eDocsService, this.toastr)
                // checa se o anexo é capturável no E-Docs
                && await Utils.isValidEDocsDocument(submission.data, this.formSchema, this.eDocsService, this.toastr)
            ) {
                // prossegue com o lifecycle padrão do Formio
                next(null, submission);
            } else {
                next([{ message: null }]);
            }
            // #endregion
        };
        // #endregion
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.configSchema = JSON.parse(this.inputFlowObjectDefinition.configSchema) as ConfigSchema;
        this.formSchema = JSON.parse(this.inputFlowObjectDefinition.formSchema);
        this.inputData = this.inputFlowObjectInstance.inputData == null
            ? new InputDataTaskForm()
            : Object.assign(new InputDataTaskForm(), JSON.parse(this.inputFlowObjectInstance.inputData));
        this.fileUrls = JSON.parse(this.inputData.fileUrls);

        // #region [listagem de campos de anexos]
        let multipleFilesComponents = this.formSchema.components.filter(x => x.type == 'pdfUploadMultiple').map(x => x.key);
        for (let key of Object.keys(this.fileUrls)) {
            if (multipleFilesComponents.includes(key)) {
                let count = this.fileUrls[key].length;
                for (let i = 1; i <= count; i++) {
                    this.pdfFiles.push({ filename: `${this.formSchema.components.find(x => x.key == key).label} (${i}).pdf` });
                }
            } else {
                this.pdfFiles.push({ filename: `${this.formSchema.components.find(x => x.key == key).label}.pdf` });
            }
        }
        // #endregion

        this.formSchema.components.filter(x => x.type != 'button').forEach(x => {
            x.hidden = true;
            x.validate.required = false;
        });

        this.publicMessageHtml = this.sanitizer.bypassSecurityTrustHtml(this.inputFlowObjectDefinition.publicMessageHtml);

        // #region [seletor de papéis]
        if (this.inputFlowDefinition?.targetId != FlowTarget.Citizen) {
            this.papeisSelector = this.authService.user.papeis;

            // se rota "/flow-definition"
            if (this.inputFlowObjectInstance == null) {
                // força o usuário a selecionar um papel manualmente
                setTimeout(() => (this.inputData.eDocsData ??= {}).signerId = null, 50);
            }
        } else {
            (this.inputData.eDocsData ??= {}).signerId = this.authService.user.id;
        }
        // #endregion
    }

    // ======================
    // public methods
    // ======================

    onSubmit(event) {
        this.inputData.data = event.data;
        this.inputFlowObjectInstance.inputData = JSON.stringify(this.inputData);
        this.outputSubmitEvent.emit(this.inputFlowObjectInstance);
    }

    // ======================
    // private methods
    // ======================
}
