import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IBaseOption } from '../../models/base.model';
import { AcessoCidadaoService } from '../../services/acesso-cidadao.service';
import { Enums } from '../../shared/enums';
import { Utils } from '../../shared/utils';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { FlowObjectType } from '../../models/flow-object.model';

@Component({
    selector: 'papel-selector',
    templateUrl: './papel-selector.component.html',
    styleUrls: ['./papel-selector.component.scss']
})
export class PapelSelectorComponent implements OnInit {
    FlowObjectType: typeof FlowObjectType = FlowObjectType;

    papelOptions: IBaseOption[] = [];

    @Input() signerId: string;
    @Output() signerIdChange = new EventEmitter<string>();

    @Input() inputPapeis: any[]; /* pode ser Papel[] ou AgentePublicoPapel[] */
    @Input() inputFlowObjectType: FlowObjectType = FlowObjectType.StartForm;
    @Input() inputIsReadOnly: boolean = false;

    constructor(
        private toastr: ToastrService,
        private authService: AuthService,
        private acessoCidadaoService: AcessoCidadaoService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        if (this.inputIsReadOnly) {
            // caso a Task já tenha sido executada
            const response_Papel = await this.acessoCidadaoService.getPapel(this.signerId);

            if (!response_Papel.isSuccess) {
                this.toastr.error(response_Papel.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.papelOptions = [{
                value: this.signerId,
                description: `${response_Papel.data.nome} - ${response_Papel.data.lotacao.nomeCurto} - ${response_Papel.data.lotacao.organizacao.sigla}`
            }];
        } else {
            // caso a Task ainda não tenha sido executada
            this.papelOptions = Array.from(this.inputPapeis, item => {
                if (item.lotacao != null) {
                    return {
                        value: item.guid,
                        description: `${item.nome} - ${item.lotacao.nomeCurto} - ${item.lotacao.organizacao.sigla}`
                    };
                } else {
                    return {
                        value: item.id,
                        description: `${item.nome} - ${item.siglaUnidadePapel} - ${item.siglaOrganizacaoPapel}`
                    };
                }
            });

            this.signerId = this.papelOptions.length > 0 ? this.papelOptions[0].value : this.authService.user.id;
            this.onChange();
        }
    }

    // ======================
    // public methods
    // ======================

    onChange() {
        this.signerIdChange.emit(this.signerId);
    }

    // ======================
    // private methods
    // ======================
}
