import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild
} from '@angular/core';
import {
    FlowObjectInstance,
    FlowObjectInstanceState,
    FlowObjectDefinition,
    FlowObjectType
} from '../../../models/flow-object.model';
import {
    Papel
} from '../../../models/edocs.model';
import {
    FlowDefinition,
    FlowTarget
} from '../../../models/flow.model';
import { FORMIO_OPTIONS } from './formio/formio-options';
import { AuthService } from '../../../services/auth.service';
import { EDocsService } from '../../../services/edocs.service';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../shared/enums';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from '../../../shared/utils';
import { InputDataTaskForm } from '../../../models/input-output-data.model';
import { MatDialog } from '@angular/material/dialog';
import { PdfPreviewDialogComponent } from '../../pdf-preview-dialog/pdf-preview-dialog.component';
import { FlowObjectDefinitionService } from '../../../services/flow-object-definition.service';
import { ConfirmationService } from 'primeng/api';
import { OnDestroy } from '@angular/core';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';

@Component({
    selector: 'flow-object-form',
    templateUrl: './flow-object-form.component.html',
    styleUrls: ['./flow-object-form.component.scss']
})
export class FlowObjectFormComponent implements OnInit, OnDestroy {
    // #region [ViewChild]
    @ViewChild('papelRef') papelRef: ElementRef;
    // #endregion

    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    // #endregion

    // #region [properties]
    model: FlowObjectDefinition;
    formSchema: any;
    inputData: InputDataTaskForm = new InputDataTaskForm();
    formioOptions: any = FORMIO_OPTIONS;
    filesUploadedCounter: number = 0;
    totalFilesCounter: number = null;
    papeisSelector: Papel[] = [];
    isReadOnly: boolean = false;
    isFirstFormChange: boolean = true;
    isLocalLoading: boolean = false;
    isBootstrapFinished: boolean = false;
    // #endregion

    // #region [getters]
    get shouldShowPapelSelector(): boolean {
        return this.inputFlowDefinition?.targetId != FlowTarget.Citizen && this.papeisSelector.length > 0;
    }

    get hasDraft(): boolean {
        return !Utils.isNullOrEmpty(window.localStorage.getItem(this.inputFlowDefinition.id))
            && window.localStorage.getItem(`${this.inputFlowDefinition.id}_default`) != window.localStorage.getItem(this.inputFlowDefinition.id);
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputFlowObjectDefinition: FlowObjectDefinition;
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputFlowObjectInstance: FlowObjectInstance;
    @Output() outputSubmitEvent = new EventEmitter<InputDataTaskForm>();
    // #endregion

    constructor(
        private dialog: MatDialog,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private confirmationService: ConfirmationService,
        private authService: AuthService,
        private eDocsService: EDocsService,
        private flowObjectDefinitionService: FlowObjectDefinitionService
    ) {
        // #region [hook do Formio para interceptação do submit e eventual alteração dos dados submetidos]
        // utilizado aqui para o tratamento diferenciado necessário aos
        // componentes customizados de arquivo(i.e.POST no Minio), dentre outros
        this.formioOptions.hooks.beforeSubmit = async (submission, next) => {
            this.spinner.show('genericFullscreen');

            try {
                let keys = Object.keys(submission.data);
                let values = Object.values(submission.data);
                let components = JSON.parse(this.inputFlowObjectDefinition.formSchema).components;

                // #region [tratamento de componentes do tipo "Valor Monetário": valores com decimais completos e cifras]
                let currencyComponents = components.filter(x => x.type == 'currency');
                currencyComponents.forEach(x => {
                    let formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: x.currency });

                    let i = keys.indexOf(x.key);
                    if (i == -1) {
                        submission.data[x.key] = formatter.format(0);
                    } else {
                        let value = values[i] as number;
                        submission.data[x.key] = formatter.format(value);
                    }
                });
                // #endregion

                // #region [tratamento específico para componentes com máscara múltipla (e.g. "Telefone")]
                for (let i = 0; i < values.length; i++) {
                    if (
                        typeof values[i] == 'object'
                        && !Array.isArray(values[i])
                        && values[i].hasOwnProperty('value')
                    ) {
                        submission.data[keys[i]] = values[i]['value'];
                    }
                }
                // #endregion

                // #region [tratamento prévio de labels de componentes do tipo "Arquivo(s) PDF"]
                let singles = components.filter(x => x.type == 'pdfUpload').map(x => x.key);
                for (let key of singles) {
                    if (submission.data[key] != null) {
                        submission.data[key].label = [components.find(x => x.key == key).label];
                    }
                }

                let multiples = components.filter(x => x.type == 'pdfUploadMultiple').map(x => x.key);
                for (let key of multiples) {
                    if (submission.data[key] != null) {
                        submission.data[key].label = [];

                        for (let i = 1; i <= submission.data[key].fileName.length; i++) {
                            submission.data[key].label.push(`${components.find(x => x.key == key).label} (${i})`);
                        }
                    }
                }
                // #endregion

                // #region [preview do documento final]
                const response = await this.flowObjectDefinitionService.previewFinalPdf({
                    id: this.model.id,
                    unitId: this.inputFlowDefinition.unitId,
                    signerId: this.inputData.eDocsData.signerId,
                    formData: submission.data
                });

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }
                // #endregion

                setTimeout(() => {
                    let dialog = this.dialog.open(PdfPreviewDialogComponent, {
                        data: {
                            content: response.data,
                            title: Enums.Messages.FinalPreviewDialogTitle
                        }
                    });
                    dialog.afterClosed().subscribe(result => {
                        this.confirmationService.confirm({
                            message: Enums.Messages.ConfirmSendForm,
                            accept: async () => {
                                // #region [checagem de seleção de papel antes de prosseguir]
                                if (Utils.isNullOrEmpty(this.inputData.eDocsData.signerId)) {
                                    Utils.scrollToTop();
                                    this.toastr.error(Enums.Messages.NoSignerIdError, Enums.Messages.Error, Utils.getToastrErrorOptions());
                                    next([{ message: null }]);
                                    return;
                                }
                                // #endregion

                                let components = JSON.parse(this.inputFlowObjectDefinition.formSchema).components;

                                // #region [tratamento de componentes do tipo "Protocolo E-Docs"]
                                // componentes do tipo "Protocolo E-Docs" adicionados diretamente no formulário
                                let eDocsProtocolComponents = components.filter(x => x.type == 'eDocsProtocol');

                                let eDocsProtocolList: { key: string, id: string, protocol: string, canUse: boolean, hasErrors: boolean }[] = [];
                                for (let component of eDocsProtocolComponents) {
                                    let key = component.key;
                                    let value = submission.data[key];

                                    let canUseResult = await Utils.canUseEDocsDocument(value, this.eDocsService, this.toastr);
                                    if (!Utils.isNullOrEmpty(value)) {
                                        eDocsProtocolList.push({
                                            key: key,
                                            id: canUseResult.id,
                                            protocol: value,
                                            canUse: canUseResult.canUse,
                                            hasErrors: canUseResult.hasErrors
                                        });
                                    }
                                }

                                if (eDocsProtocolList.length > 0) {
                                    if (eDocsProtocolList.some(x => !x.canUse)) {
                                        let printableErrorsList = eDocsProtocolList.filter(x => !x.canUse && !x.hasErrors).map(x => x.protocol);

                                        if (printableErrorsList.length > 0) {
                                            let flattened = printableErrorsList.join('; ');
                                            this.toastr.warning(Enums.Messages.EDocsProtocolDocumentsPendency.replace('{0}', flattened), Enums.Messages.Pendency, Utils.getToastrErrorOptions());
                                        }

                                        next([{ message: null }]);
                                        return;
                                    }

                                    for (let item of eDocsProtocolList) {
                                        if (item.canUse) {
                                            submission.data[item.key + '_$$_guid_$$_'] = item.id;
                                        } else {
                                            submission.data[item.key + '_$$_guid_$$_'] = null;
                                        }
                                    }
                                }

                                // componentes do tipo "Protocolo E-Docs" pertencentes a Tabelas de Dados (datagridCustom)
                                eDocsProtocolList = [];
                                let datagridCustomComponents = components.filter(x => x.type == 'datagridCustom');
                                for (let datagridCustom of datagridCustomComponents) {
                                    let datagridCustomComponents = datagridCustom.components.filter(x => x.type == 'eDocsProtocol');

                                    for (let component of datagridCustomComponents) {
                                        if (submission.data[datagridCustom.key] == null) continue;

                                        for (let i = 0; i < submission.data[datagridCustom.key].length; i++) {
                                            let row = submission.data[datagridCustom.key][i];
                                            let key = `${datagridCustom.key}_${component.key}_${i}`;
                                            let value = row[component.key];

                                            let canUseResult = await Utils.canUseEDocsDocument(value, this.eDocsService, this.toastr);
                                            if (!Utils.isNullOrEmpty(value)) {
                                                eDocsProtocolList.push({
                                                    key: key,
                                                    id: canUseResult.id,
                                                    protocol: value,
                                                    canUse: canUseResult.canUse,
                                                    hasErrors: canUseResult.hasErrors
                                                });
                                            }
                                        }
                                    }
                                }

                                if (eDocsProtocolList.length > 0) {
                                    if (eDocsProtocolList.some(x => !x.canUse)) {
                                        let printableErrorsList = eDocsProtocolList.filter(x => !x.canUse && !x.hasErrors).map(x => x.protocol);

                                        if (printableErrorsList.length > 0) {
                                            let flattened = printableErrorsList.join('; ');
                                            this.toastr.warning(Enums.Messages.EDocsProtocolDocumentsPendency.replace('{0}', flattened), Enums.Messages.Pendency, Utils.getToastrErrorOptions());
                                        }

                                        next([{ message: null }]);
                                        return;
                                    }

                                    for (let item of eDocsProtocolList) {
                                        if (item.canUse) {
                                            submission.data[item.key + '_$$_guid_$$_'] = item.id;
                                        } else {
                                            submission.data[item.key + '_$$_guid_$$_'] = null;
                                        }
                                    }
                                }
                                // #endregion

                                // #region [tratamento de componentes do tipo "Arquivo(s) PDF"]
                                let submittedCustomFileComponentKeys = Object.keys(submission.data).filter(x => submission.data[x]?.isCustomFileComponent);
                                let submittedCustomFileComponentValues = Object.values(submission.data).filter((x: any) => x?.isCustomFileComponent);

                                // #region [checagem redundante de componentes customizados de arquivo obrigatórios]
                                // contorno de bug do Formio que não valida mais o campo automaticamente uma vez que o mesmo seja limpo/esvaziado
                                let requiredCustomFileComponents = components.filter(x =>
                                    ['pdfUpload', 'pdfUploadMultiple'].includes(x.type)
                                    && x.validate.required === true
                                    && document.querySelector(`.formio-component-${x.key}:not(.formio-hidden)`) != null
                                ) as any[];
                                let noncompliant = requiredCustomFileComponents.filter(x =>
                                    !submittedCustomFileComponentKeys.includes(x.key)
                                    || submission.data[x.key].fileName.length == 0
                                );
                                if (noncompliant.length > 0) {
                                    let componentLabels = '';
                                    noncompliant.forEach(x => componentLabels += `"${x.label}"; `);
                                    componentLabels = componentLabels.trim().split(';').slice(0, -1).join(';');

                                    this.toastr.warning(Enums.Messages.RequiredFilesPendency.replace('{0}', componentLabels), Enums.Messages.Pendency, Utils.getToastrErrorOptions());
                                    next([{ message: null }]);
                                    return;
                                }
                                // #endregion

                                if (submittedCustomFileComponentKeys.length == 0) {
                                    // prossegue com o lifecycle padrão do Formio
                                    next(null, submission);
                                } else {
                                    this.totalFilesCounter = 0;
                                    submittedCustomFileComponentValues.forEach(x => {
                                        this.totalFilesCounter += x['fileName']?.length;
                                    });

                                    for (let i = 0; i < submittedCustomFileComponentKeys.length; i++) {
                                        let item = submission.data[submittedCustomFileComponentKeys[i]];
                                        let bufferLabel = [];

                                        for (let j = 0; j < item.fileName.length; j++) {
                                            let fs = JSON.parse(this.model.formSchema);
                                            let label = fs.components.find(x => x.key == submittedCustomFileComponentKeys[i]).label.trim();

                                            // garante que o label do componente esteja correto
                                            bufferLabel = (bufferLabel || []).concat([label]);

                                            if (j == item.fileName.length - 1) {
                                                submission.data[submittedCustomFileComponentKeys[i]].label = bufferLabel;
                                            }

                                            this.filesUploadedCounter++;

                                            if (this.filesUploadedCounter == this.totalFilesCounter) {
                                                // checa a validade da assinatura ICP-Brasil de anexos que a exijam, caso exista algum no form;
                                                // checa se o anexo é capturável no E-Docs
                                                if (
                                                    await Utils.isValidIcpBrasil(submission.data, this.formSchema, this.eDocsService, this.toastr)
                                                    && await Utils.isValidEDocsDocument(submission.data, this.formSchema, this.eDocsService, this.toastr)
                                                ) {
                                                    // prossegue com o lifecycle padrão do Formio
                                                    next(null, submission);
                                                } else {
                                                    next([{ message: null }]);
                                                }

                                                break;
                                            }
                                        }
                                    }

                                    setTimeout(() => {
                                        this.filesUploadedCounter = 0;
                                        this.totalFilesCounter = null;
                                    }, 500);
                                }
                                // #endregion
                            },
                            reject: () => {
                                next([{ message: null }]);
                                this.confirmationService.close();
                            }
                        });
                    });
                }, 200);
            } finally {
                this.spinner.hide('genericFullscreen');
            }
        };
        // #endregion

        window.addEventListener('beforeunload', () => this.ngOnDestroy());
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        this.model = this.inputFlowObjectDefinition ? this.inputFlowObjectDefinition : this.inputFlowObjectInstance.flowObjectDefinition;
        this.formSchema = !Utils.isNullOrEmpty(this.model?.formSchema) ? JSON.parse(this.model.formSchema) : null;

        if (this.formSchema != null) {
            this.processFormSchema();
        }

        // #region [se rota "/flow-instance"]
        if (this.inputFlowObjectInstance != null) {
            this.inputData = JSON.parse(this.inputFlowObjectInstance.inputData) as InputDataTaskForm;
            this.isReadOnly = [FlowObjectInstanceState.Finished, FlowObjectInstanceState.NotStarted].includes(this.inputFlowObjectInstance.stateId);
        }
        // #endregion

        // #region [seletor de papéis]
        if (this.inputFlowDefinition?.targetId != FlowTarget.Citizen) {
            this.papeisSelector = this.authService.user.papeis;

            // se rota "/flow-definition"
            if (this.inputFlowObjectInstance == null) {
                // força o usuário a selecionar um papel manualmente
                setTimeout(() => (this.inputData.eDocsData ??= {}).signerId = null, 50);
            }
        } else {
            (this.inputData.eDocsData ??= {}).signerId = this.authService.user.id;
        }
        // #endregion

        // #region [contorno para o i18n disponibilizado de maneira não ideal pelo Formio]
        let elementsObserved = [];
        setInterval(() => {
            let elements = Array.from(document.querySelectorAll('.formio-component [ref=charcount]'));
            let newElements = elements.filter(x => !elementsObserved.includes(x));
            if (newElements.length > 0) {
                newElements.forEach((x: HTMLElement) => {
                    const config = { attributeFilter: ['class'] };
                    const callback = () => x.innerText = x.innerText.replace('remaining', 'restantes');
                    const observer = new MutationObserver(callback);
                    observer.observe(x, config);
                });

                elementsObserved = elementsObserved.concat(newElements);
            }
        }, 1000);
        // #endregion

        // reinicia a fila de upload de arquivos em campos dos tipos "pdfUpload"/"pdfUploadMultiple"
        window['_$_fileQueue'] = [];

        setTimeout(() => this.isBootstrapFinished = true, 100);
    }

    ngOnDestroy() {
        delete window['_$_fileQueue'];
    }

    // ======================
    // public methods
    // ======================

    onSubmit(event) {
        if (window['_$_fileQueue'] != null && window['_$_fileQueue'].length > 0) {
            this.toastr.warning(Enums.Messages.UnfinishedFileUploads, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        if (this.inputFlowObjectInstance == null || this.inputFlowObjectInstance.stateId == FlowObjectInstanceState.NotStarted) {
            this.inputData.data = event.data;
            this.outputSubmitEvent.emit(this.inputData);
        }
    }

    onFormLoad(event) {
        // #region [se rota "/flow-definition"]
        if (this.inputFlowObjectInstance == null) {
            let defaultFormData = window.localStorage.getItem(`${this.inputFlowDefinition.id}_default`);
            let formData = window.localStorage.getItem(this.inputFlowDefinition.id);

            // carrega rascunho do formulário
            if (
                defaultFormData != null
                && formData != null
                && defaultFormData != formData
            ) {
                this.inputData.data = JSON.parse(decompressFromUTF16(formData));
                this.toastr.success(Enums.Messages.FormDraftRecovered, Enums.Messages.Form);
            }
        }
        // #endregion

        // #region [ajuste para exibição adequada entre formatos "Fixo" e "Celular" em campos do tipo "phoneNumber"]
        if (this.isReadOnly) {
            setTimeout(() => {
                const phoneNumberComponents = event.components.filter(x => x.type == 'phoneNumber');
                for (let component of phoneNumberComponents) {
                    let componentInput = document.querySelector(`#${component.id} input`) as HTMLInputElement;
                    let componentSelect = document.querySelector(`#${component.id} select`) as HTMLSelectElement;

                    if (componentInput && componentSelect && componentInput.value.replace(/[^\d]/g, '').length == 10) {
                        componentSelect.value = Enums.Messages.LandlinePhoneText;
                        componentSelect.dispatchEvent(new CustomEvent('change'));
                    }
                }
            }, 500);
        }
        // #endregion

        // #region [ajuste para exibição adequada de componentes do tipo "datagrid"]
        setTimeout(() => {
            const datagridComponents = event.components.filter(x => x.type == 'datagrid');
            if (datagridComponents.length > 0) {
                setInterval(() => {
                    for (let component of datagridComponents) {
                        let componentTable = document.querySelector(`#${component.id} table`) as HTMLTableElement;

                        if (componentTable != null) {
                            const cols = componentTable.querySelectorAll('tbody tr:first-of-type td[ref ^= "datagrid"]').length;
                            componentTable.querySelector('tfoot td')?.setAttribute('colspan', cols.toString());

                            if (window.innerWidth > 650) {
                                componentTable.querySelectorAll('tbody > tr > td:not(:last-child)').forEach((x: HTMLTableDataCellElement) => {
                                    x.style.width = `calc(100% / ${cols})`;
                                });
                            } else {
                                const thElements = componentTable.querySelectorAll('thead th');
                                const tdLabels = Array.from(thElements).map((x: HTMLTableHeaderCellElement) => x.innerText.trim());
                                componentTable.querySelectorAll('tbody tr').forEach(tr => {
                                    Array.from(tr.children).forEach((td, i) => td.setAttribute('label', tdLabels[i]));
                                });
                            }
                        }
                    }
                }, 1000);
            }
        }, 500);
        // #endregion
    }

    saveDraft(event) {
        if (event.data == null) return;

        // se rota "/flow-instance"
        if (this.inputFlowObjectInstance != null) return;

        let defaultFormData = window.localStorage.getItem(`${this.inputFlowDefinition.id}_default`);
        if (this.isFirstFormChange && defaultFormData == null) {
            // armazena o estado padrão do formulário para efeito de comparação posterior
            let compressed = compressToUTF16(JSON.stringify(event.data));
            window.localStorage.setItem(`${this.inputFlowDefinition.id}_default`, compressed);
            this.isFirstFormChange = false;
            return;
        }

        try {
            let tempData = JSON.parse(JSON.stringify(event.data));
            let pdfComponents = this.formSchema.components.filter(x => ['pdfUpload', 'pdfUploadMultiple'].includes(x.type));
            for (let item of pdfComponents) {
                delete tempData[item.key];
            }

            let compressed = compressToUTF16(JSON.stringify(tempData));
            window.localStorage.setItem(this.inputFlowDefinition.id, compressed);
        } catch(e) {
            console.log(e);
        }
    }

    clearDraft() {
        window.localStorage.removeItem(this.inputFlowDefinition.id);
        this.toastr.success(Enums.Messages.FormDraftCleared, Enums.Messages.Form);
    }

    async loadFormData() {
        this.isLocalLoading = true;
        this.spinner.show('local');

        try {
            const response = await this.flowObjectDefinitionService.getFormData(this.model.id);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            setTimeout(() => {
                this.formSchema = JSON.parse(response.data);
                this.processFormSchema();
            }, 400);
        } finally {
            setTimeout(() => {
                this.spinner.hide('local');
                this.isLocalLoading = false;
            }, 500);
        }
    }

    // ======================
    // private methods
    // ======================

    private processFormSchema() {
        // #region [alteração do texto do botão "Enviar" do formulário]
        // para evitar alegações de usuários advogados dizendo que o PDF do preview é comprovante suficiente de envio do formulário
        const buttonComponent = this.formSchema.components.find(x => x.type == 'button' && x.action == 'submit');
        buttonComponent.label = Enums.Messages.SendButtonText;
        // #endregion

        // #region [alteração on-the-fly do tipo base de componentes customizados por "extends" no FormBuilder]
        // para evitar erros de "Unknown component" durante a renderização
        this.formSchema.components = Utils.retypeCustomComponents(this.formSchema.components);
        this.formSchema.components.filter(x => x.type == 'datagridCustom').forEach(x => {
            x.type = 'datagrid';
            x.components = Utils.retypeCustomComponents(x.components);
        });
        // #endregion
    }
}
