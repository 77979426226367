import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FlowDefinition } from '../models/flow.model';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { FlowDefinitionServiceGetParams } from './interfaces/flow-definition.interface';

@Injectable({
    providedIn: 'root'
})
export class FlowDefinitionService extends BaseService {
    controllerRoute: string = 'FlowDefinition';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getById(id: string, version?: number): Promise<IBaseModel<FlowDefinition>> {
        return this.httpClient.get<IBaseModel<FlowDefinition>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}/${version || ''}`).toPromise();
    }

    public async getByCatalogServiceId(id: string): Promise<IBaseModel<FlowDefinition>> {
        return this.httpClient.get<IBaseModel<FlowDefinition>>(`${this.apiBaseUrl}/${this.controllerRoute}/GetByCatalogServiceId/${id}`).toPromise();
    }

    public async filterPaginated(getParams?: FlowDefinitionServiceGetParams): Promise<IBaseModel<FlowDefinition[]>> {
        let httpParams = new HttpParams();

        httpParams = httpParams.append('flowTarget', getParams.flowTarget.toString());
        if (getParams?.locationId != null) httpParams = httpParams.append('locationId', getParams.locationId);
        if (getParams?.page != null) httpParams = httpParams.append('page', getParams.page.toString());
        if (getParams?.pageSize != null) httpParams = httpParams.append('pageSize', getParams.pageSize.toString());
        if (getParams?.sortField != null) httpParams = httpParams.append('sortField', getParams.sortField);
        if (getParams?.isSortDescending != null) httpParams = httpParams.append('isSortDescending', getParams.isSortDescending.toString());
        if (getParams?.skipDataResults != null) httpParams = httpParams.append('skipDataResults', getParams.skipDataResults.toString());
        if (getParams?.searchTokens != null && getParams.searchTokens.length > 0) {
            httpParams = httpParams.append('searchTokens', getParams.searchTokens.map(x => x.trim()).join('◄►'));
        }

        return this.httpClient.get<IBaseModel<FlowDefinition[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/FilterPaginated`, { params: httpParams }).toPromise();
    }
}
