import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FlowObjectDefinition } from '../models/flow-object.model';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';

@Injectable({
    providedIn: 'root'
})
export class FlowObjectDefinitionService extends BaseService {
    controllerRoute: string = 'FlowObjectDefinition';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async update(data: FlowObjectDefinition): Promise<IBaseModel<void>> {
        return this.httpClient.put<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}`, data).toPromise();
    }

    public async previewFinalPdf(data: any): Promise<IBaseModel<string>> {
        return this.httpClient.post<IBaseModel<string>>(`${this.apiBaseUrl}/${this.controllerRoute}/PreviewFinalPdf`, data).toPromise();
    }

    public async getFormData(id: string): Promise<IBaseModel<string>> {
        return this.httpClient.get<IBaseModel<string>>(`${this.apiBaseUrl}/${this.controllerRoute}/GetFormData/${id}`).toPromise();
    }
}
