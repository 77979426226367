<ng-container *ngIf="model != null">
    <div class="wrapper px-1">
        <!-- Task info: Cancelada -->
        <ng-container *ngIf="isTaskCancelled; else componentTemplate">
            <div class="message-container">
                <b>
                    Tarefa cancelada automaticamente pelo sistema em
                    <span class="d-inline-block text-highlight relevant-info-font">{{dateString}}</span>
                </b>
            </div>
            <hr />
            <div class="message-container">
                <p>
                    O Fluxo
                    <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.name}}</span>
                    foi configurado para que qualquer instância do mesmo seja cancelada automaticamente
                    após data e hora específicos ou em caso de inatividade prolongada.
                </p>
                <p>
                    Para todos os efeitos, o Fluxo <b>não foi completado</b> e as eventuais Tarefas previamente executadas <b>não possuem validade</b>.
                </p>
                <span>
                    Inicie uma <b>nova instância</b> deste Fluxo para completar a requisição do serviço.
                </span>
            </div>
        </ng-container>

        <ng-template #componentTemplate>
            <!-- Seletor de papel -->
            <ng-container *ngIf="isTaskPublicAgentActor && hasPendingTask && papeisSelector.length > 0">
                <papel-selector [(signerId)]="inputData.eDocsData.signerId"
                                [inputIsReadOnly]="isReadOnly"
                                [inputPapeis]="papeisSelector"
                                [inputFlowObjectType]="FlowObjectType.TaskApprove"
                ></papel-selector>
                <hr />
            </ng-container>

            <!-- Task body: visão do executor -->
            <div class="message-container" *ngIf="previousDocuments.length > 0 && isTaskPublicAgentActor">
                <div>Conteúdo avaliado:</div>
                <div class="documents-container">
                    <div class="position-relative my-1" *ngFor="let doc of previousDocuments">
                        <span class="document-highlight" (click)="openDocumentModal(doc.id)">
                            <span class="e-docs relevant-info-font text-highlight" matTooltip="Protocolo E-Docs do documento">{{doc.registro}}</span>
                            <span class="relevant-info-font d-inline-block" matTooltip="Clique para visualizar o documento que foi capturado no E-Docs">
                                <i class="fas fa-external-link-alt mr-1"></i><u>{{doc.filename}}</u>
                            </span>
                        </span>
                    </div>
                </div>
                <hr />
            </div>

            <!-- Task info: Realizada -->
            <div class="message-container" *ngIf="!hasPendingTask; else pendingTaskTemplate">
                <div class="mb-3">
                    O conteúdo disponível no Encaminhamento E-Docs de protocolo
                    <span class="relevant-info-font text-highlight">{{forwardingProtocol}}</span>
                    <span *ngIf="processProtocol != DEFAULT_EMPTY_VALUE">
                        e no Processo E-Docs de protocolo
                        <span class="relevant-info-font text-highlight">{{processProtocol}}</span>
                    </span>
                    foi <b>{{inputData.hasApproved ? 'aprovado' : 'rejeitado'}}</b> por:
                </div>
                <div class="mb-3">
                    <span class="relevant-info-font text-highlight d-inline-block">{{recipient.toUpperCase()}}</span>
                </div>
                <hr />
                <div class="mb-3">
                    A seguinte mensagem foi fornecida:
                </div>
                <div>
                    <i class="document-highlight relevant-info-font">{{inputData.message}}</i>
                </div>

                <ng-container *ngIf="isTaskPublicAgentActor">
                    <hr />
                    <div class="mb-3">
                        Documento comprobatório da realização desta Tarefa:
                    </div>
                    <div class="documents-container">
                        <div class="position-relative my-1" *ngFor="let doc of currentDocuments">
                            <span class="document-highlight" (click)="openDocumentModal(doc.id)">
                                <span class="e-docs relevant-info-font text-highlight" matTooltip="Protocolo E-Docs do documento">{{doc.registro}}</span>
                                <span class="relevant-info-font d-inline-block" matTooltip="Clique para visualizar o documento que foi capturado no E-Docs">
                                    <i class="fas fa-external-link-alt mr-2"></i><u>{{doc.filename}}</u>
                                </span>
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>

            <!-- Task info: Iniciada -->
            <ng-template #pendingTaskTemplate>
                <!-- Task body: visão do requerente -->
                <div class="message-container" *ngIf="!isTaskPublicAgentActor; else pendingTaskPublicAgentActorTemplate">
                    <div class="mb-3">
                        Conteúdo do Encaminhamento E-Docs de protocolo
                        <span class="relevant-info-font text-highlight">{{forwardingProtocol}}</span>
                        <span *ngIf="processProtocol != DEFAULT_EMPTY_VALUE">
                            e do Processo E-Docs de protocolo
                            <span class="relevant-info-font text-highlight">{{processProtocol}}</span>
                        </span>
                        em aprovação por:
                    </div>
                    <div>
                        <span class="relevant-info-font text-highlight d-inline-block">{{recipient.toUpperCase()}}</span>
                    </div>

                    <ng-container *ngIf="!Utils.isNullOrEmpty(flowObjectDefinition?.publicMessageHtml)">
                        <hr />
                        <div class="public-message py-1" [class.loading]="publicMessageHtml == DEFAULT_LOADING_TEXT" [innerHtml]="publicMessageHtml"></div>
                    </ng-container>
                </div>

                <!-- Task footer: visão do executor -->
                <ng-template #pendingTaskPublicAgentActorTemplate>
                    <div class="form-container">
                        <span><span style="color: red">*</span> Parecer sobre o conteúdo acima relacionado:</span>
                        <div class="input-container text-center mt-1 mb-3 px-0">
                            <textarea id="message" placeholder="Entre com a mensagem de aprovação ou recusa" rows="4"
                                      [class.minlength]="isLackingMessage"
                                      [(ngModel)]="inputData.message"
                            ></textarea>
                        </div>
                        <div class="footer">
                            <button type="button" class="btn btn-outline-danger" (click)="submitApproval(false)">Recusar</button>
                            <button type="button" class="btn btn-outline-primary" (click)="submitApproval(true)">Aprovar</button>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
</ng-container>
