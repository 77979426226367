<div class="wrapper p-2">
    <!-- header -->
    <div class="info-header">
        <i class="fas fa-info-circle mr-1"></i>
        <span class="relevant-info-font mr-2">Extensões permitidas:</span>
        <span class="text-highlight relevant-info-font">PDF</span>
    </div>

    <!-- botões para arquivos já adicionados -->
    <ng-container *ngIf="hasValue">
        <!-- body -->
        <div class="file-container p-2 mt-1" [class.disabled]="disabled" *ngFor="let item of (value.fileName || [0]); let idx = index">
            <!-- botão default -->
            <span class="main-button" [class.hidden]="activeButtonArray[idx] != 'main'">
                <label class="has-value m-0 py-1 px-2">
                    <i class="fas fa-folder-open mr-1"></i>
                    <span>Alterar arquivo</span>

                    <ng-container *ngFor="let i of rerenderer">
                        <input type="file" accept="application/pdf" #fileRef
                               [disabled]="disabled"
                               (change)="changeFile($event, idx)"
                        >
                    </ng-container>
                </label>
            </span>

            <!-- botão de cancelamento de upload -->
            <span class="prevent-upload state-button" *ngIf="activeButtonArray[idx] == 'prevent'"
                  (click)="preventUpload(idx)"
            >
                <span class="countdown">
                    <svg><circle r="12" cx="20" cy="20"></circle></svg>
                </span>
                <i class="fas fa-stopwatch mr-2"></i>
                <span>Cancelar upload?</span>
            </span>

            <!-- botão de upload cancelado -->
            <span class="upload-cancelled state-button" *ngIf="activeButtonArray[idx] == 'cancelled'">
                <i class="fas fa-times-circle mr-1"></i>
                <span>Upload cancelado!</span>
            </span>

            <!-- botão de upload em andamento -->
            <span class="upload-progress state-button" *ngIf="activeButtonArray[idx] == 'progress'">
                <i class="fas fa-hourglass-start mr-2" *ngIf="getProgressState(idx) == 'start'"></i>
                <i class="fas fa-hourglass-half mr-2" *ngIf="getProgressState(idx) == 'half'"></i>
                <i class="fas fa-hourglass-end mr-2" *ngIf="getProgressState(idx) == 'end'"></i>
                <span>Realizando upload...</span>
            </span>

            <!-- botão de upload concluído -->
            <span class="upload-complete state-button" *ngIf="activeButtonArray[idx] == 'done'">
                <i class="fas fa-check-circle mr-2"></i>
                <span>Upload concluído!</span>
            </span>

            <!-- container do nome do arquivo -->
            <span class="file-name-container">
                <!-- Nome -->
                <span class="relevant-info-font">{{fileNames[idx]}}</span>

                <!-- Remover arquivo -->
                <i class="action remove-file fas fa-times-circle" *ngIf="!disabled"
                   matTooltip="Remover arquivo"
                   (click)="removeFile(idx)"
                ></i>

                <!-- Visualizar arquivo -->
                <i class="action view-file fas fa-eye" *ngIf="!disabled"
                   matTooltip="Visualizar arquivo"
                   (click)="viewFile(idx)"
                ></i>
            </span>
        </div>
    </ng-container>

    <!-- botão para novos arquivos -->
    <div class="file-container p-2 mt-1" [class.disabled]="disabled" *ngIf="candAddMoreFiles()">
        <!-- botão default -->
        <span class="main-button" [class.hidden]="activeButtonCurrent != 'main'">
            <label class="m-0 py-1 px-2">
                <i class="fas fa-folder-open mr-1"></i>
                <span>Escolher arquivo</span>

                <ng-container *ngFor="let i of rerenderer">
                    <input type="file" accept="application/pdf" #anchorFileRef
                           [disabled]="disabled"
                           (change)="changeFile($event)"
                    >
                </ng-container>
            </label>
        </span>

        <!-- botão de cancelamento de upload -->
        <span class="prevent-upload state-button" *ngIf="activeButtonCurrent == 'prevent'"
              (click)="preventUpload()"
        >
            <span class="countdown">
                <svg><circle r="12" cx="20" cy="20"></circle></svg>
            </span>
            <i class="fas fa-stopwatch mr-2"></i>
            <span>Cancelar upload?</span>
        </span>

        <!-- botão de upload cancelado -->
        <span class="upload-cancelled state-button" *ngIf="activeButtonCurrent == 'cancelled'">
            <i class="fas fa-times-circle mr-1"></i>
            <span>Upload cancelado!</span>
        </span>

        <!-- botão de upload em andamento -->
        <span class="upload-progress state-button" *ngIf="activeButtonCurrent == 'progress'">
            <i class="fas fa-hourglass-start mr-2" *ngIf="getProgressState() == 'start'"></i>
            <i class="fas fa-hourglass-half mr-2" *ngIf="getProgressState() == 'half'"></i>
            <i class="fas fa-hourglass-end mr-2" *ngIf="getProgressState() == 'end'"></i>
            <span>Realizando upload...</span>
        </span>

        <!-- botão de upload concluído -->
        <span class="upload-complete state-button" *ngIf="activeButtonCurrent == 'done'">
            <i class="fas fa-check-circle mr-2"></i>
            <span>Upload concluído!</span>
        </span>

        <!-- container do nome do arquivo -->
        <span class="file-name-container">
            <span class="relevant-info-font font-italic">Nenhum arquivo selecionado</span>
        </span>
    </div>
</div>
