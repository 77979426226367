import { FlowObjectDefinition, FlowObjectInstance, FlowObjectInstanceActor } from "./flow-object.model";
import { Guid } from "guid-typescript";
import { Enums } from '../shared/enums';

// #region [FlowDefinition]
export class FlowDefinition {
    id?: string = Guid.create().toString();
    name?: string = Enums.Messages.MyNewFlow;
    publicName?: string;
    publishedName?: string;
    description?: string;
    publishedDescription?: string;
    configSchema?: string;
    version?: number = 1;
    isActive?: boolean = true;
    isPublished?: boolean = false;
    isPinned?: boolean = false;
    ownerId?: string;
    ownerInfo?: string = null;
    unitId?: string;
    unitInfo?: string = null;
    publishedUnitId?: string = null;
    publishedUnitInfo?: string = null;
    organizationId?: string;
    organizationInfo?: string = null;
    publishedOrganizationId?: string = null;
    publishedOrganizationInfo?: string = null;
    patriarchId?: string;
    patriarchInfo?: string = null;
    publishedPatriarchId?: string = null;
    publishedPatriarchInfo?: string = null;
    targetId?: number = FlowTarget.Citizen;
    publishedTargetId?: number = null;
    contextId?: string = null;
    catalogServiceId?: string = null;
    contextIndex?: number = null;
    createDate?: Date;
    updateDate?: Date;
    publishDate?: Date;
    versionDate?: Date;
    activateDate?: Date;
    deactivateDate?: Date;
    pinDate?: Date;
    flowObjectDefinitions?: FlowObjectDefinition[] = [];

    public constructor(init?: Partial<FlowDefinition>) {
        Object.assign(this, init);
    }
}

export class FlowDefinitionContext {
    id?: string = Guid.createEmpty().toString();
    catalogServiceId?: string = null;
    name?: string = null;
    flowDefinitions?: FlowDefinition[] = [];

    public constructor(init?: Partial<FlowDefinitionContext>) {
        Object.assign(this, init);
    }
}

export class FlowDefinitionCounters {
    citizen?: number = 0;
    unit?: number = 0;
    organization?: number = 0;
    patriarch?: number = 0;
}

export enum FlowTarget {
    Unit = 0,
    Organization = 1,
    Patriarch = 2,
    Citizen = 3
}

export const FlowTargetLabel = new Map<number, string>([
    [FlowTarget.Unit, 'Setor'],
    [FlowTarget.Organization, 'Órgão'],
    [FlowTarget.Patriarch, 'Patriarca'],
    [FlowTarget.Citizen, 'Cidadão']
]);

export const FlowTargetDescription = new Map<number, string>([
    [FlowTarget.Unit, 'Apenas servidores lotados no Setor relacionado à Caixa de Fluxos onde este Fluxo se encontra'],
    [FlowTarget.Organization, 'Apenas servidores lotados no Órgão relacionado à Caixa de Fluxos onde este Fluxo se encontra'],
    [FlowTarget.Patriarch, 'Apenas servidores lotados no Patriarca relacionado à Caixa de Fluxos onde este Fluxo se encontra'],
    [FlowTarget.Citizen, 'Qualquer cidadão']
]);
// #endregion

// #region [FlowInstance]
export class FlowInstance {
    id?: string;
    name?: string;
    version?: number;
    isSoftDeleted?: boolean = false;
    flowDefinitionId?: string;
    ownerId?: string;
    ownerInfo?: string;
    unitId?: string;
    unitInfo?: string;
    organizationId?: string;
    organizationInfo?: string;
    patriarchId?: string;
    patriarchInfo?: string;
    catalogServiceId?: string = null;
    stateId?: FlowInstanceState;
    targetId?: FlowTarget;
    createDate?: Date;
    updateDate?: Date;
    finishDate?: Date;
    flowDefinition?: FlowDefinition;
    flowObjectInstances?: FlowObjectInstance[] = [];
    flowObjectInstanceActors?: FlowObjectInstanceActor[] = [];

    public constructor(init?: Partial<FlowInstance>) {
        Object.assign(this, init);
    }
}

export enum FlowInstanceState {
    NotStarted = 10,
    Started = 20,
    AwaitingAction = 21,
    Finished = 30,
    ManuallyFinished = 31,
    AutomaticallyCancelled = 32,
    ManuallyCancelled = 33
}

export const FlowInstanceStateDescription = new Map<number, string>([
    [FlowInstanceState.NotStarted, 'Não Iniciado'],
    [FlowInstanceState.Started, 'Iniciado'],
    [FlowInstanceState.AwaitingAction, 'Aguardando Ação'],
    [FlowInstanceState.Finished, 'Encerrado'],
    [FlowInstanceState.ManuallyFinished, 'Encerrado Manualmente'],
    [FlowInstanceState.AutomaticallyCancelled, 'Cancelado Automaticamente'],
    [FlowInstanceState.ManuallyCancelled, 'Cancelado Manualmente']
]);
// #endregion
