import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import {
    Encaminhamento,
    Papel,
    GerarUrl,
    AssinaturaDigitalValida,
    Documento,
    ProcessoAutuador,
    Processo,
    DocumentoValido,
    ProcessoLocalizacao,
    DocumentoSearchRequest,
    DocumentoSearchResponse
} from '../models/edocs.model';

@Injectable({
    providedIn: 'root'
})
export class EDocsService extends BaseService {
    controllerRoute: string = 'EDocs';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getEncaminhamento(id: string): Promise<IBaseModel<Encaminhamento>> {
        return this.httpClient.get<IBaseModel<Encaminhamento>>(`${this.apiBaseUrl}/${this.controllerRoute}/Encaminhamento/${id}`).toPromise();
    }

    public async getDocumento(id: string): Promise<IBaseModel<Documento>> {
        return this.httpClient.get<IBaseModel<Documento>>(`${this.apiBaseUrl}/${this.controllerRoute}/Documento/${id}`).toPromise();
    }

    public async getDocumentoDownloadUrl(id: string): Promise<IBaseModel<string>> {
        return this.httpClient.get<IBaseModel<string>>(`${this.apiBaseUrl}/${this.controllerRoute}/Documento/${id}/DownloadUrl`).toPromise();
    }

    public async getDocumentoPodeUsar(id: string): Promise<IBaseModel<boolean>> {
        return this.httpClient.get<IBaseModel<boolean>>(`${this.apiBaseUrl}/${this.controllerRoute}/Documento/${id}/PodeUsar`).toPromise();
    }

    public async getUsuarioPapeisEncaminhamento(id: string): Promise<IBaseModel<Papel[]>> {
        return this.httpClient.get<IBaseModel<Papel[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/Usuario/Papeis/Encaminhamento/${id}`).toPromise();
    }

    public async getGerarUrl(dataLength: number): Promise<IBaseModel<GerarUrl>> {
        return this.httpClient.get<IBaseModel<GerarUrl>>(`${this.apiBaseUrl}/${this.controllerRoute}/Documento/GerarUrl/${dataLength}`).toPromise();
    }

    public async getProcesso(id: string): Promise<IBaseModel<Processo>> {
        return this.httpClient.get<IBaseModel<Processo>>(`${this.apiBaseUrl}/${this.controllerRoute}/Processo/${id}`).toPromise();
    }

    public async getProcessoAutuador(id: string): Promise<IBaseModel<ProcessoAutuador>> {
        return this.httpClient.get<IBaseModel<ProcessoAutuador>>(`${this.apiBaseUrl}/${this.controllerRoute}/Processo/${id}/Autuador`).toPromise();
    }

    public async getProcessoLocalCustodia(id: string): Promise<IBaseModel<ProcessoLocalizacao>> {
        return this.httpClient.get<IBaseModel<ProcessoLocalizacao>>(`${this.apiBaseUrl}/${this.controllerRoute}/Processo/${id}/LocalCustodia`).toPromise();
    }

    public async postDocumentoSearch(data: DocumentoSearchRequest): Promise<IBaseModel<DocumentoSearchResponse>> {
        return this.httpClient.post<IBaseModel<DocumentoSearchResponse>>(`${this.apiBaseUrl}/${this.controllerRoute}/Documentos/Search`, data).toPromise();
    }

    public async postAssinaturaDigitalValida(identificadorTemporarioArquivoNaNuvem: string): Promise<IBaseModel<AssinaturaDigitalValida>> {
        return this.httpClient.post<IBaseModel<AssinaturaDigitalValida>>(
            `${this.apiBaseUrl}/${this.controllerRoute}/Documento/AssinaturaDigitalValida`,
            { identificadorTemporarioArquivoNaNuvem }
        ).toPromise();
    }

    public async postDocumentoValidoNatoDigitalCopiaServidor(identificadorTemporarioArquivoNaNuvem: string): Promise<IBaseModel<DocumentoValido>> {
        return this.httpClient.post<IBaseModel<DocumentoValido>>(
            `${this.apiBaseUrl}/${this.controllerRoute}/Documento/DocumentoValidoNatoDigitalCopiaServidor`,
            { identificadorTemporarioArquivoNaNuvem }
        ).toPromise();
    }

    public async postDocumentoValidoNatoDigitalCopiaCidadao(identificadorTemporarioArquivoNaNuvem: string): Promise<IBaseModel<DocumentoValido>> {
        return this.httpClient.post<IBaseModel<DocumentoValido>>(
            `${this.apiBaseUrl}/${this.controllerRoute}/Documento/DocumentoValidoNatoDigitalCopiaCidadao`,
            { identificadorTemporarioArquivoNaNuvem }
        ).toPromise();
    }

    public async postDocumentoValidoNatoDigitalAutoAssinadoServidor(identificadorTemporarioArquivoNaNuvem: string): Promise<IBaseModel<DocumentoValido>> {
        return this.httpClient.post<IBaseModel<DocumentoValido>>(
            `${this.apiBaseUrl}/${this.controllerRoute}/Documento/DocumentoValidoNatoDigitalAutoAssinadoServidor`,
            { identificadorTemporarioArquivoNaNuvem }
        ).toPromise();
    }

    public async postDocumentoValidoNatoDigitalAutoAssinadoCidadao(identificadorTemporarioArquivoNaNuvem: string): Promise<IBaseModel<DocumentoValido>> {
        return this.httpClient.post<IBaseModel<DocumentoValido>>(
            `${this.apiBaseUrl}/${this.controllerRoute}/Documento/DocumentoValidoNatoDigitalAutoAssinadoCidadao`,
            { identificadorTemporarioArquivoNaNuvem }
        ).toPromise();
    }

    public async postDocumentoValidoDigitalizadoServidor(identificadorTemporarioArquivoNaNuvem: string): Promise<IBaseModel<DocumentoValido>> {
        return this.httpClient.post<IBaseModel<DocumentoValido>>(
            `${this.apiBaseUrl}/${this.controllerRoute}/Documento/DocumentoValidoDigitalizadoServidor`,
            { identificadorTemporarioArquivoNaNuvem }
        ).toPromise();
    }

    public async postDocumentoValidoDigitalizadoCidadao(identificadorTemporarioArquivoNaNuvem: string): Promise<IBaseModel<DocumentoValido>> {
        return this.httpClient.post<IBaseModel<DocumentoValido>>(
            `${this.apiBaseUrl}/${this.controllerRoute}/Documento/DocumentoValidoDigitalizadoCidadao`,
            { identificadorTemporarioArquivoNaNuvem }
        ).toPromise();
    }
}
