import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormioCustomComponent } from '@formio/angular';

@Component({
    selector: 'date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [
        {
            provide: NGX_MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: "DD/MM/YYYY"
                },
                display: {
                    dateInput: "DD/MM/YYYY",
                    monthYearLabel: "MMM YYYY",
                    dateA11yLabel: "LLL",
                    monthYearA11yLabel: "MMMM YYYY"
                }
            }
        }
    ]
})
export class DatePickerComponent implements FormioCustomComponent<any> {
    @Input() disabled: boolean;
    @Input() value: any = null;
    @Output() valueChange = new EventEmitter<any>();

    constructor() { }

    // ======================
    // lifecycle methods
    // ======================

    // ======================
    // public methods
    // ======================

    onChange() {
        this.value = this.value.format('YYYY-MM-DD');
        this.valueChange.emit(this.value);
    }

    clear() {
        this.value = null;
        this.valueChange.emit(this.value);
    }

    // ======================
    // private methods
    // ======================
}
