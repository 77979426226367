import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { AgentePublico, AgentePublicoPapel, CidadaoEmail } from '../models/acesso-cidadao.model';

@Injectable({
    providedIn: 'root'
})
export class AcessoCidadaoService extends BaseService {
    controllerRoute: string = 'AcessoCidadao';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getPapel(id: string): Promise<IBaseModel<AgentePublicoPapel>> {
        return this.httpClient.get<IBaseModel<AgentePublicoPapel>>(`${this.apiBaseUrl}/${this.controllerRoute}/Papel/${id}`).toPromise();
    }

    public async getCidadaoEmail(id: string): Promise<IBaseModel<CidadaoEmail>> {
        return this.httpClient.get<IBaseModel<CidadaoEmail>>(`${this.apiBaseUrl}/${this.controllerRoute}/Cidadao/${id}/Email`).toPromise();
    }

    public async getConjuntoAgentesPublicos(id: string): Promise<IBaseModel<AgentePublico[]>> {
        return this.httpClient.get<IBaseModel<AgentePublico[]>>(`${this.apiBaseUrl}/${this.controllerRoute}/Conjunto/${id}/AgentesPublicos`).toPromise();
    }

    public async getConjuntoGestor(id: string): Promise<IBaseModel<AgentePublico>> {
        return this.httpClient.get<IBaseModel<AgentePublico>>(`${this.apiBaseUrl}/${this.controllerRoute}/Conjunto/${id}/Gestor`).toPromise();
    }

    public async getCidadaoExiste(cpf: string): Promise<IBaseModel<void>> {
        return this.httpClient.get<IBaseModel<void>>(`${this.apiBaseUrl}/${this.controllerRoute}/Cidadao/${cpf}`).toPromise();
    }

    public async getCidadaoSub(cpf: string): Promise<IBaseModel<AgentePublico>> {
        return this.httpClient.get<IBaseModel<AgentePublico>>(`${this.apiBaseUrl}/${this.controllerRoute}/Cidadao/${cpf}/Sub`).toPromise();
    }

    public async getRestritoCidadao(sub: string): Promise<IBaseModel<AgentePublico>> {
        return this.httpClient.get<IBaseModel<AgentePublico>>(`${this.apiBaseUrl}/${this.controllerRoute}/Restrito/Cidadao/${sub}`).toPromise();
    }
}
