<div class="card shadow">
    <div class="card-body">
        <div class="body">
            <h2 class="counter m-0 py-1">{{inputCounter}}</h2>
            <h6 class="counter-label m-0 px-1">{{inputCounterLabel}}</h6>
        </div>
        <div class="footer mt-3">
            <div class="actions">
                <button type="button" class="btn btn-outline-primary" (click)="goTo()">
                    <i class="far fa-arrow-alt-circle-right mr-2"></i>
                    <span>Acessar</span>
                </button>
            </div>
        </div>
    </div>
</div>
