<div class="page-container" *ngIf="authService.user != null">
    <div class="working-area p-4">
        <h1 class="page-title m-0">
            <i class="fas fa-home mr-2"></i>
            <span>Olá, {{getUserName()}}</span>
        </h1>

        <p class="page-subtitle m-0 pl-4 pt-3 pr-2 pb-2">
            <i class="fas fa-level-up-alt mr-2"></i>
            Inicie um novo Fluxo dentre os disponíveis ou acompanhe a situação de um Fluxo iniciado com o qual você esteja envolvido.
        </p>

        <hr />

        <div class="section mt-5 p-4">
            <h5 class="section-title mb-4">
                <i class="fas fa-folder-open mr-2"></i>
                <span>Meus Fluxos</span>
            </h5>
            <div class="cards-container">
                <home-item inputCounterLabel="Total"
                            [inputCounter]="flowInstanceCounters.total"
                            (outputGoToEvent)="goToFlowInstanceList()"
                ></home-item>
                <home-item inputCounterLabel="Em andamento"
                            [inputCounter]="flowInstanceCounters.ongoing"
                            (outputGoToEvent)="goToFlowInstanceList(PredefinedFilter.Ongoing)"
                ></home-item>
                <home-item inputCounterLabel="Iniciados por mim"
                            [inputCounter]="flowInstanceCounters.startedByMe"
                            (outputGoToEvent)="goToFlowInstanceList(PredefinedFilter.StartedByMe)"
                ></home-item>
                <home-item inputCounterLabel="Pendentes para mim"
                            [inputCounter]="flowInstanceCounters.pendingForMe"
                            (outputGoToEvent)="goToFlowInstanceList(PredefinedFilter.PendingForMe)"
                ></home-item>
            </div>
        </div>

        <div class="section mt-5 p-4">
            <h5 class="section-title mb-4">
                <i class="fas fa-play-circle mr-2"></i>
                <span>Iniciar um Fluxo</span>
            </h5>
            <div class="cards-container">
                <home-item inputCounterLabel="Para o Cidadão"
                            [inputCounter]="flowDefinitionCounters.citizen"
                            (outputGoToEvent)="goToFlowDefinitionList(FlowTarget.Citizen)"
                ></home-item>
                <ng-container *ngIf="authService.user.isPublicAgent">
                    <home-item *ngIf="flowDefinitionCounters.unit > 0"
                                inputCounterLabel="Em {{getCounterLabel(authService.user.papeisUnitIds)}}"
                                [inputCounter]="flowDefinitionCounters.unit"
                                (outputGoToEvent)="goToFlowDefinitionList(FlowTarget.Unit)"
                    ></home-item>
                    <home-item *ngIf="flowDefinitionCounters.organization > 0"
                                inputCounterLabel="Em {{getCounterLabel(authService.user.papeisOrganizationIds)}}"
                                [inputCounter]="flowDefinitionCounters.organization"
                                (outputGoToEvent)="goToFlowDefinitionList(FlowTarget.Organization)"
                    ></home-item>
                    <home-item *ngIf="flowDefinitionCounters.patriarch > 0"
                                inputCounterLabel="Em {{getCounterLabel(authService.user.papeisPatriarchIds)}}"
                                [inputCounter]="flowDefinitionCounters.patriarch"
                                (outputGoToEvent)="goToFlowDefinitionList(FlowTarget.Patriarch)"
                    ></home-item>
                </ng-container>
            </div>
        </div>
    </div>
</div>
