<header>
    <div class="logo-section px-3">
        <div class="wrapper">
            <div class="keep-left">
                <a matTooltip="Acessar a página inicial do E-Flow" *ngIf="!isFromConecta; else conectaTemplate"
                   [routerLink]="[Enums.PagesPaths.Home]"
                >
                    <img class="app-logo" alt="E-Flow" src="/assets/img/logo_eflow.svg" />
                </a>
                <ng-template #conectaTemplate>
                    <a href="//conecta.{{conectaEnvironmentSegment}}es.gov.br" target="_blank" matTooltip="Acessar a página inicial do Conecta ES">
                        <img class="app-logo" alt="Conecta ES" src="/assets/img/logo_conecta.svg" />
                    </a>
                </ng-template>
            </div>

            <div class="keep-right">
                <a href="//www.es.gov.br/" target="_blank" matTooltip="Acessar o portal do Governo do Estado do Espírito Santo">
                    <img class="gov-logo" alt="Governo do Estado do Espírito Santo" src="//cdn.es.gov.br/images/logo/governo/brasao/right-white/Brasao_Governo_190.png" />
                </a>
            </div>
        </div>
    </div>

    <nav class="navbar navbar-light {{env}} px-2" [matTooltip]="shouldHideEnvironment() ? '' : 'Ambiente de ' + environment">
        <div class="wrapper">
            <div class="keep-left">
                <div class="show justify-content-start align-self-center mr-1" *ngIf="!isFromConecta">
                    <ul class="navbar-nav flex-grow">
                        <main-menu></main-menu>
                    </ul>
                </div>
                <a class="mini-app-link ml-2" matTooltip="Acessar a página inicial do E-Flow" *ngIf="!isFromConecta; else conectaMiniTemplate"
                   [routerLink]="[Enums.PagesPaths.Home]"
                >
                    <img class="mini-app-logo" alt="E-Flow" src="/assets/img/logo_eflow.svg" />
                </a>
                <ng-template #conectaMiniTemplate>
                    <a class="mini-app-link ml-2" href="//conecta.{{conectaEnvironmentSegment}}es.gov.br" target="_blank" matTooltip="Acessar a página inicial do Conecta ES">
                        <img class="mini-app-logo" alt="Conecta ES" src="/assets/img/logo_conecta.svg" />
                    </a>
                </ng-template>
            </div>

            <div class="keep-center" [class.invisible]="shouldHideEnvironment()">
                <span class="environment">{{environment}}</span>
            </div>

            <div class="keep-right navbar-collapse collapse show">
                <ul class="navbar-nav flex-grow">
                    <user-menu></user-menu>
                    <notifications class="ml-1"></notifications>
                </ul>
            </div>
        </div>
    </nav>
</header>
