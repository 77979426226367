import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ElementRef
} from '@angular/core';
import { PredefinedFilterState } from '../../models/helper-classes.model';
import { Enums } from '../../shared/enums';

@Component({
    selector: 'filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent implements OnInit {
    @Input() filterState: PredefinedFilterState = new PredefinedFilterState();
    @Output() filterStateChange = new EventEmitter<PredefinedFilterState>();

    @Output() outputCloseEvent = new EventEmitter<any>();

    PredefinedFilter: typeof Enums.PredefinedFilter = Enums.PredefinedFilter;
    PredefinedFilterDescription: typeof Enums.PredefinedFilterDescription = Enums.PredefinedFilterDescription;

    constructor(public elementRef: ElementRef) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    toggleOngoingFilter(event) {
        if (this.shouldRelayCheckboxClick(event)) {
            this.filterState.isOngoingFilterOn = !this.filterState.isOngoingFilterOn;
        }

        this.filterState.isFinishedFilterOn = false;
        this.filterState.isCancelledFilterOn = false;
        this.onChange();
    }

    toggleFinishedFilter(event) {
        if (this.shouldRelayCheckboxClick(event)) {
            this.filterState.isFinishedFilterOn = !this.filterState.isFinishedFilterOn;
        }

        this.filterState.isOngoingFilterOn = false;
        this.filterState.isCancelledFilterOn = false;
        this.onChange();
    }

    toggleCancelledFilter(event) {
        if (this.shouldRelayCheckboxClick(event)) {
            this.filterState.isCancelledFilterOn = !this.filterState.isCancelledFilterOn;
        }

        this.filterState.isOngoingFilterOn = false;
        this.filterState.isFinishedFilterOn = false;
        this.onChange();
    }

    toggleStartedByMeFilter(event) {
        if (this.shouldRelayCheckboxClick(event)) {
            this.filterState.isStartedByMeFilterOn = !this.filterState.isStartedByMeFilterOn;
        }

        this.filterState.isNotStartedByMeFilterOn = false;
        this.onChange();
    }

    toggleNotStartedByMeFilter(event) {
        if (this.shouldRelayCheckboxClick(event)) {
            this.filterState.isNotStartedByMeFilterOn = !this.filterState.isNotStartedByMeFilterOn;
        }

        this.filterState.isStartedByMeFilterOn = false;
        this.onChange();
    }

    togglePendingForMeFilter(event) {
        if (this.shouldRelayCheckboxClick(event)) {
            this.filterState.isPendingForMeFilterOn = !this.filterState.isPendingForMeFilterOn;
        }

        this.filterState.isNotPendingForMeFilterOn = false;
        this.onChange();
    }

    toggleNotPendingForMeFilter(event) {
        if (this.shouldRelayCheckboxClick(event)) {
            this.filterState.isNotPendingForMeFilterOn = !this.filterState.isNotPendingForMeFilterOn;
        }

        this.filterState.isPendingForMeFilterOn = false;
        this.onChange();
    }

    clearFilter() {
        this.filterState = new PredefinedFilterState();
        this.onChange();
    }

    isFilterStateDefault() {
        return JSON.stringify(this.filterState) == JSON.stringify(new PredefinedFilterState());
    }

    onChange() {
        this.filterStateChange.emit(this.filterState);
    }

    close() {
        this.outputCloseEvent.emit();
    }

    // ======================
    // private methods
    // ======================

    private shouldRelayCheckboxClick(event: any) {
        return event.target.tagName.toLowerCase() == 'mat-checkbox';
    }
}
