<div class="nav-item dropdown p-0">
    <a class="nav-link" matTooltip="Perfil do Usuário" #headerButtonRef>
        <span class="fa-stack">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-user-alt fa-stack-1x"></i>
        </span>
    </a>
    <div class="dropdown-menu dropdown-menu-right" #dropdownMenuRef>
        <div class="title pt-2" [class.pb-2]="userEmail == null || userEmail == ''">
            <span [class.capitalize]="userName === userName.toUpperCase()">{{userName}}</span>
        </div>
        <div class="subtitle pb-2" *ngIf="userEmail != null && userEmail != ''">
            <span>{{userEmail}}</span>
        </div>
        <hr />
        <div class="entry-row p-2"
             matTooltip="Acessar a página para edição de perfil do usuário no Acesso Cidadão"
             (click)="toProfile()"
        >
            <a class="btn btn-default btn-flat p-0">
                <i class="fas fa-address-card"></i>
                <span>Minha Conta</span>
            </a>
        </div>
        <hr />
        <div class="entry-row p-2"
             matTooltip="Sair do sistema"
             (click)="logout()"
        >
            <a class="btn btn-default btn-flat p-0">
                <i class="fas fa-sign-out-alt"></i>
                <span>Sair</span>
            </a>
        </div>
    </div>
</div>
