import { NgxMatMomentAdapter } from '@angular-material-components/moment-adapter';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomDateAdapter extends NgxMatMomentAdapter {
    setLocale() {
        super.setLocale('pt-BR');
    }

    getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
        let response = super.getDayOfWeekNames(style);

        if (style == 'narrow') {
            response = super.getDayOfWeekNames('short');
        }

        return response;
    }
}
