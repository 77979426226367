import { AbstractControl } from '@angular/forms';
import { isObservable } from 'rxjs';
import { FlowDefinition, FlowInstance } from '../models/flow.model';
import { FlowObjectDefinition, FlowObjectInstance, FlowObjectInstanceActor } from '../models/flow-object.model';
import { Const } from './const';
import { CookieService } from 'ngx-cookie-service';
import { Enums } from './enums';
import { EDocsService } from '../services/edocs.service';
import { ToastrService } from 'ngx-toastr';
import { DocumentLegalValueCombination } from '../models/edocs.model';

export const Utils = {
    getToastrErrorOptions(isQuick?: boolean, isLong?: boolean): any {
        return {
            closeButton: true,
            //disableTimeOut: true,
            timeOut: isQuick ? 5000 : (isLong ? 300000 : 60000),
            extendedTimeOut: isQuick ? 5000 : (isLong ? 300000 : 60000)
        };
    },

    scrollTo(id: string) {
        let interval = setInterval(() => {
            let elem = document.getElementById(id);
            if (elem.scrollHeight == 0) return;

            elem.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });

            clearInterval(interval);
        }, 300);
    },

    scrollToTop(selector?: string) {
        setTimeout(() => document.querySelector(selector || '.container > .content-wrapper').scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        }), 400);
    },

    scrollToBottom(selector?: string) {
        setTimeout(() => document.querySelector(selector || '.container > .content-wrapper').scroll({
            top: window.innerHeight,
            left: 0,
            behavior: 'smooth'
        }), 400);
    },

    getPublicMessageHtml(
        cookieService: CookieService,
        flowObjectDefinition: FlowObjectDefinition,
        forwardingProtocol: string,
        processProtocol: string
    ): string {
        const environment = atob(cookieService.get('prodest-eflow-env')).toLowerCase();
        const redirectsToLearning = ['tre', 'hom', 'des', 'loc'];
        const redirectsToStaging = [];
        const resolvedSubdomain = `${redirectsToLearning.includes(environment) ? 'treinamento.' : ''}e-docs.${redirectsToStaging.includes(environment) ? 'hom.' : ''}`;

        return flowObjectDefinition.publicMessageHtml?.replaceAll(Const.TagPublicMessageForwardingProtocol, forwardingProtocol)
            .replaceAll(Const.TagPublicMessageForwardingLink, `https://${resolvedSubdomain}es.gov.br/e/${forwardingProtocol}`)
            .replaceAll(Const.TagPublicMessageProcessProtocol, processProtocol)
            .replaceAll(Const.TagPublicMessageProcessLink, `https://${resolvedSubdomain}es.gov.br/p/${processProtocol}`);
    },

    maskCnpj(cnpj: string): string {
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    },

    isObject(x: any): boolean {
        return x != null && typeof x === 'object';
    },

    isFunction(value: any): boolean {
        return typeof value === 'function';
    },

    isString(x: any): boolean {
        return x != null && typeof x === 'string';
    },

    isNullOrEmpty(value: any): boolean {
        return value == null || value.toString().trim() === '';
    },

    isStringEqual(str1: string, str2: string): boolean {
        return str1 != null
            && str2 != null
            && str1.toLowerCase().trim() === str2.toLowerCase().trim();
    },

    clone(value: any): any {
        if (!this.isObject(value) || isObservable(value) || value.changingThisBreaksApplicationSecurity || ['RegExp', 'FileList', 'File', 'Blob'].includes(value.constructor.name)) {
            return value;
        }

        if (value._isAMomentObject && this.isFunction(value.clone)) {
            return value.clone();
        }

        if (value instanceof AbstractControl) {
            return null;
        }

        if (value instanceof Date) {
            return new Date(value.getTime());
        }

        if (Array.isArray(value)) {
            //return value.slice(0).map(v => this.clone(v));
            return null;
        }

        const proto = Object.getPrototypeOf(value);
        let c = Object.create(proto);
        c = Object.setPrototypeOf(c, proto);

        return Object.keys(value).reduce((newVal, prop) => {
            const propDesc = Object.getOwnPropertyDescriptor(value, prop);
            if (propDesc.get) {
                Object.defineProperty(newVal, prop, propDesc);
            } else {
                newVal[prop] = this.clone(value[prop]);
            }

            return newVal;
        }, c);
    },

    flowAreaReady(callback) {
        if (
            document.querySelector('.selected-flow-objects > .msg.empty') == null
            && document.querySelector('.selected-flow-objects .selected-flow-object') == null
        ) {
            setTimeout(() => this.flowAreaReady(callback), 300);
        } else {
            setTimeout(callback, 1);
        }
    },

    jsonSyntaxHighlight(json: string, keepHtmlTags?: boolean): string {
        if (!keepHtmlTags) {
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        }

        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, match => {
            let cssClass = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cssClass = 'key';
                } else {
                    cssClass = 'string';
                }
            } else if (/true|false/.test(match)) {
                cssClass = 'boolean';
            } else if (/null/.test(match)) {
                cssClass = 'null';
            }
            return `<span class="${cssClass}">${match}</span>`;
        });
    },

    async canUseEDocsDocument(
        protocol: string,
        eDocsService: EDocsService,
        toastr: ToastrService
    ): Promise<{ id: string, canUse: boolean, hasErrors: boolean }> {
        if (Utils.isNullOrEmpty(protocol)) {
            return {
                id: null,
                canUse: false,
                hasErrors: true
            };
        }

        let response_Search = await eDocsService.postDocumentoSearch({ registros: [protocol] });

        if (!response_Search?.isSuccess) {
            toastr.error(`"${protocol}": ${response_Search.message.description}`, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return {
                id: null,
                canUse: false,
                hasErrors: true
            };
        }

        if (response_Search.data.result.length == 0) {
            toastr.warning(Enums.Messages.EDocsProtocolNotFound.replace('{0}', protocol), Enums.Messages.Warning, Utils.getToastrErrorOptions());
            return {
                id: null,
                canUse: false,
                hasErrors: true
            };
        }

        let response_PodeUsar = await eDocsService.getDocumentoPodeUsar(response_Search.data.result[0].id);

        if (!response_PodeUsar?.isSuccess) {
            toastr.error(`"${protocol}": ${response_PodeUsar.message.description}`, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return {
                id: null,
                canUse: false,
                hasErrors: true
            };
        }

        return {
            id: response_Search.data.result[0].id,
            canUse: response_PodeUsar.data,
            hasErrors: false
        };
    },

    async isValidEDocsDocument(
        data: any,
        formSchema: any,
        eDocsService: EDocsService,
        toastr: ToastrService
    ): Promise<boolean> {
        // schema dos componentes do form que necessitam de verificação
        let eDocsDocumentsComponents = formSchema.components.filter(x =>
            x.documentLegalValueCombination != null
            && ![
                DocumentLegalValueCombination.CidadaoDocumentoEletronicoAssinadoDigitalmente,
                DocumentLegalValueCombination.ServidorDocumentoEletronicoAssinadoDigitalmente
            ].includes(x.documentLegalValueCombination?.value)
        );
        let eDocsDocumentsComponentKeys = eDocsDocumentsComponents.map(x => ({
            key: x.key,
            documentLegalValueCombination: x.documentLegalValueCombination.value
        }));

        let isValid = true;

        for (let i = 0; i < eDocsDocumentsComponentKeys.length; i++) {
            let key = eDocsDocumentsComponentKeys[i].key;
            let documentLegalValueCombination = eDocsDocumentsComponentKeys[i].documentLegalValueCombination;
            let item = data[key];

            for (let j = 0; j < item?.fileName.length; j++) {
                // verifica se o documento é capturável no E-Docs
                let response = null;
                switch (documentLegalValueCombination) {
                    case DocumentLegalValueCombination.ServidorDocumentoEletronicoSemAssinatura:
                        response = await eDocsService.postDocumentoValidoNatoDigitalCopiaServidor(item.minioKey[j]);
                        break;

                    case DocumentLegalValueCombination.ServidorDocumentoEletronicoAssinadoEletronicamente:
                        response = await eDocsService.postDocumentoValidoNatoDigitalAutoAssinadoServidor(item.minioKey[j]);
                        break;

                    case DocumentLegalValueCombination.ServidorDocumentoEscaneadoDocumentoOriginal:
                    case DocumentLegalValueCombination.ServidorDocumentoEscaneadoCopiaSimples:
                    case DocumentLegalValueCombination.ServidorDocumentoEscaneadoCopiaAutenticadaCartorio:
                    case DocumentLegalValueCombination.ServidorDocumentoEscaneadoCopiaAutenticadaAdministrativamente:
                        response = await eDocsService.postDocumentoValidoDigitalizadoServidor(item.minioKey[j]);
                        break;

                    case DocumentLegalValueCombination.CidadaoDocumentoEletronicoSemAssinatura:
                        response = await eDocsService.postDocumentoValidoNatoDigitalCopiaCidadao(item.minioKey[j]);
                        break;

                    case DocumentLegalValueCombination.CidadaoDocumentoEletronicoAssinadoEletronicamente:
                        response = await eDocsService.postDocumentoValidoNatoDigitalAutoAssinadoCidadao(item.minioKey[j]);
                        break;

                    case DocumentLegalValueCombination.CidadaoDocumentoEscaneadoCopiaSimples:
                        response = await eDocsService.postDocumentoValidoDigitalizadoCidadao(item.minioKey[j]);
                        break;

                    default:
                        break;
                }

                if (!response?.isSuccess) {
                    toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return false;
                }

                // trata erros na validação dos documentos
                if (!response.data.isSuccess) {
                    let label = eDocsDocumentsComponents.find(x => x.key == key).label;
                    let index = item?.fileName.length > 1 ? ` (item ${j + 1})` : '';
                    toastr.warning(
                        Enums.Messages.InvalidEDocsDocument.replace('{0}', label + index).replace('{1}', response.data.message),
                        Enums.Messages.Pendency,
                        Utils.getToastrErrorOptions(false, true)
                    );
                    isValid = false;
                }
            }
        }

        return isValid;
    },

    async isValidIcpBrasil(
        data: any,
        formSchema: any,
        eDocsService: EDocsService,
        toastr: ToastrService
    ): Promise<boolean> {
        // schema dos componentes do form que necessitam de assinatura ICP-Brasil
        let icpBrasilComponents = formSchema.components.filter(x =>
            [
                DocumentLegalValueCombination.CidadaoDocumentoEletronicoAssinadoDigitalmente,
                DocumentLegalValueCombination.ServidorDocumentoEletronicoAssinadoDigitalmente
            ].includes(x.documentLegalValueCombination?.value)
        );
        let icpBrasilComponentKeys = icpBrasilComponents.map(x => x.key);

        let isValid = true;

        for (let i = 0; i < icpBrasilComponentKeys.length; i++) {
            let key = icpBrasilComponentKeys[i];
            let item = data[key];

            for (let j = 0; j < item?.fileName.length; j++) {
                // verifica se assinatura digital é válida
                const response = await eDocsService.postAssinaturaDigitalValida(item.minioKey[j]);

                if (!response.isSuccess) {
                    toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return false;
                }

                // trata erros na validação dos documentos
                if (!response.data.isSuccess) {
                    let label = icpBrasilComponents.find(x => x.key == key).label;
                    let index = item?.fileName.length > 1 ? ` (item ${j + 1})` : '';
                    toastr.warning(
                        Enums.Messages.InvalidIcpBrasil.replace('{0}', label + index),
                        Enums.Messages.Pendency,
                        Utils.getToastrErrorOptions(false, true)
                    );
                    isValid = false;
                }
            }
        }

        return isValid;
    },

    retypeCustomComponents(components: any[]) : any[] {
        components.filter(x => x.type == 'landlineNumber').forEach(x => x.type = 'phoneNumber');
        components.filter(x => x.type == 'cellNumber').forEach(x => x.type = 'phoneNumber');
        components.filter(x => x.type == 'cpf').forEach(x => x.type = 'textfield');
        components.filter(x => x.type == 'cnpj').forEach(x => x.type = 'textfield');
        components.filter(x => x.type == 'eDocsProtocol').forEach(x => x.type = 'textfield');
        components.filter(x => x.type == 'selectRepository').forEach(x => x.type = 'select');
        components.filter(x => x.type == 'selectFiltered').forEach(x => x.type = 'select');
        components.filter(x => x.type == 'tableCustom').forEach(x => x.type = 'table');

        return components;
    },

    decycleFlowDefinition(data: FlowDefinition): FlowDefinition {
        let dataClone = this.clone(data) as FlowDefinition;
        dataClone.flowObjectDefinitions = [];

        data.flowObjectDefinitions.forEach(flowObject => {
            let flowObjectClone = this.clone(flowObject) as FlowObjectDefinition;
            flowObjectClone.next = [];

            flowObject.next.forEach(next => {
                let nextClone = this.clone(next) as FlowObjectDefinition;
                flowObjectClone.next.push(nextClone);
            });

            dataClone.flowObjectDefinitions.push(flowObjectClone);
        });

        return dataClone;
    },

    decycleFlowInstance(data: FlowInstance): FlowInstance {
        let dataClone = this.clone(data) as FlowInstance;
        dataClone.flowObjectInstances = [];
        dataClone.flowObjectInstanceActors = [];

        data.flowObjectInstances.forEach(flowObject => {
            let flowObjectClone = this.clone(flowObject) as FlowObjectInstance;
            flowObjectClone.flowObjectInstanceActors = [];

            flowObject.flowObjectInstanceActors.forEach(actor => {
                let actorClone = this.clone(actor) as FlowObjectInstanceActor;
                flowObjectClone.flowObjectInstanceActors.push(actorClone);
            });

            dataClone.flowObjectInstances.push(flowObjectClone);
        });

        return dataClone;
    },

    decycleFlowObjectInstance(data: FlowObjectInstance): FlowObjectInstance {
        let dataClone = this.clone(data) as FlowObjectInstance;
        dataClone.flowObjectInstanceActors = [];

        data.flowObjectInstanceActors.forEach(actor => {
            let actorClone = this.clone(actor) as FlowObjectInstanceActor;
            dataClone.flowObjectInstanceActors.push(actorClone);
        });

        return dataClone;
    }
};
