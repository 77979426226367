import { Enums } from "../shared/enums";

export class Organizacao {
    guid?: string;
    razaoSocial?: string;
    nomeFantasia?: string;
    sigla?: string;
    organizacaoPai?: Organizacao;

    public constructor(init?: Partial<Organizacao>) {
        Object.assign(this, init);
    }
}

export class Unidade {
    guid?: string;
    nome?: string;
    sigla?: string;
    nomeCurto?: string;
    organizacao?: Organizacao;

    public constructor(init?: Partial<Unidade>) {
        Object.assign(this, init);
    }
}

export class Localizacao {
    id?: string;
    sigla?: string;
    nome?: string;
    paiSigla?: string;
    grupoTipoNome?: string;
    tipo?: Enums.LocationType;

    public constructor(init?: Partial<Localizacao>) {
        Object.assign(this, init);
    }
}
