import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { Localizacao, Organizacao, Unidade } from '../models/organograma.model';

@Injectable({
    providedIn: 'root'
})
export class OrganogramaService extends BaseService {
    controllerRoute: string = 'Organograma';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getOrganizacao(id: string): Promise<IBaseModel<Organizacao>> {
        return this.httpClient.get<IBaseModel<Organizacao>>(`${this.apiBaseUrl}/${this.controllerRoute}/Organizacao/${id}`).toPromise();
    }

    public async getUnidade(id: string): Promise<IBaseModel<Unidade>> {
        return this.httpClient.get<IBaseModel<Unidade>>(`${this.apiBaseUrl}/${this.controllerRoute}/Unidade/${id}`).toPromise();
    }

    public async getLocationInfo(id: string): Promise<IBaseModel<Localizacao>> {
        return this.httpClient.get<IBaseModel<Localizacao>>(`${this.apiBaseUrl}/${this.controllerRoute}/LocationInfo/${id}`).toPromise();
    }
}
