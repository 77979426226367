import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Enums } from '../../../shared/enums';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    Enums = Enums;

    isFromConecta: boolean = false;

    constructor(private cookieService: CookieService) {
        if (atob(this.cookieService.get('prodest-eflow-cnc')).toLowerCase() == 'true') {
            this.isFromConecta = window.location.pathname.includes(Enums.PagesPaths.CatalogService);
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() { }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
