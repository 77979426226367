<div class="page-container">
    <div class="working-area p-4">
        <!-- Header -->
        <h1 class="page-title m-0">
            <i class="fas fa-play-circle mr-2"></i>
            <span>Iniciar um Fluxo</span>
        </h1>

        <!-- Subtitle -->
        <p class="page-subtitle m-0 pl-4 pt-3 pr-2 pb-2">
            <i class="fas fa-level-up-alt mr-2"></i>
            Escolha, a seguir, um dos Fluxos disponíveis para instanciação no momento.
        </p>

        <hr />

        <!-- Cidadão -->
        <div id="cit">
            <flow-target-section [inputGeneralState]="generalState"
                                 [inputFlowTarget]="FlowTarget.Citizen"
                                 (outputSetCookiesEvent)="setCookies($event, FlowTarget.Citizen)"
            ></flow-target-section>
        </div>

        <!-- Setor -->
        <div id="unt">
            <flow-target-section *ngFor="let unitId of papeisUnitIdsArray"
                                 [inputGeneralState]="generalState"
                                 [inputFlowTarget]="FlowTarget.Unit"
                                 [inputLocationId]="unitId"
                                 (outputSetCookiesEvent)="setCookies($event, FlowTarget.Unit)"
            ></flow-target-section>
        </div>

        <!-- Órgão -->
        <div id="org">
            <flow-target-section *ngFor="let organizationId of papeisOrganizationIdsArray"
                                 [inputGeneralState]="generalState"
                                 [inputFlowTarget]="FlowTarget.Organization"
                                 [inputLocationId]="organizationId"
                                 (outputSetCookiesEvent)="setCookies($event, FlowTarget.Organization)"
            ></flow-target-section>
        </div>

        <!-- Patriarca -->
        <div id="pat">
            <flow-target-section *ngFor="let patriarchId of papeisPatriarchIdsArray"
                                 [inputGeneralState]="generalState"
                                 [inputFlowTarget]="FlowTarget.Patriarch"
                                 [inputLocationId]="patriarchId"
                                 (outputSetCookiesEvent)="setCookies($event, FlowTarget.Patriarch)"
            ></flow-target-section>
        </div>
    </div>
</div>
