import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';
import { NotificacoesGetResponse, NotificacoesTotais } from '../models/notificacoes.model';
import { NotificacoesServiceParams } from './interfaces/notificacoes.interface';

@Injectable({
    providedIn: 'root'
})
export class NotificacoesService extends BaseService {
    controllerRoute: string = 'Notificacoes';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async get(params: NotificacoesServiceParams): Promise<IBaseModel<NotificacoesGetResponse>> {
        let httpParams = new HttpParams();

        httpParams = httpParams.append('usuarioGuid', params.usuarioGuid);
        if (params?.categoriaId != null) httpParams = httpParams.append('categoriaId', params.categoriaId.toString());
        if (params?.page != null) httpParams = httpParams.append('page', params.page.toString());
        if (params?.pageSize != null) httpParams = httpParams.append('pageSize', params.pageSize.toString());

        return this.httpClient.get<IBaseModel<NotificacoesGetResponse>>(`${this.apiBaseUrl}/${this.controllerRoute}`, { params: httpParams }).toPromise();
    }

    public async getTotais(id: string): Promise<IBaseModel<NotificacoesTotais>> {
        return this.httpClient.get<IBaseModel<NotificacoesTotais>>(`${this.apiBaseUrl}/${this.controllerRoute}/Totais/${id}`).toPromise();
    }

    public async postMarcarLida(id: number, params: NotificacoesServiceParams): Promise<IBaseModel<NotificacoesTotais>> {
        return this.httpClient.post<IBaseModel<NotificacoesTotais>>(`${this.apiBaseUrl}/${this.controllerRoute}/MarcarLida/${id}`, params).toPromise();
    }

    public async postMarcarNaoLida(id: number, params: NotificacoesServiceParams): Promise<IBaseModel<NotificacoesTotais>> {
        return this.httpClient.post<IBaseModel<NotificacoesTotais>>(`${this.apiBaseUrl}/${this.controllerRoute}/MarcarNaoLida/${id}`, params).toPromise();
    }

    public async postMarcarTodasLidas(params: NotificacoesServiceParams): Promise<IBaseModel<NotificacoesTotais>> {
        return this.httpClient.post<IBaseModel<NotificacoesTotais>>(`${this.apiBaseUrl}/${this.controllerRoute}/MarcarTodasLidas`, params).toPromise();
    }

    public async postMarcarTodasNaoLidas(params: NotificacoesServiceParams): Promise<IBaseModel<NotificacoesTotais>> {
        return this.httpClient.post<IBaseModel<NotificacoesTotais>>(`${this.apiBaseUrl}/${this.controllerRoute}/MarcarTodasNaoLidas`, params).toPromise();
    }
}
