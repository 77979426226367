import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    refCode: string;

    constructor() {
        this.refCode = btoa((new Date()).toISOString());
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit(): void { }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
