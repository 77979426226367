import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowObjectDefinition } from '../../models/flow-object.model';
import { BaseComponent } from '../../components/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../shared/enums';
import { FlowInstanceService } from '../../services/flow-instance.service';
import { AuthService } from '../../services/auth.service';
import { Utils } from '../../shared/utils';
import { IBaseOption } from '../../models/base.model';
import { MatCheckbox } from '@angular/material/checkbox';
import { InputDataTaskForm } from '../../models/input-output-data.model';

@Component({
    selector: 'tests',
    templateUrl: './tests.component.html',
    styleUrls: ['./tests.component.scss']
})
export class TestsComponent extends BaseComponent implements OnInit {
    @ViewChild('configCardRef') configCardRef: ElementRef;
    @ViewChild('testContainerRef') testContainerRef: ElementRef;
    @ViewChild('testResultsContainerRef') testResultsContainerRef: ElementRef;
    @ViewChild('defaultDesRef') defaultDesRef: MatCheckbox;
    @ViewChild('defaultHomRef') defaultHomRef: MatCheckbox;
    @ViewChild('defaultTreRef') defaultTreRef: MatCheckbox;

    Math = Math;

    readonly defaultDesFDId: string = 'f4bd1081-17a4-133f-18f4-7b74de496bf3';
    readonly defaultDesFODId: string = '860AA06C-7558-1782-F265-B7F31835FA82';

    readonly defaultHomFDId: string = 'bf240b29-5aa6-f0c1-ce9a-1c1cb1b31e6d';
    readonly defaultHomFODId: string = 'F487EBE8-E599-A559-220A-40B36950915F';

    readonly defaultTreFDId: string = 'db4896d3-ed1e-cce0-138a-0c09b5fc64aa';
    readonly defaultTreFODId: string = 'A7451EEE-7DDA-B3F0-1146-265FAD2DD218';

    papelId: string;
    flowDefinitionId: string;
    flowObjectDefinitionId: string;
    formData: string;
    reqs: number = 1;
    reqsPerSec: number = 1;
    secs: number = 60;
    finishedTests: number = 0;
    elapsedTime: number = 0;
    loadTestsResults: any[] = [];
    papelOptions: IBaseOption[] = [];
    flowObjectToExecute: FlowObjectDefinition;
    interval: any;
    isBootstrapFinished: boolean = false;
    areTestsRolling: boolean = false;
    isTheTestConcurrent: boolean = null;
    dirSortRoundtrip: boolean = false;
    dirSortSequence: boolean = false;

    constructor(
        route: ActivatedRoute,
        router: Router,
        spinner: NgxSpinnerService,
        toastr: ToastrService,
        private flowInstanceService: FlowInstanceService,
        private authService: AuthService
    ) {
        super(route, router, spinner, toastr);

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.papelOptions = Array.from(this.authService.user.papeis, item => {
            return {
                value: item.guid,
                description: `${item.nome} - ${item.lotacao.nomeCurto} - ${item.lotacao.organizacao.sigla}`
            };
        });
        this.papelId = this.authService.user.papeis[0].guid;

        this.formData = JSON.stringify({
            "submit": true
        });

        this.flowDefinitionId = this.defaultDesFDId;
        this.flowObjectDefinitionId = this.defaultDesFODId;

        setTimeout(() => {
            this.isBootstrapFinished = true;
            setTimeout(() => {
                if (this.testContainerRef != null) {
                    (this.testContainerRef.nativeElement as HTMLElement).style.height = '100%';
                }
                this.spinner.hide();
            }, 300);
        }, 300);
    }

    // ======================
    // public methods
    // ======================

    async fireConcurrentReqs() {
        if (this.reqs == null || this.reqs == 0) {
            this.toastr.error('Cadê as requisições?', Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.areTestsRolling = true;
        this.isTheTestConcurrent = true;
        this.loadTestsResults = [];
        this.finishedTests = 0;

        clearInterval(this.interval);
        this.elapsedTime = 0;

        let inputData = ({
            data: JSON.parse(this.formData),
            eDocsData: {
                signerId: this.papelId
            }
        }) as InputDataTaskForm;

        this.flowObjectToExecute = new FlowObjectDefinition({
            id: this.flowObjectDefinitionId,
            flowDefinitionId: this.flowDefinitionId
        });

        let elapsedStart = new Date();
        this.interval = setInterval(() => {
            this.elapsedTime = new Date().getTime() - elapsedStart.getTime();
            if (this.elapsedTime > 0 && this.finishedTests == this.reqs) {
                clearInterval(this.interval);
            }
        }, 100);

        for (let i = 1; i <= this.reqs; i++) {
            let start = new Date();
            this.loadTestsResults.push({
                sequence: i,
                batch: 1,
                start: start.toISOString().split('T')[1],
                finish: null,
                roundtrip: null,
                id: null,
                message: null,
                isSuccess: null
            });

            this.flowInstanceService.create(this.flowObjectToExecute, inputData).then(response => {
                let loadTestResult = this.loadTestsResults.find(x => x.sequence == i);
                let finish = new Date();
                loadTestResult.finish = finish.toISOString().split('T')[1];
                loadTestResult.diff = (finish.getTime() - start.getTime()) / 1000;
                loadTestResult.roundtrip = loadTestResult.diff.toFixed(3) + 's';

                let intRes = setInterval(() => {
                    if (response != null) {
                        loadTestResult.id = response.data?.id;
                        loadTestResult.message = response.message.description;
                        loadTestResult.isSuccess = response.isSuccess;
                        clearInterval(intRes);
                    }
                }, 200);

                this.finishedTests++;

                if (this.finishedTests == this.reqs) {
                    this.areTestsRolling = false;
                    this.isTheTestConcurrent = null;
                }
            });
        }
    }

    async fireScaledReqs() {
        if (this.reqsPerSec == null || this.reqsPerSec == 0) {
            this.toastr.error('Cadê as requisições?', Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        } else if (this.secs == null || this.secs == 0) {
            this.toastr.error('Cadê os segundos?', Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.areTestsRolling = true;
        this.isTheTestConcurrent = false;
        this.loadTestsResults = [];
        this.finishedTests = 0;

        clearInterval(this.interval);
        this.elapsedTime = 0;

        let inputData = ({
            data: JSON.parse(this.formData),
            eDocsData: {
                signerId: this.papelId
            }
        }) as InputDataTaskForm;

        this.flowObjectToExecute = new FlowObjectDefinition({
            id: this.flowObjectDefinitionId,
            flowDefinitionId: this.flowDefinitionId
        });

        let elapsedStart = new Date();
        this.interval = setInterval(() => {
            this.elapsedTime = new Date().getTime() - elapsedStart.getTime();
            if (this.elapsedTime > 0 && this.finishedTests == this.reqsPerSec * this.secs) {
                clearInterval(this.interval);
            }
        }, 100);

        for (let j = 0; j < this.secs; j++) {
            setTimeout(() => {
                for (let i = 1; i <= this.reqsPerSec; i++) {
                    let start = new Date();
                    this.loadTestsResults.push({
                        sequence: (j * this.reqsPerSec) + i,
                        batch: j + 1,
                        start: start.toISOString().split('T')[1],
                        finish: null,
                        roundtrip: null,
                        id: null,
                        message: null,
                        isSuccess: null
                    });

                    this.flowInstanceService.create(this.flowObjectToExecute, inputData).then(response => {
                        let loadTestResult = this.loadTestsResults.find(x => x.sequence == (j * this.reqsPerSec) + i);
                        let finish = new Date();
                        loadTestResult.finish = finish.toISOString().split('T')[1];
                        loadTestResult.diff = (finish.getTime() - start.getTime()) / 1000;
                        loadTestResult.roundtrip = loadTestResult.diff.toFixed(3) + 's';

                        let intRes = setInterval(() => {
                            if (response != null) {
                                loadTestResult.id = response.data?.id;
                                loadTestResult.message = response.message.description;
                                loadTestResult.isSuccess = response.isSuccess;
                                clearInterval(intRes);
                            }
                        }, 200);

                        this.finishedTests++;

                        if (this.finishedTests == this.reqsPerSec * this.secs) {
                            this.areTestsRolling = false;
                            this.isTheTestConcurrent = null;
                        }
                    });
                }
            }, 1000 * j);
        }
    }

    toggleConfig() {
        this.testContainerRef.nativeElement.querySelectorAll('.toggle').forEach(x => x.classList.toggle('off'));
    }

    toggleAll() {
        this.configCardRef.nativeElement.classList.toggle('min');
        this.configCardRef.nativeElement.querySelectorAll('h6 ~ *').forEach(x => x.classList.toggle('off'));
    }

    sortRoundtrip() {
        if (this.loadTestsResults.length == 0) return;

        if (this.dirSortRoundtrip) {
            this.loadTestsResults.sort((a, b) => b.diff - a.diff);
        } else {
            this.loadTestsResults.sort((a, b) => a.diff - b.diff);
        }

        this.testResultsContainerRef.nativeElement.querySelectorAll('.sortable.roundtrip').forEach(x => x.classList.add('on'));
        this.testResultsContainerRef.nativeElement.querySelectorAll('.sortable.sequence').forEach(x => x.classList.remove('on'));

        this.dirSortRoundtrip = !this.dirSortRoundtrip;
    }

    sortSequence() {
        if (this.loadTestsResults.length == 0) return;

        if (this.dirSortSequence) {
            this.loadTestsResults.sort((a, b) => b.sequence - a.sequence);
        } else {
            this.loadTestsResults.sort((a, b) => a.sequence - b.sequence);
        }

        this.testResultsContainerRef.nativeElement.querySelectorAll('.sortable.sequence').forEach(x => x.classList.add('on'));
        this.testResultsContainerRef.nativeElement.querySelectorAll('.sortable.roundtrip').forEach(x => x.classList.remove('on'));

        this.dirSortSequence = !this.dirSortSequence;
    }

    setDefaultDes(event) {
        if (event.checked) {
            this.defaultHomRef.checked = false;
            this.defaultTreRef.checked = false;
            this.flowDefinitionId = this.defaultDesFDId;
            this.flowObjectDefinitionId = this.defaultDesFODId;
        } else {
            this.flowDefinitionId = null;
            this.flowObjectDefinitionId = null;
        }
    }

    setDefaultHom(event) {
        if (event.checked) {
            this.defaultDesRef.checked = false;
            this.defaultTreRef.checked = false;
            this.flowDefinitionId = this.defaultHomFDId;
            this.flowObjectDefinitionId = this.defaultHomFODId;
        } else {
            this.flowDefinitionId = null;
            this.flowObjectDefinitionId = null;
        }
    }

    setDefaultTre(event) {
        if (event.checked) {
            this.defaultDesRef.checked = false;
            this.defaultHomRef.checked = false;
            this.flowDefinitionId = this.defaultTreFDId;
            this.flowObjectDefinitionId = this.defaultTreFODId;
        } else {
            this.flowDefinitionId = null;
            this.flowObjectDefinitionId = null;
        }
    }

    // ======================
    // private methods
    // ======================
}
