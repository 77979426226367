import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/http/auth.guard';

import { HomeComponent } from './pages/home/home.component';
import { FlowDefinitionComponent } from './pages/flow-definition/flow-definition.component';
import { FlowDefinitionListComponent } from './pages/flow-definition-list/flow-definition-list.component';
import { FlowInstanceComponent } from './pages/flow-instance/flow-instance.component';
import { FlowInstanceListComponent } from './pages/flow-instance-list/flow-instance-list.component';
import { ErrorComponent } from './core/error/error.component';
import { TestsComponent } from './pages/tests/tests.component';
import { Enums } from './shared/enums';
import { MjolnirComponent } from './pages/mjolnir/mjolnir.component';

const routes: Routes = [
    {
        path: Enums.PagesPaths.Home.slice(1),
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] } /* array vazio para aceitar todos os perfis do AC */
    },
    {
        path: Enums.PagesPaths.FlowInstanceList.slice(1),
        component: FlowInstanceListComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] } /* array vazio para aceitar todos os perfis do AC */
    },
    {
        path: Enums.PagesPaths.FlowDefinitionList.slice(1),
        component: FlowDefinitionListComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] } /* array vazio para aceitar todos os perfis do AC */
    },
    {
        path: `${Enums.PagesPaths.FlowDefinition.slice(1)}/:id`,
        component: FlowDefinitionComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] } /* array vazio para aceitar todos os perfis do AC */
    },
    {
        path: `${Enums.PagesPaths.FlowDefinition.slice(1)}/${Enums.PagesPaths.CatalogService.slice(1)}/:id`,
        component: FlowDefinitionComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] } /* array vazio para aceitar todos os perfis do AC */
    },
    {
        path: `${Enums.PagesPaths.FlowInstance.slice(1)}/:id`,
        component: FlowInstanceComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] } /* array vazio para aceitar todos os perfis do AC */
    },
    {
        path: `${Enums.PagesPaths.FlowInstance.slice(1)}/${Enums.PagesPaths.CatalogService.slice(1)}/:id`,
        component: FlowInstanceComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] } /* array vazio para aceitar todos os perfis do AC */
    },
    {
        path: Enums.PagesPaths.Error.slice(1),
        component: ErrorComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] } /* array vazio para aceitar todos os perfis do AC */
    },
    {
        path: Enums.PagesPaths.Tests.slice(1),
        component: TestsComponent,
        canActivate: [AuthGuard],
        data: { profiles: [Enums.Profiles.Owner] }
    },
    {
        path: `${Enums.PagesPaths.Mjolnir.slice(1)}/:id`,
        component: MjolnirComponent,
        canActivate: [AuthGuard],
        data: { profiles: [] }
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64],
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
