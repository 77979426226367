import { Component, OnInit, Input } from "@angular/core";
import { ConfigSchema } from "../../../models/config-schema.model";
import { FlowObjectInstance } from "../../../models/flow-object.model";
import { OutputDataTaskGatewayRules } from "../../../models/input-output-data.model";
import { Utils } from "../../../shared/utils";

@Component({
    selector: "flow-object-gateway-rules",
    templateUrl: "./flow-object-gateway-rules.component.html",
    styleUrls: ["./flow-object-gateway-rules.component.scss"]
})

export class FlowObjectGatewayRulesComponent implements OnInit {
    Utils = Utils;

    model: FlowObjectInstance;
    outputData: OutputDataTaskGatewayRules;
    configSchema: ConfigSchema;

    @Input() inputModel: FlowObjectInstance;

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        this.model = this.inputModel;
        this.outputData = JSON.parse(this.model?.outputData) as OutputDataTaskGatewayRules;
        this.configSchema = JSON.parse(this.model.flowObjectDefinition.configSchema) as ConfigSchema;
    }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
