<ng-container *ngIf="model != null">
    <div class="wrapper px-1">
        <div class="public-message py-1" [class.loading]="publicMessageHtml == DEFAULT_LOADING_TEXT"
             *ngIf="!Utils.isNullOrEmpty(flowObjectDefinition?.publicMessageHtml); else defaultMessageTemplate"
             [innerHtml]="publicMessageHtml"
        ></div>

        <ng-template #defaultMessageTemplate>
            <div><span>Fluxo <b>encerrado</b></span></div>
        </ng-template>
    </div>
</ng-container>
