import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    CookieGeneralState,
    PredefinedFilterState,
    SearchToken,
    SortState
} from '../../models/helper-classes.model';
import { FlowInstance } from '../../models/flow.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../shared/enums';
import { FlowInstanceService } from '../../services/flow-instance.service';
import { CookieService } from 'ngx-cookie-service';
import { Utils } from '../../shared/utils';
import { FilterMenuComponent } from '../../components/filter-menu/filter-menu.component';
import { SCSS_VARS } from '../../shared/shared-scss-ts-variables';

@Component({
    selector: 'flow-instance-list',
    templateUrl: './flow-instance-list.component.html',
    styleUrls: ['./flow-instance-list.component.scss']
})
export class FlowInstanceListComponent implements OnInit {
    @ViewChild('filterMenuRef') filterMenuRef: FilterMenuComponent;
    @ViewChild('sortingMenuRef') sortingMenuRef: ElementRef;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.filterMenuRef?.elementRef.nativeElement.firstChild.classList.contains('show')) {
            if (!event.composedPath().includes(this.filterMenuRef.elementRef.nativeElement.firstChild)) {
                this.closeFilterMenu();
            }
        }

        if (this.sortingMenuRef?.nativeElement.classList.contains('show')) {
            if (!event.composedPath().includes(this.sortingMenuRef.nativeElement)) {
                this.closeSortMenu();
            }
        }
    }

    PredefinedFilter: typeof Enums.PredefinedFilter = Enums.PredefinedFilter;
    PredefinedFilterDescription: typeof Enums.PredefinedFilterDescription = Enums.PredefinedFilterDescription;

    window: Window = window;

    flowInstances: FlowInstance[] = [];
    filteredFlowInstances: FlowInstance[] = [];
    searchTokens: SearchToken[] = [];
    customFilterSearch: string = null;
    isBootstrapFinished: boolean = false;
    filterState: PredefinedFilterState = new PredefinedFilterState();
    sortState: SortState = new SortState({ descendingCreateDate: true });;
    currentPage: number = 1;
    currentPageSize: number = 1;
    currentTotalCount: number = 0;
    currentTotalPages: number = 1;

    constructor(
        private router: Router,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private flowInstanceService: FlowInstanceService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.processPageSize();
        await this.getCookies();

        setTimeout(() => {
            this.isBootstrapFinished = true;
        }, 500);
    }

    // ======================
    // public methods
    // ======================

    addFilter() {
        setTimeout(async () => {
            this.processPredefinedFiltersTokens();
            await this.getFilteredResults();
        }, 1);
    }

    async addCustomFilter() {
        if (this.customFilterSearch == null) return;

        this.searchTokens.push(new SearchToken({
            isCustomFilter: true,
            text: this.customFilterSearch
        }));
        this.searchTokens = [...new Set(this.searchTokens)];
        this.customFilterSearch = null;

        await this.getFilteredResults();
    }

    async removeFilter(searchToken: SearchToken) {
        this.searchTokens = this.searchTokens.filter(x => x != searchToken);

        if (!searchToken.isCustomFilter) {
            this.syncPredefinedFilterStateSearchTokens(searchToken);
        }

        await this.getFilteredResults();
    }

    async sortByCreationDate() {
        this.sortState = new SortState({ descendingCreateDate: !!!this.sortState.descendingCreateDate });
        await this.getFilteredResults();
    }

    sortCurrentContentByCreationDate() {
        if (this.flowInstances.length == 0) return;

        if (this.sortState.descendingCreateDate) {
            this.flowInstances.sort((a, b) => b.createDate > a.createDate ? 1 : -1);
        } else {
            this.flowInstances.sort((a, b) => a.createDate > b.createDate ? 1 : -1);
        }
    }

    async sortByName() {
        this.sortState = new SortState({ descendingName: !!!this.sortState.descendingName });
        await this.getFilteredResults();
    }

    sortCurrentContentByName() {
        if (this.flowInstances.length == 0) return;

        if (this.sortState.descendingName) {
            this.flowInstances.sort((a, b) => b.name.localeCompare(a.name));
        } else {
            this.flowInstances.sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    async sortByOwnerInfo() {
        this.sortState = new SortState({ descendingOwnerInfo: !!!this.sortState.descendingOwnerInfo });
        await this.getFilteredResults();
    }

    sortCurrentContentByOwnerInfo() {
        if (this.flowInstances.length == 0) return;

        if (this.sortState.descendingOwnerInfo) {
            this.flowInstances.sort((a, b) => b.name.localeCompare(a.name));
        } else {
            this.flowInstances.sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    openFilterMenu() {
        setTimeout(() => this.filterMenuRef.elementRef.nativeElement.firstChild.classList.add('show'), 1);
    }

    closeFilterMenu() {
        setTimeout(() => this.filterMenuRef.elementRef.nativeElement.firstChild.classList.remove('show'), 1);
    }

    openSortMenu() {
        setTimeout(() => this.sortingMenuRef.nativeElement.classList.add('show'), 1);
    }

    closeSortMenu() {
        setTimeout(() => this.sortingMenuRef.nativeElement.classList.remove('show'), 1);
    }

    async loadMore() {
        await this.getFilteredResults(this.currentPage + 1, true);
    }

    isSortStateDefault() {
        return JSON.stringify(this.sortState) == JSON.stringify(new SortState());
    }

    isPredefinedFilterStateDefault() {
        return JSON.stringify(this.filterState) == JSON.stringify(new PredefinedFilterState());
    }

    openFlowInstance(flowInstance: FlowInstance) {
        this.router.navigate([Enums.PagesPaths.FlowInstance, flowInstance.id]);
    }

    // ======================
    // private methods
    // ======================

    private getFilteredResults(
        page?: number,
        shouldMaintainCurrentSet?: boolean,
        pageSize?: number
    ) {
        this.spinner.show();

        setTimeout(async () => {
            this.setCookies();

            try {
                this.currentPage = page || 1;

                const response = await this.flowInstanceService.filterPaginated({
                    page: page,
                    pageSize: pageSize || this.currentPageSize,
                    sortField: this.getCurrentSortField(),
                    isSortDescending: this.getCurrentIsSortDescending(),
                    searchTokens: this.searchTokens.map(x => x.key || x.text)
                });

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.flowInstances = shouldMaintainCurrentSet
                    ? this.flowInstances.concat(response.data)
                    : response.data;
                this.currentPageSize = response.pageSize;
                this.currentTotalCount = response.totalCount;
                this.currentTotalPages = response.totalPages;

                if (!this.isSortStateDefault()) {
                    if (this.sortState.descendingCreateDate !== null) {
                        this.sortCurrentContentByCreationDate();
                    } else if (this.sortState.descendingName !== null) {
                        this.sortCurrentContentByName();
                    } else if (this.sortState.descendingOwnerInfo !== null) {
                        this.sortCurrentContentByOwnerInfo();
                    }
                }
            } finally {
                setTimeout(() => {
                    this.spinner.hide();
                }, 300);
            }
        }, 300);
    }

    private processPredefinedFiltersTokens() {
        const textOngoing = Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.Ongoing);
        const textFinished = Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.Finished);
        const textCancelled = Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.Cancelled);
        const textStartedByMe = Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.StartedByMe);
        const textNotStartedByMe = Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.NotStartedByMe);
        const textPendingForMe = Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.PendingForMe);
        const textNotPendingForMe = Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.NotPendingForMe);

        this.searchTokens = this.searchTokens.filter(x => x.text != textOngoing);
        if (this.filterState.isOngoingFilterOn) {
            this.searchTokens.push(new SearchToken({
                isCustomFilter: false,
                key: Enums.PredefinedFilterKey.get(Enums.PredefinedFilter.Ongoing),
                text: textOngoing
            }));
        }

        this.searchTokens = this.searchTokens.filter(x => x.text != textFinished);
        if (this.filterState.isFinishedFilterOn) {
            this.searchTokens.push(new SearchToken({
                isCustomFilter: false,
                key: Enums.PredefinedFilterKey.get(Enums.PredefinedFilter.Finished),
                text: textFinished
            }));
        }

        this.searchTokens = this.searchTokens.filter(x => x.text != textCancelled);
        if (this.filterState.isCancelledFilterOn) {
            this.searchTokens.push(new SearchToken({
                isCustomFilter: false,
                key: Enums.PredefinedFilterKey.get(Enums.PredefinedFilter.Cancelled),
                text: textCancelled
            }));
        }

        this.searchTokens = this.searchTokens.filter(x => x.text != textStartedByMe);
        if (this.filterState.isStartedByMeFilterOn) {
            this.searchTokens.push(new SearchToken({
                isCustomFilter: false,
                key: Enums.PredefinedFilterKey.get(Enums.PredefinedFilter.StartedByMe),
                text: textStartedByMe
            }));
        }

        this.searchTokens = this.searchTokens.filter(x => x.text != textNotStartedByMe);
        if (this.filterState.isNotStartedByMeFilterOn) {
            this.searchTokens.push(new SearchToken({
                isCustomFilter: false,
                key: Enums.PredefinedFilterKey.get(Enums.PredefinedFilter.NotStartedByMe),
                text: textNotStartedByMe
            }));
        }

        this.searchTokens = this.searchTokens.filter(x => x.text != textPendingForMe);
        if (this.filterState.isPendingForMeFilterOn) {
            this.searchTokens.push(new SearchToken({
                isCustomFilter: false,
                key: Enums.PredefinedFilterKey.get(Enums.PredefinedFilter.PendingForMe),
                text: textPendingForMe
            }));
        }

        this.searchTokens = this.searchTokens.filter(x => x.text != textNotPendingForMe);
        if (this.filterState.isNotPendingForMeFilterOn) {
            this.searchTokens.push(new SearchToken({
                isCustomFilter: false,
                key: Enums.PredefinedFilterKey.get(Enums.PredefinedFilter.NotPendingForMe),
                text: textNotPendingForMe
            }));
        }

        this.searchTokens.sort((a, b) => a.addedTimestamp > b.addedTimestamp ? 1 : -1).sort((a, b) => {
            if (a.isCustomFilter) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    private syncPredefinedFilterStateSearchTokens(searchToken: SearchToken) {
        switch (searchToken.text) {
            case Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.Finished):
                this.filterState.isFinishedFilterOn = !this.filterState.isFinishedFilterOn;
                break;

            case Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.Ongoing):
                this.filterState.isOngoingFilterOn = !this.filterState.isOngoingFilterOn;
                break;

            case Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.Cancelled):
                this.filterState.isCancelledFilterOn = !this.filterState.isCancelledFilterOn;
                break;

            case Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.StartedByMe):
                this.filterState.isStartedByMeFilterOn = !this.filterState.isStartedByMeFilterOn;
                break;

            case Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.NotStartedByMe):
                this.filterState.isNotStartedByMeFilterOn = !this.filterState.isNotStartedByMeFilterOn;
                break;

            case Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.PendingForMe):
                this.filterState.isPendingForMeFilterOn = !this.filterState.isPendingForMeFilterOn;
                break;

            case Enums.PredefinedFilterDescription.get(Enums.PredefinedFilter.NotPendingForMe):
                this.filterState.isNotPendingForMeFilterOn = !this.filterState.isNotPendingForMeFilterOn;
                break;
        }
    }

    private getCurrentSortField(): string {
        return this.sortState.descendingCreateDate !== null
            ? Enums.SortField.CreateDate
            : this.sortState.descendingName !== null
                ? Enums.SortField.Name
                : this.sortState.descendingOwnerInfo !== null
                    ? Enums.SortField.OwnerInfo
                    : null;
    }

    private getCurrentIsSortDescending(): boolean {
        return this.getCurrentSortField() === null
            ? null
            : this.sortState.descendingCreateDate !== null
                ? this.sortState.descendingCreateDate
                : this.sortState.descendingName !== null
                    ? this.sortState.descendingName
                    : this.sortState.descendingOwnerInfo;
    }

    private processPageSize() {
        if (this.window.visualViewport.width <= SCSS_VARS['$breakpoint-max-1']) {
            this.currentPageSize = 5;
        } else if (this.window.visualViewport.width <= SCSS_VARS['$breakpoint-max-2']) {
            this.currentPageSize = 4;
        } else if (this.window.visualViewport.width <= SCSS_VARS['$breakpoint-max-last']) {
            this.currentPageSize = 6;
        } else {
            this.currentPageSize = 8;
        }
    }

    private async getCookies() {
        // lê os valores do cookie
        let cookieValue = atob(this.cookieService.get('prodest-eflow-flowinstlist-state'));

        // caso o cookie exista
        if (cookieValue != '') {
            // atribui os valores parseados do cookie
            let generalState = JSON.parse(cookieValue) as CookieGeneralState;

            this.searchTokens = generalState.main.searchTokens || [] as SearchToken[];
            this.filterState = generalState.main.filterState || new PredefinedFilterState();
            this.sortState = generalState.main.sortState || new SortState();

            this.processPredefinedFiltersTokens();
        }

        await this.getFilteredResults();
    }

    private setCookies() {
        let generalState: CookieGeneralState = {
            main: {
                searchTokens: this.searchTokens,
                filterState: this.filterState,
                sortState: this.sortState
            }
        };

        // escreve os valores no cookie
        this.cookieService.set('prodest-eflow-flowinstlist-state', btoa(JSON.stringify(generalState)));
    }
}
