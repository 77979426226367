import { Injector } from '@angular/core';
import { FormioCustomComponentInfo, registerCustomFormioComponent } from '@formio/angular';
import { PdfUploadMultipleComponent } from './pdf-upload-multiple.component';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
    type: 'pdfUploadMultiple',
    selector: 'pdf-upload-multiple',
    title: 'Arquivos PDF',
    group: 'basic',
    icon: 'fa fa-copy',
    fieldOptions: ['maxFiles']
};

export function registerPdfUploadMultipleComponent(injector: Injector) {
    registerCustomFormioComponent(COMPONENT_OPTIONS, PdfUploadMultipleComponent, injector);
}
