<div class="nav-item dropdown p-0">
    <a class="nav-link" matTooltip="Notificações" #headerButtonRef>
        <div class="unread-bell-tag" *ngIf="unread > 0">{{unread}}</div>
        <i class="fas fa-bell"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right" #dropdownMenuRef>
        <div class="title">
            <i class="fas fa-times ml-2 invisible" matTooltip="Fechar" (click)="close()"></i>
            <span>Notificações</span>
            <i class="fas fa-times mr-2" matTooltip="Fechar" (click)="close()"></i>
        </div>
        <hr />
        <div class="sub title">
            <i class="fas fa-sync-alt ml-2"
               matTooltip="Atualizar notificações"
               (click)="refresh()"
            ></i>
            <div class="text-wrapper">
                <span class="ml-2 mr-1">Exibindo {{count}} de {{total}}</span>
                <span class="mr-1">({{unread}}</span>
                <span>não lidas)</span>
            </div>
            <i class="fas fa-eye mr-2"
               matTooltip="Marcar todas como {{(unread == 0 ? 'não ' : '')}}lidas"
               [class.all-read]="unread == 0"
               (click)="toggleAllRead()"
            ></i>
        </div>
        <hr />
        <div class="notifications-container">
            <ng-container *ngFor="let notification of notifications; let i = index">
                <div class="entry-row py-2 pr-2" [class.read]="notification.lida">
                    <div class="unread-marker mr-2" *ngIf="!notification.lida">
                        <i class="fas fa-exclamation"></i>
                    </div>
                    <div class="content-container">
                        <div class="content-header mb-1" [innerHtml]="notification.titulo"></div>
                        <div class="content-body" [innerHtml]="notification.conteudo"></div>
                        <div class="content-footer">
                            <div class="footer-block info">
                                <i class="fas fa-clock mr-1"></i>
                                <span>{{getDate(notification.dataCriacao)}}</span>
                            </div>
                            <div class="footer-block action mr-1" [class.read]="notification.lida" (click)="toggleRead(notification)">
                                <i class="fas fa-eye mr-1"></i>
                                <span>Marcar como <span *ngIf="notification.lida">não </span>lida</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="i < (notifications.length - 1)" />
            </ng-container>
        </div>
        <hr />
        <div class="footer my-2">
            <button type="button" class="btn btn-outline-primary" (click)="loadMore()">
                <i class="fas fa-download mr-2"></i>
                <span>Carregar mais</span>
            </button>
        </div>
    </div>
</div>
