<div class="wrapper p-2">
    <!-- header -->
    <div class="info-header">
        <i class="fas fa-info-circle mr-1"></i>
        <span class="relevant-info-font mr-2">Extensões permitidas:</span>
        <span class="text-highlight relevant-info-font">PDF</span>
    </div>

    <!-- body -->
    <div class="file-container p-2 mt-1" [class.disabled]="disabled">
        <!-- botão default -->
        <span class="main-button" [class.hidden]="activeButton != 'main'">
            <label class="m-0 py-1 px-2" [class.has-value]="fileName != null">
                <i class="fas fa-folder-open mr-1"></i>
                <span>{{(fileName != null ? 'Alterar' : 'Escolher')}} arquivo</span>

                <ng-container *ngFor="let i of rerenderer">
                    <input type="file" accept="application/pdf" #fileRef
                            [disabled]="disabled"
                            (change)="changeFile($event)"
                    >
                </ng-container>
            </label>
        </span>

        <!-- botão de cancelamento de upload -->
        <span class="prevent-upload state-button" *ngIf="activeButton == 'prevent'"
                (click)="preventUpload()"
        >
            <span class="countdown">
                <svg><circle r="12" cx="20" cy="20"></circle></svg>
            </span>
            <i class="fas fa-stopwatch mr-2"></i>
            <span>Cancelar upload?</span>
        </span>

        <!-- botão de upload cancelado -->
        <span class="upload-cancelled state-button" *ngIf="activeButton == 'cancelled'">
            <i class="fas fa-times-circle mr-1"></i>
            <span>Upload cancelado!</span>
        </span>

        <!-- botão de upload em andamento -->
        <span class="upload-progress state-button" *ngIf="activeButton == 'progress'">
            <i class="fas fa-hourglass-start mr-2" *ngIf="progressState == 'start'"></i>
            <i class="fas fa-hourglass-half mr-2" *ngIf="progressState == 'half'"></i>
            <i class="fas fa-hourglass-end mr-2" *ngIf="progressState == 'end'"></i>
            <span>Realizando upload...</span>
        </span>

        <!-- botão de upload concluído -->
        <span class="upload-complete state-button" *ngIf="activeButton == 'done'">
            <i class="fas fa-check-circle mr-2"></i>
            <span>Upload concluído!</span>
        </span>

        <!-- container do nome do arquivo -->
        <span class="file-name-container">
            <ng-container *ngIf="fileName != null; else noFileTemplate">
                <!-- Nome -->
                <span class="relevant-info-font">{{fileName}}</span>

                <!-- Remover arquivo -->
                <i class="action remove-file fas fa-times-circle" *ngIf="!disabled"
                    matTooltip="Remover arquivo"
                    (click)="removeFile($event)"
                ></i>

                <!-- Visualizar arquivo -->
                <i class="action view-file fas fa-eye" *ngIf="fileName != null && !disabled"
                    matTooltip="Visualizar arquivo"
                    (click)="viewFile()"
                ></i>
            </ng-container>

            <ng-template #noFileTemplate>
                <span class="relevant-info-font font-italic">Nenhum arquivo selecionado</span>
            </ng-template>
        </span>
    </div>
</div>
