import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormioCustomComponent } from '@formio/angular';

@Component({
    selector: 'datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.scss']
})
export class DateTimePickerComponent implements FormioCustomComponent<any> {
    @Input() disabled: boolean;
    @Input() value: any = null;
    @Output() valueChange = new EventEmitter<any>();

    constructor() { }

    // ======================
    // lifecycle methods
    // ======================

    // ======================
    // public methods
    // ======================

    onChange() {
        this.valueChange.emit(this.value);
    }

    clear() {
        this.value = null;
        this.valueChange.emit(this.value);
    }

    // ======================
    // private methods
    // ======================
}
