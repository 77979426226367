<div class="card flow-item-container shadow" *ngIf="model != null">
    <div class="card-body">
        <!-- tag da Caixa de Fluxo -->
        <div class="superheader">
            <span class="relevant-info-font text-highlight" matTooltip="{{patriarch}} - {{organization}} - {{unit}}">
                {{patriarch}} - {{organization}} - {{unit}}
            </span>
        </div>

        <!-- header -->
        <div class="header mt-1">
            <h6 class="title m-0"
                matTooltipPosition="above"
                [matTooltip]="model.publishedName || model.name"
            >{{model.publishedName || model.name}}</h6>
        </div>

        <hr>

        <!-- body -->
        <div class="body">
            <div class="actions">
                <!-- Iniciar -->
                <button type="button" class="btn btn-outline-primary" (click)="open()">
                    <i class="fas fa-play-circle mr-2"></i>
                    <span>Iniciar</span>
                </button>

                <!-- painel de informações -->
                <ngx-mat-popover [popoverContent]="popoverContentTemplate" #popoverRef>
                    <a class="card-link"
                       matTooltip="Visualizar informações sobre o Fluxo"
                       (click)="infoClick($event)"
                    >
                        <i class="fas fa-info-circle"></i>
                    </a>
                </ngx-mat-popover>
                <ng-template #popoverContentTemplate>
                    <div class="wrapper py-2" #wrapperRef>
                        <h6 class="mb-0">{{model.publishedName || model.name}}</h6>

                        <hr #rulerElemPopover>

                        <div class="body-line">
                            <label class="my-0 mr-2">Órgão</label>
                            <span class="relevant-info-font">{{organization}}</span>
                        </div>
                        <div class="body-line">
                            <label class="my-0 mr-2">Setor</label>
                            <span class="relevant-info-font">{{unit}}</span>
                        </div>
                        <div class="body-line">
                            <label class="my-0 mr-2">Disponibilizado em</label>
                            <span class="relevant-info-font">{{getUpdateDateString()}}</span>
                        </div>
                        <div class="body-line">
                            <label class="my-0 mr-2">Versão atual</label>
                            <span class="version-tag px-1 py-0">Versão {{model.version}}</span>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
