import {
    Component,
    OnInit
} from '@angular/core';
import {
    FlowTarget,
    FlowDefinitionCounters
} from '../../models/flow.model';
import {
    CookieGeneralState,
    FlowInstanceCounters,
    PredefinedFilterState,
    SearchToken
} from '../../models/helper-classes.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../shared/enums';
import { FlowInstanceService } from '../../services/flow-instance.service';
import { CookieService } from 'ngx-cookie-service';
import { Utils } from '../../shared/utils';
import { AuthService } from '../../services/auth.service';
import { FlowDefinitionService } from '../../services/flow-definition.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    // #region [Type properties]
    PredefinedFilter: typeof Enums.PredefinedFilter = Enums.PredefinedFilter;
    FlowTarget: typeof FlowTarget = FlowTarget;
    // #endregion

    // #region [properties]
    searchTokens: SearchToken[] = [];
    flowInstanceCounters: FlowInstanceCounters = new FlowInstanceCounters();
    flowDefinitionCounters: FlowDefinitionCounters = new FlowDefinitionCounters();
    // #endregion

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private flowInstanceService: FlowInstanceService,
        private flowDefinitionService: FlowDefinitionService,
        public authService: AuthService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.flowInstanceCounters.total = await this.getFlowInstanceCounters();
        this.flowInstanceCounters.ongoing = await this.getFlowInstanceCounters([Enums.PredefinedFilter.Ongoing]);
        this.flowInstanceCounters.startedByMe = await this.getFlowInstanceCounters([Enums.PredefinedFilter.Ongoing, Enums.PredefinedFilter.StartedByMe]);
        this.flowInstanceCounters.pendingForMe = await this.getFlowInstanceCounters([Enums.PredefinedFilter.Ongoing, Enums.PredefinedFilter.PendingForMe]);

        this.flowDefinitionCounters.citizen = await this.getFlowDefinitionCounters(FlowTarget.Citizen);
        this.flowDefinitionCounters.unit = await this.getFlowDefinitionCounters(FlowTarget.Unit);
        this.flowDefinitionCounters.organization = await this.getFlowDefinitionCounters(FlowTarget.Organization);
        this.flowDefinitionCounters.patriarch = await this.getFlowDefinitionCounters(FlowTarget.Patriarch);
    }

    // ======================
    // public methods
    // ======================

    goToFlowDefinitionList(flowTarget?: FlowTarget) {
        let section = '';
        switch (flowTarget) {
            case FlowTarget.Unit:
                section = 'unt';
                break;

            case FlowTarget.Organization:
                section = 'org';
                break;

            case FlowTarget.Patriarch:
                section = 'pat';
                break;

            case FlowTarget.Citizen:
                section = 'cit';
                break;
        }

        this.router.navigate([Enums.PagesPaths.FlowDefinitionList], { queryParams: { t: section } });
    }

    goToFlowInstanceList(predefinedFilter?: Enums.PredefinedFilter) {
        this.setFlowInstanceListCookies(predefinedFilter);
        this.router.navigate([Enums.PagesPaths.FlowInstanceList]);
    }

    getCounterLabel(ids): string {
        let values = Object.values(ids);

        if (values.length == 2) {
            return values.join(' ou ');
        }

        if (values.length == 3) {
            return `${values.slice(0, 2).join(', ')} ou ${values[2]}`;
        }

        if (values.length > 3) {
            return `${values.slice(0, 2).join(', ')} etc.`;
        }

        return values[0].toString();
    }

    getUserName(): string {
        return this.authService.user
            ? (this.authService.user?.apelido || this.authService.user?.nome).trim().split(' ')[0]
            : null;
    }

    // ======================
    // private methods
    // ======================

    private async getFlowInstanceCounters(predefinedFilters?: Enums.PredefinedFilter[]): Promise<number> {
        if (predefinedFilters != null) {
            this.processPredefinedFiltersTokens(predefinedFilters);
        }

        const response = await this.flowInstanceService.filterPaginated({
            skipDataResults: true,
            searchTokens: this.searchTokens.map(x => x.key || x.text)
        });

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        return response.totalCount;
    }

    private async getFlowDefinitionCounters(flowTarget: FlowTarget): Promise<number> {
        const response = await this.flowDefinitionService.filterPaginated({
            flowTarget: flowTarget,
            skipDataResults: true
        });

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        return response.totalCount;
    }

    private processPredefinedFiltersTokens(predefinedFilters: Enums.PredefinedFilter[]) {
        this.searchTokens = [];

        predefinedFilters.forEach(item => {
            const text = Enums.PredefinedFilterDescription.get(item);
            this.searchTokens.push(new SearchToken({
                isCustomFilter: false,
                key: Enums.PredefinedFilterKey.get(item),
                text: text
            }));
        });
    }

    private setFlowInstanceListCookies(predefinedFilter?: Enums.PredefinedFilter) {
        let generalState: CookieGeneralState = {
            main: {
                filterState: new PredefinedFilterState()
            }
        };

        switch (predefinedFilter) {
            case Enums.PredefinedFilter.Ongoing:
                generalState.main.filterState.isOngoingFilterOn = true;
                break;

            case Enums.PredefinedFilter.StartedByMe:
                generalState.main.filterState.isOngoingFilterOn = true;
                generalState.main.filterState.isStartedByMeFilterOn = true;
                break;

            case Enums.PredefinedFilter.PendingForMe:
                generalState.main.filterState.isOngoingFilterOn = true;
                generalState.main.filterState.isPendingForMeFilterOn = true;
                break;
        }

        // escreve os valores no cookie
        this.cookieService.set('prodest-eflow-flowinstlist-state', btoa(JSON.stringify(generalState)));
    }
}
