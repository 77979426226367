import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FlowDefinitionContext } from '../models/flow.model';
import { BaseService } from './base.service';
import { IBaseModel } from '../models/base.model';

@Injectable({
    providedIn: 'root'
})
export class FlowDefinitionContextService extends BaseService {
    controllerRoute: string = 'FlowDefinitionContext';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public async getById(id: string): Promise<IBaseModel<FlowDefinitionContext>> {
        return this.httpClient.get<IBaseModel<FlowDefinitionContext>>(`${this.apiBaseUrl}/${this.controllerRoute}/${id}`).toPromise();
    }

    public async isContextInUse(id: string): Promise<IBaseModel<boolean>> {
        return this.httpClient.get<IBaseModel<boolean>>(`${this.apiBaseUrl}/${this.controllerRoute}/IsContextInUse/${id}`).toPromise();
    }
}
