export const HTML_TEMPLATE_REGISTER_PROCESS_CANCEL = `
<table style="border-collapse: collapse; width: 100%; height: 80px;" border="0">
    <tbody>
        <tr style="height: 80px;">
            <td style="width: 15%; height: 80px; padding-bottom: 10px;">{|FluxoPatriarcaBrasao|}</td>
            <td style="width: 85%; height: 80px;">
                <strong>{|FluxoPatriarcaNome|}</strong>
                <br />
                <strong>
                    <span style="font-size: 11pt;">{|FluxoOrgaoNome|} - {|FluxoOrgaoSigla|}</span>
                </strong>
            </td>
        </tr>
    </tbody>
</table>
<table style="border-collapse: collapse; width: 100%;" border="0">
    <tbody>
        <tr>
            <td style="padding-top: 7px; padding-right: 5px; padding-bottom: 7px; text-align: right; width: 100%;" colspan="2">
                <span style="font-size: 11pt;">
                    <strong>&nbsp;</strong>
                </span>
            </td>
        </tr>
        <tr style="border-bottom-width: 1px; background-color: #ced4d9;">
            <td style="width: 21.9911%; padding: 7px 5px; border-right: 1px solid #bbb; text-align: right;">
                <span style="font-size: 11pt;">
                    <strong>E-FLOW</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding: 7px;">
                <span style="font-size: 11pt;">
                    <strong>CANCELAMENTO DE FLUXO NA AUTUA&Ccedil;&Atilde;O DE PROCESSO</strong>
                </span>
            </td>
        </tr>
    </tbody>
</table>
<table style="border-collapse: collapse; width: 100%; height: 270px;" border="0">
    <tbody>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>FLUXO</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_NomeFluxo_|} (Vers&atilde;o {|_VersaoFluxo_|})</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>CAIXA DE FLUXOS</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_CaixaFluxos_|}</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>TAREFA</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_NomeTarefa_|}</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>ID DA INST&Acirc;NCIA</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal; text-transform: uppercase;">{|_IdInstancia_|}</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>REQUERENTE</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">{|_RequerenteInstancia_|}</p>
            </td>
        </tr>
        <tr style="min-height: 18px;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <span style="font-size: 8pt; color: #666;">
                    <strong>SITUA&Ccedil;&Atilde;O</strong>
                </span>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">
                    O Fluxo acima descrito foi cancelado na data e hora de captura deste Documento em fun&ccedil;&atilde;o de um ou mais erros ocorridos na identifica&ccedil;&atilde;o dos Interessados do Processo a ser autuado no E-Docs. Em caso de d&uacute;vidas, entre em contato com o &oacute;rg&atilde;o respons&aacute;vel pelo servi&ccedil;o.
                </p>
                <p>&nbsp;</p>
                <p style="margin: 0; font-size: 10pt; word-break: normal; overflow-wrap: normal; white-space: normal;">
                    Para todos os efeitos, o Fluxo <strong>n&atilde;o foi completado</strong> e as eventuais Tarefas previamente executadas <strong>n&atilde;o possuem validade</strong>. Inicie um novo Fluxo para completar a requisi&ccedil;&atilde;o do servi&ccedil;o.
                </p>
            </td>
        </tr>
        <tr style="min-height: 18px; border-bottom: 1px solid #dddddd;">
            <td style="width: 21.9911%; text-align: right; padding-right: 6px; border-right: 1px solid #dddddd; vertical-align: top; padding-top: 3px; padding-bottom: 3px;">
                <p>
                    <span style="font-size: 8pt; color: #666;">
                        <strong>MENSAGEM DO</strong>
                    </span>
                </p>
                <p>
                    <span style="font-size: 8pt; color: #666;">
                        <strong> RESPONS&Aacute;VEL</strong>
                    </span>
                </p>
            </td>
            <td style="width: 78.0089%; padding-left: 6px; vertical-align: top; padding-top: 6px; padding-bottom: 6px;">
                <p style="margin: 0; display: inline-block; word-break: normal; overflow-wrap: normal; white-space: normal; font-size: 10pt; background-color: #ecf0f1; padding: 3px 5px; border-radius: 5px;">{|_Mensagem_|}</p>
            </td>
        </tr>
    </tbody>
</table>
<table style="border-collapse: collapse; width: 100%;" border="0">
    <tbody>
        <tr style="height: 25px;">
            <td style="width: 100%; height: 25px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
            <td style="width: 100%; height: 25px;">&nbsp;</td>
        </tr>
    </tbody>
</table>
<p style="text-align: center; text-transform: uppercase;">
    <strong>{|UsuarioNome|}</strong>
    <br />
    <span style="font-size: 9pt;">
        <strong>{|UsuarioPapel|}</strong>
    </span>
    <br />
    <span style="font-size: 9pt;">
        <strong>{|UsuarioUnidadeNome|} - {|UsuarioUnidadeSigla|} - {|UsuarioOrgaoSigla|}</strong>
    </span>
</p>
`;
