import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Notificacao } from '../../../../models/notificacoes.model';
import { AuthService } from '../../../../services/auth.service';
import { NotificacoesService } from '../../../../services/notificacoes.service';
import { Enums } from '../../../../shared/enums';
import { Utils } from '../../../../shared/utils';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    @ViewChild('dropdownMenuRef') dropdownMenuRef: ElementRef;
    @ViewChild('headerButtonRef') headerButtonRef: ElementRef;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.dropdownMenuRef.nativeElement.classList.contains('show')) {
            if (!event.composedPath().includes(this.dropdownMenuRef.nativeElement)) {
                this.dropdownMenuRef.nativeElement.classList.toggle('show');
            }
        } else {
            if (event.composedPath().includes(this.headerButtonRef.nativeElement)) {
                this.dropdownMenuRef.nativeElement.classList.toggle('show');
            }
        }
    }

    notifications: Notificacao[] = [];
    unread: number = 0;
    total: number = 0;
    count: number = 0;
    page: number = 1;

    constructor(
        private elementRef: ElementRef,
        private toastr: ToastrService,
        private authService: AuthService,
        private notificacoesService: NotificacoesService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        let interval = setInterval(async () => {
            if (this.authService.user == null) return;

            clearInterval(interval);
            await this.updateAll();
        }, 300);
    }

    // ======================
    // public methods
    // ======================

    close() {
        this.dropdownMenuRef.nativeElement.classList.toggle('show');
    }

    async toggleAllRead() {
        let shouldCallMarcarTodasLidas = this.unread > 0;

        const response = shouldCallMarcarTodasLidas
            ? await this.notificacoesService.postMarcarTodasLidas({ usuarioGuid: this.authService.user.id })
            : await this.notificacoesService.postMarcarTodasNaoLidas({ usuarioGuid: this.authService.user.id });

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.unread = response.data.naoLidas;
        this.notifications.forEach(x => x.lida = shouldCallMarcarTodasLidas);
    }

    async toggleRead(notification: Notificacao) {
        const response = notification.lida
            ? await this.notificacoesService.postMarcarNaoLida(notification.id, { usuarioGuid: this.authService.user.id })
            : await this.notificacoesService.postMarcarLida(notification.id, { usuarioGuid: this.authService.user.id });

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.unread = response.data.naoLidas;
        notification.lida = !notification.lida;
    }

    async loadMore() {
        const response = await this.notificacoesService.get({
            usuarioGuid: this.authService.user.id,
            page: ++this.page
        });

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.notifications = this.notifications.concat(response.data.data.sort((a, b) => b.id - a.id));
        this.count += response.data.meta.count;
    }

    refresh() {
        this.updateAll();
    }

    getDate(date: string): string {
        let newDate = new Date(date);
        return newDate.toLocaleDateString() + ' ' + newDate.toUTCString().split(' ').reverse()[1];
    }

    // ======================
    // private methods
    // ======================

    private async updateAll(showErrors: boolean = true) {
        const response_Get = await this.notificacoesService.get({
            usuarioGuid: this.authService.user.id
        });

        if (!response_Get.isSuccess) {
            if (showErrors) {
                this.toastr.error(response_Get.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            }

            return;
        }

        this.notifications = response_Get.data.data.sort((a, b) => b.id - a.id);
        this.total = response_Get.data.meta.total;
        this.count = response_Get.data.meta.count;

        let response_GetTotais = await this.notificacoesService.getTotais(this.authService.user.id);

        if (!response_GetTotais.isSuccess) {
            if (showErrors) {
                this.toastr.error(response_GetTotais.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            }

            return;
        }

        this.unread = response_GetTotais.data.naoLidas;
    }
}
