import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Enums } from '../../../shared/enums';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    favicon: HTMLLinkElement = document.querySelector('#faviconElement');
    pageTitle: HTMLTitleElement = document.querySelector('#pageTitleElement');

    Enums = Enums;

    env: string;
    environment: string;
    conectaEnvironmentSegment: string = '';
    isFromConecta: boolean = false;

    constructor(
        private cookieService: CookieService,
        private titleService: Title
    ) {
        if (atob(this.cookieService.get('prodest-eflow-cnc')).toLowerCase() == 'true') {
            this.isFromConecta = window.location.pathname.includes(Enums.PagesPaths.CatalogService);
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        this.env = atob(this.cookieService.get('prodest-eflow-env')).toLowerCase();
        switch (this.env) {
            case 'loc':
                this.environment = Enums.Messages.EnvironmentLocalhost;
                this.conectaEnvironmentSegment = 'dev.';
                setTimeout(() => this.titleService.setTitle(`${this.titleService.getTitle()} [L]`), 100);
                break;

            case 'des':
                this.environment = Enums.Messages.EnvironmentDevelopment;
                this.conectaEnvironmentSegment = 'dev.';
                setTimeout(() => this.titleService.setTitle(`${this.titleService.getTitle()} [D]`), 100);
                break;

            case 'hom':
                this.environment = Enums.Messages.EnvironmentStaging;
                this.conectaEnvironmentSegment = 'hom.';
                setTimeout(() => this.titleService.setTitle(`${this.titleService.getTitle()} [H]`), 100);
                break;

            case 'tre':
                this.environment = Enums.Messages.EnvironmentLearning;
                this.conectaEnvironmentSegment = 'hom.';
                setTimeout(() => this.titleService.setTitle(`${this.titleService.getTitle()} [T]`), 100);
                break;
        }

        if (this.isFromConecta) {
            this.favicon.href = this.favicon.href.replace('eflow', 'conecta');
            this.pageTitle.text = Enums.Messages.PageTitleConectaEs;
        } else {
            this.favicon.href = this.favicon.href.replace('conecta', 'eflow');
            this.pageTitle.text = Enums.Messages.PageTitleEFlow;
        }
    }

    // ======================
    // public methods
    // ======================

    shouldHideEnvironment(): boolean {
        return this.env.toLowerCase() == 'prd';
    }

    // ======================
    // private methods
    // ======================
}
