import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FlowTarget } from '../../models/flow.model';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Utils } from '../../shared/utils';
import { CookieGeneralState, CookieParticularState } from '../../models/helper-classes.model';

@Component({
    selector: 'flow-definition-list',
    templateUrl: './flow-definition-list.component.html',
    styleUrls: ['./flow-definition-list.component.scss']
})
export class FlowDefinitionListComponent implements OnInit, AfterViewInit {
    // #region [ViewChild]
    @ViewChild('citRef') citRef: ElementRef;
    // #endregion

    // #region [Type properties]
    FlowTarget: typeof FlowTarget = FlowTarget;
    // #endregion

    // #region [properties]
    section: string = null;
    generalState: any = new CookieGeneralState();
    papeisUnitIdsArray: string[] = [];
    papeisOrganizationIdsArray: string[] = [];
    papeisPatriarchIdsArray: string[] = [];
    // #endregion

    constructor(
        public route: ActivatedRoute,
        public authService: AuthService,
        private cookieService: CookieService
    ) {
        this.section = this.route.snapshot.queryParamMap.get('t');
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        this.getCookies();

        this.papeisUnitIdsArray = Object.keys(this.authService.user.papeisUnitIds);
        this.papeisOrganizationIdsArray = Object.keys(this.authService.user.papeisOrganizationIds);
        this.papeisPatriarchIdsArray = Object.keys(this.authService.user.papeisPatriarchIds);
    }

    ngAfterViewInit() {
        if (this.section != null) {
            Utils.scrollTo(this.section);
        }
    }

    // ======================
    // public methods
    // ======================

    setCookies(event: CookieParticularState, flowTarget: FlowTarget) {
        switch (flowTarget) {
            case FlowTarget.Unit:
                this.generalState.unit = event;
                break;

            case FlowTarget.Organization:
                this.generalState.organization = event;
                break;

            case FlowTarget.Patriarch:
                this.generalState.patriarch = event;
                break;

            case FlowTarget.Citizen:
                this.generalState.citizen = event;
                break;
        }

        // escreve os valores no cookie
        this.cookieService.set('prodest-eflow-flowdeflist-state', btoa(JSON.stringify(this.generalState)));
    }

    // ======================
    // private methods
    // ======================

    private getCookies() {
        // lê os valores do cookie
        let cookieValue = atob(this.cookieService.get('prodest-eflow-flowdeflist-state'));

        // caso o cookie exista
        if (cookieValue != '') {
            // atribui os valores parseados do cookie
            this.generalState = JSON.parse(cookieValue);
        }
    }
}
