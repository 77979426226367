import { Injector } from '@angular/core';
import { FormioCustomComponentInfo, registerCustomFormioComponent } from '@formio/angular';
import { PdfUploadComponent } from './pdf-upload.component';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
    type: 'pdfUpload',
    selector: 'pdf-upload',
    title: 'Arquivo PDF',
    group: 'basic',
    icon: 'fa fa-file'
};

export function registerPdfUploadComponent(injector: Injector) {
    registerCustomFormioComponent(COMPONENT_OPTIONS, PdfUploadComponent, injector);
}
